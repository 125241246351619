import React from 'react';
import { Select, MenuItem, Box, Typography } from '@mui/material';

const LanguageSelect = ({ languageselectedOption, languagehandleChange }) => {
  const languageoptions = [
    { value: 'English', label: 'English', imgSrc: 'iconss/flags/united-kingdom.png' },
    { value: 'Hindi', label: 'Hindi', imgSrc: 'iconss/flags/india.png' },
    { value: 'European', label: 'European', imgSrc: 'iconss/flags/europe.png' },
  ]; 

  return (
    <Select
      labelId="language-select-label"
      id="language-select"
      className="country-select w-100"
      value={languageselectedOption} // Use value from props
      onChange={(event) => languagehandleChange(event.target.value)} // Call function from props
    >
      {languageoptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Box display="flex" alignItems="center">
            <img src={option.imgSrc} alt={option.label} style={{ marginRight: 8, width: 24, height: 24 }} />
            <Typography>{option.label}</Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSelect;
