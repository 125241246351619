import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import '../../css/authentication/signup.css';
import Cookies from 'js-cookie';

const Signup = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(3);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [otp, setOtp] = useState(new Array(5).fill(''));
  const [seconds, setSeconds] = useState(30);
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonText, setButtonText] = useState("Resend Code in 30");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [currentStep, setCurrentStep] = useState('step_one'); // State to manage current step

  useEffect(() => {
    document.title = 'Sign Up | Aadow';
  
		if (seconds > 0) {
		  const timer = setInterval(() => {
			setSeconds(prevSeconds => {
			  const newSeconds = prevSeconds - 1;
			  setButtonText(`Resend Code in ${newSeconds}`);
			  return newSeconds;
			});
		  }, 1000);

		  return () => clearInterval(timer);
		} else {
		  setIsDisabled(false);
		  setButtonText("Resend Code");
		}
	  }, [seconds]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  
  const handleOtpChange = (element, index) => {
    const value = element.value;
    if (isNaN(value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? value : d))]);

    // Focus on the next field
    if (element.nextSibling && value) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  };
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  
  const handleChangeEmail = async () => {
	setCurrentStep('step_one');
	setSignupButtonDisabled(false);
	setSignupButtonText('Continue');
  }
  
  
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [NameError, setNameError] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const [PasswordError, setPasswordError] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [EmailErrorMessage, setEmailErrorMessage] = useState('');
  const [PasswordErrorMessage, setPasswordErrorMessage] = useState('');
  const [NameErrorMessage, setNameErrorMessage] = useState('');
  const [OtpSentEmail, setOtpSentEmail] = useState('');
  const [signupbuttonDisabled, setSignupButtonDisabled] = useState(false);
  const [signupbuttonText, setSignupButtonText] = useState('Continue');
  
  const handleSignup = async () => {
		setNameError(false);
		setEmailError(false);
		setPasswordError(false);
		setNameErrorMessage('');
		setEmailErrorMessage('');
		setPasswordErrorMessage('');
		if (name.trim() === '') {
		  setNameError(true);
		  setNameErrorMessage('Please enter your name');
		  return;
		}
		
		if (email.trim() === '') {
		  setEmailError(true);
		  setEmailErrorMessage('Please enter an email address');
		  return;
		}else if (!emailRegex.test(email)) {
		  setEmailErrorMessage('Please enter a valid email address.');
		  setEmailError(true);
		  return;
		}
		
		if (password.trim() === '') {
		  setPasswordError(true);
		  setPasswordErrorMessage('Please enter your password');
		  return;
		}
		setSignupButtonDisabled(true);
		setSignupButtonText('Please wait...');
		/// setCurrentStep('step_two');
		/// setOtpSentEmail(email.replace(/(\w)([\w.-]*)(\w)(@.+)/, (_, first, middle, last, domain) => first + '*'.repeat(middle.length) + last + domain));
		/// showSnackbar('OTP sent successfully!', 'success');
		/// setSeconds(30);
		/// setIsDisabled(true);
		handleCheckEmailAvailability();
  };
  
  const handleCheckEmailAvailability = async () => {
    const loginData = new URLSearchParams({
        email: email, // Your email input
    });

    // Collecting Basic Auth credentials from inputs
    const basicAuthUsername = "testuser"; // This should be set from user input
    const basicAuthPassword = "testpassword"; // This should be set from user input

    // Base64 encode the Basic Auth credentials
    const encodedCredentials = btoa(`${basicAuthUsername}:${basicAuthPassword}`);

    try {
        const response = await fetch('http://portal.aadow.com:8080/api/validate/email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', // Change content type
                'Authorization': `Basic ${encodedCredentials}` // Add Basic Auth header
            },
            body: loginData.toString(), // Send login data as x-www-form-urlencoded
        });

        const status = response.status; // Get the response status

        if (response.ok) {
            const data = await response.text(); // Expect plain text response
            if(data === "Email available"){
				SendSignupOtp();
			}else{
			  setEmailError(true);
			  setEmailErrorMessage('Email address you entered is already exists!');
			  setSignupButtonDisabled(false);
			  setSignupButtonText('Continue');
			}
        } else {
			showSnackbar('An error occurred. Please try again.', 'error');
			setSignupButtonDisabled(false);
			setSignupButtonText('Continue');
        }
    } catch (error) {
        console.error('Error during login:', error); // Log the entire error object
		
		showSnackbar('An error occurred. Please try again.', 'error');
		setSignupButtonDisabled(false);
		setSignupButtonText('Continue');
    }
};

	const SendSignupOtp = async () => {
		const requestData = {
			username: email, // The username input
			type: "signup",         // The type input (e.g., 'email' or 'sms')
		};

		// Collecting Basic Auth credentials from inputs
		const basicAuthUsername = "testuser"; // This should be set from user input
		const basicAuthPassword = "testpassword"; // This should be set from user input

		// Base64 encode the Basic Auth credentials
		const encodedCredentials = btoa(`${basicAuthUsername}:${basicAuthPassword}`);

		try {
			const response = await fetch('http://portal.aadow.com:8080/send-otp', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json', // Sending raw JSON data
					'Authorization': `Basic ${encodedCredentials}` // Add Basic Auth header
				},
				body: JSON.stringify(requestData), // Send the username and type in JSON format
			});

			const status = response.status; // Get the response status

			if (response.ok) {
				const message = await response.text(); // Get plain text response
				setCurrentStep('step_two');
				setOtpSentEmail(email.replace(/(\w)([\w.-]*)(\w)(@.+)/, (_, first, middle, last, domain) => first + '*'.repeat(middle.length) + last + domain));
				showSnackbar('OTP sent successfully!', 'success');
				setSeconds(30);
				setIsDisabled(true);
			} else {
			
			showSnackbar('An error occurred. Please try again.', 'error');
			setSignupButtonDisabled(false);
			setSignupButtonText('Signup');
			}
		} catch (error) {
			console.error('Error during OTP send:', error); // Log the entire error object
			
			showSnackbar('An error occurred. Please try again.', 'error');
			setSignupButtonDisabled(false);
			setSignupButtonText('Signup');
		}
	};
	

  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const [otpError, setOtpError] = useState(false);
  
  const [otpverifybuttonDisabled, setotpverifyButtonDisabled] = useState(false);
  const [otpverifybuttonText, setotpverifyButtonText] = useState('Verify');
  
  const handleVerifyOtp = () => {
    const cleanedOtp = String(otp).replace(/,/g, '');
	const otpLength = cleanedOtp.length;
	
	setOtpError(false);
	setOtpErrorMessage("");
	if (cleanedOtp.trim() === '') {
		setOtpError(true);
		setOtpErrorMessage("Please enter OTP");
		return;
	}else if (otpLength < 5) {
		setOtpError(true);
		setOtpErrorMessage("The OTP must be at least 5 characters long");
		return;
	}
	setotpverifyButtonDisabled(true);
	setotpverifyButtonText("Verifying...");
	/// setotpverifyButtonDisabled(true);
    /// setotpverifyButtonText('Verifying...');
	/// setCurrentStep('step_three');
	
	handleValidateOTP();
  };
  
  const handleValidateOTP = async () => {
	  
    const cleanedOtp = String(otp).replace(/,/g, '');
	const otpLength = cleanedOtp.length;
    const requestData = {
        username: email, // The username input
        type: "signup",         // The type input (e.g., 'email' or 'sms')
        otp: cleanedOtp,           // The OTP input
    };

    // Collecting Basic Auth credentials from inputs
    const basicAuthUsername = "testuser"; // This should be set from user input
    const basicAuthPassword = "testpassword"; // This should be set from user input

    // Base64 encode the Basic Auth credentials
    const encodedCredentials = btoa(`${basicAuthUsername}:${basicAuthPassword}`);

    try {
        const response = await fetch('http://portal.aadow.com:8080/auth-otp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Sending raw JSON data
                'Authorization': `Basic ${encodedCredentials}` // Add Basic Auth header
            },
            body: JSON.stringify(requestData), // Send the username, type, and OTP in JSON format
        });

        const status = response.status; // Get the response status

        if (response.ok) {
            const data = await response.json(); // Parse JSON response
            const message = data.message; // Extract 'message' from the JSON response
            console.log('OTP validation message:', message); // Log the message
			handleFinalSignup();
        } else {
            // Handle non-OK response
            const errorData = await response.json();
            const errorMessage = errorData.message; // Extract 'message' from the error response
            console.error('Error message:', errorMessage); // Log the error message
            setOtpError(true);
			setOtpErrorMessage("Invalid OTP");
			setotpverifyButtonDisabled(false);
			setotpverifyButtonText("Verify");
        }
    } catch (error) {
        console.error('Error during OTP validation:', error); // Log the entire error object
		
		setotpverifyButtonDisabled(false);
		otpverifybuttonText("Verify");
		showSnackbar('An error occurred. Please try again.', 'error');
		setSignupButtonDisabled(false);
		setotpverifyButtonText('Signup');
    }
};
  
  const handleFinalSignup = async () => {
    const signupData = {
        email: email,        // Email input
        password: password,  // Password input
        name: name,          // Name input
        source: "source",      // Source input (e.g., where the user came from)
        utm: "utm",            // UTM tracking parameter
        ip: "ip"               // IP address of the user
    };

    // Collecting Basic Auth credentials from inputs
    const basicAuthUsername = "testuser"; // This should be set from user input
    const basicAuthPassword = "testpassword"; // This should be set from user input

    // Base64 encode the Basic Auth credentials
    const encodedCredentials = btoa(`${basicAuthUsername}:${basicAuthPassword}`);

    try {
        const response = await fetch('http://portal.aadow.com:8080/auth/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Send the data as raw JSON
                'Authorization': `Basic ${encodedCredentials}` // Add Basic Auth header
            },
            body: JSON.stringify(signupData), // Send the email, password, name, source, utm, and ip
        });

        const status = response.status; // Get the response status

        if (response.ok) {
            const data = await response.json(); // Parse JSON response
            const message = data.message; // Extract 'message' from the JSON response
            console.log('Signup success message:', message); // Log the message
			
			Cookies.set('current_Id', data.id, { expires: 30 });
			Cookies.set('current_accountId', data.accountId, { expires: 30 });
			Cookies.set('current_accountName', name, { expires: 30 });
			Cookies.set('current_accountEmail', email, { expires: 30 });
			
            setCurrentStep('step_three');
					const countdown = setInterval(() => {
					  setTimer(prevTimer => {
						if (prevTimer <= 1) {
						  clearInterval(countdown);
						  navigate('/complete-setup'); // Change '/target-page' to your desired route
						}
						return prevTimer - 1;
					  });
					}, 1000);
			
			
        } else {
            // Handle non-OK response
            const errorData = await response.json();
            const errorMessage = errorData.message; // Extract 'message' from the error response
            console.error('Error message:', errorMessage); // Log the error message
            showSnackbar(errorMessage, 'error');
        }
    } catch (error) {
        console.error('Error during signup:', error); // Log the entire error object
		
		showSnackbar('An error occurred. Please try again.', 'error');
		setSignupButtonDisabled(false);
		setSignupButtonText('Signup');
    }
};

  
  const handleResendCode = () => {
    // Logic to resend the OTP code
    setSeconds(30);
    setIsDisabled(true);
	SendSignupOtp();
  };
  
  
  return (
    <div className="signup-body">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6 col-xl-6">
            <div className="left-area">
              <div className="top-section">
                <img className="logo" src="logos/aadow-logo-white.svg" alt="Aadow Logo" />
              </div>
              <div className="middle-section">
                <div><h1 className="main-heading">Ready to Grow Your Business?</h1></div>
                <div><h6 className="main-description">Sign up and enjoy tools designed to scale your business</h6></div>
                <div><a className="main-link d-none">Get Started</a></div>
              </div>
              <div className="bottom-section">
                <p className="footer-description"><i className="bi bi-c-circle me-1"></i> {currentYear} Copyright: Aadow by Horza Private Limited</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 col-xl-6">
            <div className="right-area">
			
			{/* Signup Step Start */}
			{currentStep === 'step_one' && (
              <div id="step_one" className="right-content">
                <h2 className="secondary-heading">Signup to Account</h2>
                <h2 className="secondary-description">Get Started for free. No credit card required</h2>
                <button className="social-login d-none">
                  <img src="iconss/logos/google.jpg" alt="Google Icon" className="social-login-icon" />
                  <span>Sign Up with Google</span>
                </button>
                <button className="social-login  d-none">
                  <img src="iconss/logos/apple.jpg" alt="Apple Icon" className="social-login-icon" />
                  <span>Sign Up with Apple</span>
                </button>
                <div className="text-with-lines  d-none">
                  <span>Or</span>
                </div>
                
                <form>
                  <div className="form-area">
                    <div className="col-12">
                      <label>Name<b className="text-danger">*</b></label>
                      <input type="text" className={`input-form ${NameError ? 'input-error' : ''}`} onChange={(e) => setName(e.target.value)} />
					  {NameErrorMessage && <div className="error-message" >{NameErrorMessage}</div>}
                    </div>
					<div className="col-12">
                      <label>Email Address<b className="text-danger">*</b></label>
                      <input type="text" className={`input-form ${EmailError ? 'input-error' : ''}`} onChange={(e) => setEmail(e.target.value)} />
					  {EmailErrorMessage && <div className="error-message">{EmailErrorMessage}</div>}
                    </div>
                    <div className="col-12">
                      <label>Password<b className="text-danger">*</b></label>
                      <div className="password-container">
                        <input type={passwordVisible ? 'text' : 'password'} className={`input-form ${PasswordError ? 'input-error' : ''} pe-5`} onChange={(e) => setPassword(e.target.value)} />
                        <i className={`bi ${passwordVisible ? 'bi-eye-slash' : 'bi-eye'} password-icon`} onClick={togglePasswordVisibility}></i>
                      </div>
					  {PasswordErrorMessage && <div className="error-message">{PasswordErrorMessage}</div>}
                    </div>
					<div className="col-12">
						<h6 className="terms-policy">By Proceeding you must agree to our <Link target="_blank" to="/terms">Terms & Conditions</Link> and <Link target="_blank" to="/privacy-policy">Privacy Policy</Link></h6>
					</div>
                    <div className="col-12">
                      <button type="button" className="primary-button w-100" disabled={signupbuttonDisabled} onClick={handleSignup}>{signupbuttonText}</button>
                    </div>
                    <div className="col-12">
                      <p className="login-signup-link">Already have an account? <Link to="/login">Sign In</Link></p>
                    </div>
                  </div>
                </form>
              </div>
			  )}
			  {/*Signup Step End */}
			  
			  {/* Verification Required Step Start */}
			  {currentStep === 'step_two' && (
			  <div id="step_two" className="right-content">
                <h2 className="secondary-heading">Verification Required</h2>
                <h2 className="secondary-description">We sent a code to <b>{OtpSentEmail}</b> <a onClick={handleChangeEmail} className="change-email">Edit</a></h2>
                
                
                <form>
                  <div className="form-area">
                    <div className="col-12 otp-container">
                      {otp.map((data, index) => (
                        <input
                          type="text"
						  className={`otp-input ${otpError ? 'otp-error' : ''}`}
                          maxLength="1"
                          key={index}
                          value={data}
                          onChange={e => handleOtpChange(e.target, index)}
                          onFocus={e => e.target.select()}
                          onKeyDown={e => handleKeyDown(e, index)}
                        />
                      ))}
                    </div>
                    {otpErrorMessage && <div className="error-message">{otpErrorMessage}</div>}
                    <div className="col-12">
                      <button type="button" disabled={otpverifybuttonDisabled} className="primary-button w-100" onClick={handleVerifyOtp}>{otpverifybuttonText}</button>
                    </div>
					
                    <div className="col-12">
						 <button type="button" id="resend_code"
                          disabled={isDisabled}
                          className="secondary-button w-100"
                          onClick={handleResendCode}
                        >
                          {buttonText}
                        </button>
                    </div>
                    
                  </div>
                </form>
              </div>
			  )}
			  {/* Verification Step End */}
			  
			  
			  {/* Verified Step Start */}
			  {currentStep === 'step_three' && (
			  <div id="step_three" className="right-content">
				<img src="iconss/verify.png" className="success-icon" />
                <h2 className="secondary-heading text-center">Verified Successfully</h2>
                <h2 className="secondary-description text-center">We're redirecting you to your Dashboard.</h2>
              </div>
			  )}
			  {/* Verified Step End */}
			  
			  
            </div>
          </div>
        </div>
      </div>
	  
	  
      <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
      </Snackbar>
	  
    </div>
  );
};

export default Signup;
