import React, { useState, useEffect, useRef } from 'react';
import CountrySelect from './../../../components/CountrySelect';
import LanguageSelect from './../../../components/LanguageSelect';
import TimeZoneSelect from './../../../components/TimeZoneSelect';
import TimeFormatSelect from './../../../components/TimeFormatSelect';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const GeneralSettings = () => {
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };  
  
  const [countryselectedOption, countrysetSelectedOption] = useState(''); // Move state to parent
  const [languageselectedOption, languagesetSelectedOption] = useState(''); // Move state to parent
  const [timezoneselectedOption, timezonesetSelectedOption] = useState(''); // Move state to parent
  const [timeformatselectedOption, timeformatsetSelectedOption] = useState(''); // Move state to parent
  const [SubmitArea, setSubmitArea] = useState('');
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
    const handleChange = () => {
	  setSubmitArea("true");
	  };

  
  const handleSubmit = async () => {
	
	 handleSaveInfo();
	 
  };
  
  const handleCancel = async () => {
	  setSubmitArea("false");
  };
  
  useEffect(() => {
	  fetchGeneralSettings();
  }, []);
  
  const current_username = 'pachorieshan';
  const username = 'testuser';
  const password = 'testpassword';
  const encodedCredentials = btoa(`${username}:${password}`);
  
  
  const handleSaveInfo = async () => {
		const requestData = {
			username: current_username, // The username input
			country: countryselectedOption,
			language: languageselectedOption,
			timezone: timezoneselectedOption,
			timeformat: timeformatselectedOption,
		};
        try {
            const response = await fetch(`http://portal.aadow.com:8080/api/general-settings/save`, {
                method: 'POST',
                headers: {
				  'Content-Type': 'application/json', // Sending raw JSON data
				  'Authorization': `Basic ${encodedCredentials}`,
                },
				body: JSON.stringify(requestData),
            });

            if (response.ok) {
                const newApi = await response.json(); 
				
				setSubmitArea("false");
				showSnackbar('General Info Saved', 'success');
            } else {
                console.error('Failed to add the API');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
  
  const fetchGeneralSettings = async () => {
			try {
			  const response = await fetch(`http://portal.aadow.com:8080/api/general-settings/get/${current_username}`, {
				method: 'GET',
				headers: {
				  'Authorization': `Basic ${encodedCredentials}`,
				  'Content-Type': 'application/json',
				},
			  });

			  if (!response.ok) {
				throw new Error('Network response was not ok');
			  }

			
			  const data = await response.json();
			  countrysetSelectedOption(data.country);
			  timezonesetSelectedOption(data.timezone);
			  languagesetSelectedOption(data.language);
			  timeformatsetSelectedOption(data.timeformat);
			  setLoading(false);  // Set loading to false once data is fetched
			  
			} catch (error) {
			  setError("Fetch error:", error);
			  setLoading(false);
			}
   };
   
   
   if (loading) {
	  return <div>Loading...</div>; // Display loading message
   }

   if (error) {
   }

  return (					
			<div className="main-area">
		  
			<div className="secondary-heading-area">
					<h4 className="secondary-heading">General Settings</h4>
					<h6 className="secondary-subheading">Customize your experience by selecting your country, timezone, language, and time format for a tailored user experience.</h6>
			</div>

			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
						  <h4 className="secondary-heading">Country <b className="text-danger">*</b></h4>
						  <h6 className="secondary-subheading">Select your current location</h6>
					</div>
					<div className="col-5">
							<div className="w-75">
							<CountrySelect
								countryselectedOption={countryselectedOption}
								countryhandleChange={(value) => {
								  countrysetSelectedOption(value);
								  handleChange();
								}}
							  />
							</div>
					</div>
				</div>
			</div>

			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
						  <h4 className="secondary-heading">Language <b className="text-danger">*</b></h4>
						  <h6 className="secondary-subheading">Select your preferred language</h6>
					</div>
					<div className="col-5">
						<div className="w-75">
							<LanguageSelect
								languageselectedOption={languageselectedOption}
								languagehandleChange={(value) => {
								  languagesetSelectedOption(value);
								  handleChange();
								}}
							  />
						</div>	  
					</div>
				</div>
			</div>
			
			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
						  <h4 className="secondary-heading">Timezone <b className="text-danger">*</b></h4>
						  <h6 className="secondary-subheading">Select your preferred timezone</h6>
					</div>
					<div className="col-5">
						<div className="w-75">
							
							 <TimeZoneSelect
								timezoneselectedOption={timezoneselectedOption}
								timezonehandleChange={(value) => {
								  timezonesetSelectedOption(value);
								  handleChange();
								}}
							  />
						</div>	
					</div>
				</div>
			</div>
			
			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
						  <h4 className="secondary-heading">Time Format <b className="text-danger">*</b></h4>
						  <h6 className="secondary-subheading">Select your preferred time format</h6>
					</div>
					<div className="col-5">
					<div className="w-75">
							  <TimeFormatSelect
								timeformatselectedOption={timeformatselectedOption}
								timeformathandleChange={(value) => {
								  timeformatsetSelectedOption(value);
								  handleChange();
								}}
							  />
						</div>	
					</div>
				</div>
			</div>
			
			{SubmitArea === 'true' && (
			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
					</div>
					<div className="col-4">
					</div>
					<div className="col-4 text-end">
						<button type="button" onClick={handleCancel} className="primary-outline-button me-2">Cancel</button>
						<button type="button" onClick={handleSubmit} className="primary-button ms-2">Save Changes</button>
					</div>
				</div>
			</div>
			)}
			
		  <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
			<MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
		  </Snackbar>
			
		  </div>
					
  );
};

export default GeneralSettings;
