import React, { useState, useEffect, useRef } from 'react';
import CountrySelect from './../../../components/CountrySelect';
import LanguageSelect from './../../../components/LanguageSelect';
import TimeZoneSelect from './../../../components/TimeZoneSelect';
import TimeFormatSelect from './../../../components/TimeFormatSelect';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const BusinessDetails = () => {
 
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  useEffect(() => {
	  fetchBusinessSettings();
  }, []);
  
  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };  
	
  const [countryselectedOption, countrysetSelectedOption] = useState(''); // Move state to parent
  
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  const [SubmitArea, setSubmitArea] = useState('');
  const [FirstName, setFirstName] = useState('');
  const [LastName, setLastName] = useState('');
  const [Email, setEmail] = useState('');
  const [Phone, setPhone] = useState('');
  const [CompanyName, setCompanyName] = useState('');
  const [Gst, setGst] = useState('');
  const [Address, setAddress] = useState('');
  const [City, setCity] = useState('');
  const [State, setState] = useState('');
  const [Zip, setZip] = useState('');
  
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [FirstNameError, setFirstNameError] = useState(false);
  const [LastNameError, setLastNameError] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const [PhoneError, setPhoneError] = useState(false);
  const [CompanyError, setCompanyError] = useState(false);
  const [GstError, setGstError] = useState(false);
  const [AddressError, setAddressError] = useState(false);
  const [CityError, setCityError] = useState(false);
  const [StateError, setStateError] = useState(false);
  const [ZipError, setZipError] = useState(false);
  
  const [ErrorName, setErrorName] = useState('');
  const [ErrorEmailPhone, setErrorEmailPhone] = useState('');
  const [ErrorCompanyGst, setErrorCompanyGst] = useState('');
  const [ErrorAddress, setErrorAddress] = useState('');
  const [ErrorCityState, setErrorCityState] = useState('');
  const [ErrorPostalCountry, setErrorPostalCountry] = useState('');
  
  
  const FirstNameRef = useRef(null);
  const LastNameRef = useRef(null);
  const EmailRef = useRef(null);
  const PhoneRef = useRef(null);
  const CompanyRef = useRef(null);
  const GstRef = useRef(null);
  const AddressRef = useRef(null);
  const CityRef = useRef(null);
  const StateRef = useRef(null);
  const ZipRef = useRef(null);
  const CountryRef = useRef(null);
  
   const handleChange = () => {
	  setSubmitArea("true");
   };

  
  const handleSubmit = async () => {
		
		setErrorCompanyGst("");
		setCompanyError(false);
		setGstError(false);
		
		setErrorAddress("");
		setAddressError(false);
		
		setErrorCityState("");
		setCityError(false);
		setStateError(false);
		
		setErrorPostalCountry("");
		setZipError(false);
		
	if (CompanyName.trim() === '') {
		setErrorCompanyGst("Please enter company name");
		setCompanyError(true);
		CompanyRef.current.focus();
		return;
	}else if (Address.trim() === '') {
		setErrorAddress("Please enter address");
		setAddressError(true);
		AddressRef.current.focus();
		return;
	}else if (City.trim() === '') {
		setErrorCityState("Please enter city");
		setCityError(true);
		CityRef.current.focus();
		return;
	}else if (State.trim() === '') {
		setErrorCityState("Please enter state");
		setStateError(true);
		StateRef.current.focus();
		return;
	}else if (Zip.trim() === '') {
		setErrorPostalCountry("Please enter postal code");
		setZipError(true);
		ZipRef.current.focus();
		return;
	}else if (countryselectedOption.trim() === '') {
		setErrorPostalCountry("Please select country");
		return;
	}
	
	handleSaveInfo();
  };
  
  const current_username = 'pachorieshan';
  const username = 'testuser';
  const password = 'testpassword';
  const encodedCredentials = btoa(`${username}:${password}`);
  
  const handleSaveInfo = async () => {
		const requestData = {
			username: current_username,
			companyName: CompanyName,
			gst: Gst,
			address: Address,
			city: City,
			state: State,
			zip: Zip,
			country: countryselectedOption,
		};
        try {
            const response = await fetch(`http://portal.aadow.com:8080/api/business/update`, {
                method: 'PUT',
                headers: {
				  'Content-Type': 'application/json', // Sending raw JSON data
				  'Authorization': `Basic ${encodedCredentials}`,
                },
				body: JSON.stringify(requestData),
            });

            if (response.ok) {
				
                const newApi = await response.json(); 
				
				setSubmitArea("false");
				showSnackbar('Business Info Saved', 'success');
            } else {
                console.error('Failed to add the API');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
  
  const fetchBusinessSettings = async () => {
			try {
			  const response = await fetch(`http://portal.aadow.com:8080/api/business/get/${current_username}`, {
				method: 'GET',
				headers: {
				  'Authorization': `Basic ${encodedCredentials}`,
				  'Content-Type': 'application/json',
				},
			  });

			  if (!response.ok) {
				throw new Error('Network response was not ok');
			  }

			
			  const data = await response.json();
			  setCompanyName(data.companyName);
			  setGst(data.gst);
			  setAddress(data.address);
			  setCity(data.city);
			  setState(data.state);
			  setZip(data.zip);
			  countrysetSelectedOption(data.country);
			  setLoading(false);  // Set loading to false once data is fetched
			  
			} catch (error) {
			  setError("Fetch error:", error);
			  setLoading(false);
			}
   };
   
   
   if (loading) {
	  return <div>Loading...</div>; // Display loading message
   }

   if (error) {
   }
  
  
  const handleCancel = async () => {
	  setSubmitArea("false");
  };
  
  const handleInputChange = (value) => {
	  handleChange(); // Trigger the common handler
  };
  
  

  return (					
			<div className="main-area">
		  
			<div className="secondary-heading-area">
					<h4 className="secondary-heading">Business & Billing Infomration</h4>
					<h6 className="secondary-subheading">Please note that your billing information might not necessarily match the details in your account's profile. The billing information is used for generating invoices.</h6>
			</div>

			
			
			
			
			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
						  <h4 className="secondary-heading">Company Details <b className="text-danger">*</b></h4>
						  <h6 className="secondary-subheading">Enter company's name and tax information</h6>
					</div>
					
					<div className="col-6">
						<div className="row">
							<div className="col-6">
									<input type="text" ref={CompanyRef} className={`input-forms w-100 ${CompanyError ? 'input-error' : ''}`}  value={CompanyName} onChange={(e) => {
										  setCompanyName(e.target.value);
										  handleInputChange(e.target.value); // Call a custom handler with the value
										}} placeHolder="Company's Name" />
							</div>
							<div className="col-6">
									<input type="text" ref={GstRef} className={`input-forms w-100 ${GstError ? 'input-error' : ''}`}  value={Gst} onChange={(e) => {
										  setGst(e.target.value);
										  handleInputChange(e.target.value); // Call a custom handler with the value
										}} placeHolder="Tax Information" />
							</div>
										{ErrorCompanyGst && <div className="error-message">{ErrorCompanyGst}</div>}
							</div>
					</div>
				</div>
			</div>
			
			
			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
						  <h4 className="secondary-heading">Address <b className="text-danger">*</b></h4>
						  <h6 className="secondary-subheading">Enter company's full address</h6>
					</div>
					<div className="col-6">
					<div className="row">
					<div className="col-12">
							<input type="text" ref={AddressRef} className={`input-forms w-100 ${AddressError ? 'input-error' : ''}`} value={Address} onChange={(e) => {
								  setAddress(e.target.value);
								  handleInputChange(e.target.value); // Call a custom handler with the value
								}} placeHolder="Street Address" />
								
					</div>
								{ErrorAddress && <div className="error-message">{ErrorAddress}</div>}
					</div>
					</div>
				</div>
			</div>
			
			
			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
						  <h4 className="secondary-heading">City & State <b className="text-danger">*</b></h4>
						  <h6 className="secondary-subheading">Enter company's name and tax information</h6>
					</div>
					<div className="col-6">
					<div className="row">
						<div className="col-6">
								<input type="text" ref={CityRef} className={`input-forms w-100 ${CityError ? 'input-error' : ''}`} value={City} onChange={(e) => {
									  setCity(e.target.value);
									  handleInputChange(e.target.value); // Call a custom handler with the value
									}} placeHolder="City" />
						</div>
						<div className="col-6">
								<input type="text" ref={StateRef} className={`input-forms w-100 ${StateError ? 'input-error' : ''}`} value={State} onChange={(e) => {
									  setState(e.target.value);
									  handleInputChange(e.target.value); // Call a custom handler with the value
									}} placeHolder="State"  />
						</div>
									{ErrorCityState && <div className="error-message">{ErrorCityState}</div>}
					</div>
					</div>
				</div>
			</div>
			
			
			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
						  <h4 className="secondary-heading">Postal Code & Country <b className="text-danger">*</b></h4>
						  <h6 className="secondary-subheading">Enter company's name and tax information</h6>
					</div>
					<div className="col-6">
					<div className="row">
					<div className="col-6">
							<input type="text" ref={ZipRef} className={`input-forms w-100 ${ZipError ? 'input-error' : ''}`} value={Zip} onChange={(e) => {
								  setZip(e.target.value);
								  handleInputChange(e.target.value); // Call a custom handler with the value
								}} placeHolder="Postal Code" />
					</div>
					<div className="col-6">
							<CountrySelect
								countryselectedOption={countryselectedOption}
								countryhandleChange={(value) => {
								  countrysetSelectedOption(value);
								  handleChange();
								}}
							  />
					</div>
								{ErrorPostalCountry && <div className="error-message">{ErrorPostalCountry}</div>}
					</div>
					</div>
				</div>
			</div>

			
			{SubmitArea === 'true' && (
			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
					</div>
					<div className="col-4">
					</div>
					<div className="col-4 text-end">
						<button type="button" onClick={handleCancel} className="primary-outline-button me-2">Cancel</button>
						<button type="button" onClick={handleSubmit} className="primary-button ms-2">Save Changes</button>
					</div>
				</div>
			</div>
			)}
			
		  <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
			<MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
		  </Snackbar>
		  </div>
					
  );
};

export default BusinessDetails;
