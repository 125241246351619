import React, { useEffect, useState } from 'react';
import axios from 'axios';

const EmailContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(null);
  const [listId, setListId] = useState('');
  const [status, setStatus] = useState('');
  const [segment, setSegment] = useState('');
  const [folderId, setFolderId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const pageSize = 8; // Number of items per page

  const fetchContacts = async (page) => {
    try {
      let url;

      if (searchTerm) {
        url = `http://portal.aadow.com:8080/api/emailcontacts/search?username=pachorieshan&search=${searchTerm}&page=${page}`;
      } else {
        url = `http://portal.aadow.com:8080/api/emailcontacts/username?username=pachorieshan&page=${page}`;
        
        // Build the filter URL if any filter is applied
        if (listId || status || segment || folderId) {
          url = `http://portal.aadow.com:8080/api/emailcontacts/filters?username=pachorieshan&page=${page}`;
          const params = new URLSearchParams();

          if (listId) params.append('listid', listId);
          if (status) params.append('status', status);
          if (segment) params.append('segment', segment);
          if (folderId) params.append('folderid', folderId);

          url += '&' + params.toString();
        }
      }

      const response = await axios.get(url, {
        auth: {
          username: 'testuser',
          password: 'testpassword',
        },
      });

      setContacts(response.data.content);
      setTotalPages(response.data.totalPages);
      setError(null); // Clear any previous error
    } catch (err) {
      setError('Failed to fetch contacts. Please try again later.');
      setContacts([]);
      setTotalPages(0);
    }
  };

  useEffect(() => {
    fetchContacts(currentPage);
  }, [currentPage, listId, status, segment, folderId, searchTerm]);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      // If Enter is pressed, we trigger the fetch
      setCurrentPage(0); // Reset to first page when searching
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (!value) {
      setCurrentPage(0); // Reset to first page when search is cleared
    }
  };

  return (
    <div>
      <h1>Email Contacts</h1>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      
      <div>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyPress={handleSearchKeyPress}
        />
      </div>

      <div>
        <select onChange={(e) => setListId(e.target.value)} value={listId}>
          <option value="">Select List ID</option>
          {/* Add your options here */}
          <option value="318491">List 318491</option>
          {/* Add more options as needed */}
        </select>

        <select onChange={(e) => setStatus(e.target.value)} value={status}>
          <option value="">Select Status</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>

        <select onChange={(e) => setSegment(e.target.value)} value={segment}>
          <option value="">Select Segment</option>
          <option value="green">Green</option>
          <option value="red">Red</option>
        </select>

        <select onChange={(e) => setFolderId(e.target.value)} value={folderId}>
          <option value="">Select Folder ID</option>
          <option value="789101">Folder 789101</option>
        </select>
      </div>

      {contacts.length === 0 && !error ? (
        <div>No contacts found.</div>
      ) : (
        <ul>
          {contacts.map(contact => (
            <li key={contact.id}>
              {contact.firstname} {contact.lastname} - {contact.email} ({contact.status})
            </li>
          ))}
        </ul>
      )}
      <div>
        <button onClick={handlePrevPage} disabled={currentPage === 0}>
          Previous
        </button>
        <span> Page {currentPage + 1} of {totalPages} </span>
        
        {/* Pagination Buttons */}
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageClick(index)}
            disabled={index === currentPage}
            style={{ fontWeight: index === currentPage ? 'bold' : 'normal' }}
          >
            {index + 1}
          </button>
        ))}

        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
          Next
        </button>
      </div>
    </div>
  );
};

export default EmailContacts;
