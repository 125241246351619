import React, { useState, useEffect, useRef } from 'react';
import CountrySelect from './../../../components/CountrySelect';
import LanguageSelect from './../../../components/LanguageSelect';
import TimeZoneSelect from './../../../components/TimeZoneSelect';
import TimeFormatSelect from './../../../components/TimeFormatSelect';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const GeneralSettings = () => {
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };  
  
  const [SubmitArea, setSubmitArea] = useState('');
  const [twofaselectedValue, settwofaselectedValue] = React.useState('Disabled');
  const twofaselecthandleChange = (event) => {
    settwofaselectedValue(event.target.value);
	  if(twofaselectedValue === "Enabled"){
	  showSnackbar('2FA Disabled', 'success');
	  }else{
	  showSnackbar('2FA Enabled', 'success');
	  }
  };
  
  
  const [CurrentPassword, setCurrentPassword] = useState('');
  const [NewPassword, setNewPassword] = useState('');
  
  
  const [CurrentPasswordError, setCurrentPasswordError] = useState(false);
  const [NewPasswordError, setNewPasswordError] = useState(false);
  
  
  const [ErrorNewPassword, setErrorNewPassword] = useState('');
  const [ErrorCurrentPassword, setErrorCurrentPassword] = useState('');
  
  
  const CurrentPasswordRef = useRef(null);
  const NewPasswordRef = useRef(null);
  
  const handleCurrentPasswordChange = (value) => {
	  setSubmitArea("true");
  };	
  
  const handleNewPasswordChange = (value) => {
	  setSubmitArea("true");
  };	
  
  const handleCancel = async () => {
	  setSubmitArea("false");
  };
  
  const handleSubmit = async () => {
	  
		setCurrentPasswordError(false);
		setErrorCurrentPassword("");
		setNewPasswordError(false);
		setErrorNewPassword("");
		
	if (CurrentPassword.trim() === '') {
		setErrorCurrentPassword("Please enter current password");
		setCurrentPasswordError(true);
		return;
	}else if (NewPassword.trim() === '') {
		setErrorNewPassword("Please enter new password");
		setNewPasswordError(true);
		return;
	}
	handleLoginServer();
  };
  
  const handleLoginServer = async (e) => {

    // Collecting login data
    const loginData = {
        email: "eshupachori777@gmail.com", // Your email input
        password: CurrentPassword, // Your password input
    };

    // Collecting Basic Auth credentials from inputs
    const basicAuthUsername = "testuser"; // This should be set from user input
    const basicAuthPassword = "testpassword"; // This should be set from user input

    // Base64 encode the Basic Auth credentials
    const encodedCredentials = btoa(`${basicAuthUsername}:${basicAuthPassword}`);

    try {
        const response = await fetch('http://portal.aadow.com:8080/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${encodedCredentials}` // Add Basic Auth header
            },
            body: JSON.stringify(loginData), // Send login data (email and password)
        });

        const status = response.status; // Get the response status

        if (response.ok) {
            const data = await response.json();
			SaveNewPassword();
        } else {
			setErrorCurrentPassword("Incorrect Password");
			setCurrentPasswordError(true);
            const errorText = await response.text(); // Get error message from server response
            console.error('Login failed:', errorText);
            console.error(`Login failed. Status: ${status}. Message: ${errorText}`)
        }
    } catch (error) {
        console.error('Error during login:', error); // Log the entire error object
		
    }
};

  const current_username = 'pachorieshan';
  const account_id = '25';
  const username = 'testuser';
  const password = 'testpassword';
  const encodedCredentials = btoa(`${username}:${password}`);
	const SaveNewPassword = async () => {
		const requestData = {
			email: "eshupachori777@gmail.com",
			newPassword: NewPassword,
		};
        try {
            const response = await fetch(`http://portal.aadow.com:8080/auth/update-password`, {
                method: 'PUT',
                headers: {
				  'Content-Type': 'application/json', // Sending raw JSON data
				  'Authorization': `Basic ${encodedCredentials}`,
                },
				body: JSON.stringify(requestData),
            });
			
			
            if (response.ok) {
				
				setNewPassword("");
				setCurrentPassword("");
				setSubmitArea("false");
				showSnackbar('New Password Saved', 'success');
				
            } else {
                console.error('Failed to add the API');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
  
  useEffect(() => {
  }, []);

  return (					
			<div className="main-area">
		  
			<div className="secondary-heading-area">
					<h4 className="secondary-heading">Security Settings</h4>
					<h6 className="secondary-subheading">Manage your account security by updating your password and enabling two-factor authentication</h6>
			</div>

			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
						  <h4 className="secondary-heading">Current Password <b className="text-danger">*</b></h4>
						  <h6 className="secondary-subheading">Enter your current password</h6>
					</div>
					<div className="col-5">
								<input type="password" ref={CurrentPasswordRef} className={`input-forms w-75 ${CurrentPasswordError ? 'input-error' : ''}`} value={CurrentPassword} onChange={(e) => {
								  setCurrentPassword(e.target.value);
								  handleCurrentPasswordChange(e.target.value); // Call a custom handler with the value
								}} placeHolder="****************" />
								{ErrorCurrentPassword && <div className="error-message">{ErrorCurrentPassword}</div>}
					</div>
				</div>
			</div>
			
			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
						  <h4 className="secondary-heading">New Password <b className="text-danger">*</b></h4>
						  <h6 className="secondary-subheading">Enter your new password</h6>
					</div>
					<div className="col-5">
								<input type="password" ref={NewPasswordRef} className={`input-forms w-75 ${NewPasswordError ? 'input-error' : ''}`} value={NewPassword} onChange={(e) => {
								  setNewPassword(e.target.value);
								  handleNewPasswordChange(e.target.value); // Call a custom handler with the value
								}} placeHolder="****************" />
								{ErrorNewPassword && <div className="error-message">{ErrorNewPassword}</div>}
					</div>
					<div className="col-3 text-end d-none">
						<button type="button" className="primary-outline-button me-2">Cancel</button>
						<button type="button" className="primary-button ms-2">Send OTP</button>
					</div>
				</div>
			</div>
			
			<div className="form-area d-none">
				<div className="row align-items-center">
					<div className="col-4">
						  <h4 className="secondary-heading">2FA</h4>
						  <h6 className="secondary-subheading">Mange Two Factor Authentication</h6>
					</div>
					<div className="col-5">
						 
							
							<Select labelId="twofa-select-label" id="twofa-sell-emails-select" className="country-select w-75" value={twofaselectedValue} onChange={twofaselecthandleChange}>
									 <MenuItem key="Enabled" value="Enabled">
										<Box display="flex" alignItems="center">
										  <Typography>Enabled</Typography>
										</Box>
									  </MenuItem>
									  <MenuItem key="Disabled" value="Disabled">
										<Box display="flex" alignItems="center">
										  <Typography>Disabled</Typography>
										</Box>
									  </MenuItem>
							</Select>
					</div>
				</div>
			</div>

			
			
			{SubmitArea === 'true' && (
			<div className="form-area">
				<div className="row align-items-center">
					<div className="col-4">
					</div>
					<div className="col-4">
					</div>
					<div className="col-4 text-end">
						<button type="button" onClick={handleCancel} className="primary-outline-button me-2">Cancel</button>
						<button type="button" onClick={handleSubmit} className="primary-button ms-2">Save Changes</button>
					</div>
				</div>
			</div>
			)}
			
		  <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
			<MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
		  </Snackbar>
			
		  </div>
					
  );
};

export default GeneralSettings;
