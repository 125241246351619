import React, { useState, useEffect, useRef } from 'react';
import '../../css/public/contact.css';
import TopBar from './NavBar';
import Footer from './../components/Footer';

const Contact = () => {
  const [currentStep, setCurrentStep] = useState(1); // Step tracking
  
  const [selectedCardOne, setSelectedCardOne] = useState(''); // Manage selected card state
  const [ErrorStepOne, setErrorStepOne] = useState("");
  
  const [SubmitDisabled, setSubmitDisabled] = useState(false);
  const [SubmitButtonText, setSubmitButtonText] = useState("Submit");
  // Define your cards data
  const cardsOne = [
    { id: 1, icon: "bi bi-info-circle", title: "General Support", description: "For any general inquiries" },
    { id: 2, icon: "bi bi-code-slash", title: "Technical Support", description: "For Technical Issues" },
    { id: 3, icon: "bi bi-megaphone", title: "PR Inquiries", description: "Interested in collaborating" },
    { id: 4, icon: "bi bi-credit-card", title: "Payment & Billing", description: "For payments or invoice" },
    { id: 5, icon: "bi bi-emoji-smile", title: "Product Feedback", description: "Help us to Improve" },
    { id: 6, icon: "bi bi-question-circle", title: "Something Else", description: "For Other Inquiries" },
  ];

  // Function to handle card selection
  const handleCardOneClick = (title) => {
    setSelectedCardOne(title);
  };
  
  const [selectedCardTwo, setSelectedCardTwo] = useState(""); // Manage selected card state

  // Define your new cards data
  const cardsTwo = [
    { id: 2, icon: "bi bi-envelope-at", title: "Email", description: "Available Mon-Sat" },
    { id: 3, icon: "bi bi-info-circle", title: "FAQs", description: "Available 24/7" },
  ];

  // Function to handle card selection
  const handleCardTwoClick = (id) => {
    setSelectedCardTwo(id);
  };
  
  const [TicketId, setTicketId] = useState('');
  useEffect(() => {
    document.title = 'Contact Us | Aadow';
    const generateTicketId = () => {
      const min = 10000000; // Minimum value for 8 digits
      const max = 9999999999; // Maximum value for 10 digits
      const randomTicketId = Math.floor(Math.random() * (max - min + 1)) + min;
      setTicketId(randomTicketId.toString());
    };

    // Generate the ticket ID when the component mounts
    generateTicketId();
  }, []);
  
  useEffect(() => {
    if (TicketId) {
		
      setTicketIdInput(TicketId);
    }
  }, [TicketId]);


  
  const [TicketIdInput, setTicketIdInput] = useState('');
  
  
  const [Name, setName] = useState('');
  const [NameError, setNameError] = useState(false);
  const [ErrorName, setErrorName] = useState('');
  const NameRef = useRef(null);
  
  
  const [Email, setEmail] = useState('');
  const [EmailError, setEmailError] = useState(false);
  const [ErrorEmail, setErrorEmail] = useState('');
  const EmailRef = useRef(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  
  const [Phone, setPhone] = useState('');
  const [PhoneError, setPhoneError] = useState(false);
  const [ErrorPhone, setErrorPhone] = useState('');
  const PhoneRef = useRef(null);
  
  const [Message, setMessage] = useState('');
  const [MessageError, setMessageError] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState('');
  const MessageRef = useRef(null);
  
  const [AccountId, setAccountId] = useState('');
  const [AccountIdError, setAccountIdError] = useState(false);
  const [ErrorAccountId, setErrorAccountId] = useState('');
  const AccountIdRef = useRef(null);
  
  // Function to handle "Next" button click
   const handleSubmit = () => {
	   setNameError(false);
	   setErrorName("");
	   setEmailError(false);
	   setErrorEmail("");
	   setPhoneError(false);
	   setErrorPhone("");
	   setMessageError(false);
	   setErrorMessage("");
	   if (Name === '') {
	    setNameError(true);
	    setErrorName("Please enter a name");
		NameRef.current.focus();
		return;
	   }else if (Email === '') {
	    setEmailError(true);
	    setErrorEmail("Please enter a email address");
		EmailRef.current.focus();
		return;
	   }else if (!emailRegex.test(Email)) {
	    setEmailError(true);
	    setErrorEmail("Please enter a valid email address");
		EmailRef.current.focus();
		return;
	   }else if (Phone === '') {
	    setPhoneError(true);
	    setErrorPhone("Please enter a phone");
		PhoneRef.current.focus();
		return;
	   }else if (Message === '') {
	    setMessageError(true);
	    setErrorMessage("Please enter a Message");
		MessageRef.current.focus();
		return;
	   }
	   
	   setSubmitDisabled(true);
	   setSubmitButtonText("Submitting...");
	   submitSupportTicket();
   };
  
  const handleNext = () => {
	 setErrorStepOne(""); 
	 if(currentStep === 1){
		 if (selectedCardOne === '') {
			 setErrorStepOne("Please Select an option");
			return;
		 }
	 }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  // Function to handle "Back" button click
  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };
  
  const [ticketData, setTicketData] = useState({
    
  });
  
  const submitSupportTicket = async () => {
	
	const ticketData = {
		ticketId: TicketIdInput, // Ensure TicketIdInput is correctly set before this function
		queryCategory: selectedCardOne,
		name: Name,
		accountId: AccountId,
		email: Email,
		phone: Phone,
		message: Message,
		status: "Open",
	  };  
	  
    const username = "testuser"; // Replace with your actual username
    const password = "testpassword"; // Replace with your actual password
    const credentials = btoa(`${username}:${password}`); // Encode to Base64 for Basic Auth

    try {
      const response = await fetch("http://localhost:8080/api/support-ticket/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Basic ${credentials}`, // Add Basic Auth header
        },
        body: JSON.stringify(ticketData),
      });

      if (response.ok) {
        const data = await response.json();
		setTicketId(data.ticketId);
		setCurrentStep(3);
      } else {
	    setErrorMessage("Failed to submit support ticket.");
		setSubmitDisabled(false);
		setSubmitButtonText("Submit");
      }
    } catch (error) {
	  setErrorMessage("Failed to submit support ticket.");
	  setSubmitDisabled(false);
	  setSubmitButtonText("Submit");
    }
  };
  

  return (
    <div className="contact-body">
      <TopBar />
      <div className="main-area">
        <div className="row">
          {/* Left Area */}
          <div className="col-12 col-md-12 col-lg-3 col-xl-3 order-2 order-md-2 order-lg-1 order-xl-1 m-0 p-0 ps-2">
            <div className="left-area">
              <div className="main-content">
                {/* Contact Option */}
                <div className="contact-option">
                  <div className="row">
                    <div className="col-3 col-md-3 col-lg-2 col-xl-2">
                      <a href=""><i className="bi bi-envelope-at"></i></a>
                    </div>
                    <div className="col-9 col-md-9 col-lg-10 col-xl-10">
                      <h6 className="heading">Mail us</h6>
                      <h6 className="description">Our friendly team is here to help</h6>
                      <b className="contact-link">support@aadow.com</b>
                    </div>
                  </div>
                </div>
                {/* Other Contact Options */}
                <div className="contact-option">
                  <div className="row">
                    <div className="col-3 col-md-3 col-lg-2 col-xl-2">
                      <a href=""><i className="bi bi-geo-alt"></i></a>
                    </div>
                    <div className="col-9 col-md-9 col-lg-10 col-xl-10">
                      <h6 className="heading">Office</h6>
                      <h6 className="description">Come say hello to our HQ</h6>
                      <b className="contact-link">Horza Pvt Ltd, Devinagar, Bangalore, KA-560094 INDIA</b>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer-content">
                <a href="https://www.facebook.com/officialaadow/" target='_blank'><i className="bi bi-facebook"></i></a>
                <a href="https://x.com/aadow_com" target='_blank'><i className="bi bi-twitter-x"></i></a>
                <a href="https://www.linkedin.com/company/aadow" target='_blank'><i className="bi bi-linkedin"></i></a>
                <a href="https://www.instagram.com/officialaadow/" target='_blank'><i className="bi bi-instagram"></i></a>
                <a href="https://www.youtube.com/c/Aadow" target='_blank'><i className="bi bi-youtube"></i></a>
              </div>
            </div>
          </div>

          {/* Right Area */}
          <div className="col-12 col-md-12 col-lg-9 col-xl-9 order-1 order-md-1 order-lg-2 order-xl-2 m-0 p-0">
            <div className="right-area">
              <div className="main-content">
                {/* Conditionally Render Steps */}
                {currentStep === 1 && (
                  <div className="steps-area">
                    <h2>Get in Touch with Our Team</h2>
                    <div className="row">
									{cardsOne.map((card) => (
										<div key={card.id} className="col-6 col-md-6 col-lg-4 col-xl-4">
										  <div
											className={`radio-card ${selectedCardOne === card.id ? "active" : ""}`} // Apply active class if selected
											onClick={() => handleCardOneClick(card.id)}
										  >
											<i className={card.icon}></i>
											<h4>{card.title}</h4>
											<h6>{card.description}</h6>
										  </div>
										</div>
									  ))}
								</div>
								{ErrorStepOne && <div className="error-message">{ErrorStepOne}</div>}
                    <center>
                      <button className="primary-button w-50" onClick={handleNext}>Continue</button>
                    </center>
                  </div>
                )}

                

                {currentStep === 2 && (
                  <div className="steps-area">
                    <h2>Get in Touch with Our Team</h2>
                   <form className="mb-2">
										<div className="row">
											<div className="col-12 col-md-12 col-lg-6 col-xl-6">
												<label>Name<b className="text-danger">*</b></label>
												<input type="text" ref={NameRef} className={`input-form w-100 ${NameError ? 'input-error' : ''}`} value={Name} onChange={(e) => {
													  setName(e.target.value);
													}} />
												{ErrorName && <div className="error-message">{ErrorName}</div>}
											</div>
											<div className="col-12 col-md-12 col-lg-6 col-xl-6">
												<label>Email Address<b className="text-danger">*</b></label>
												<input type="text" ref={EmailRef} className={`input-form w-100 ${EmailError ? 'input-error' : ''}`} value={Email} onChange={(e) => {
													  setEmail(e.target.value);
													}} />
												{ErrorEmail && <div className="error-message">{ErrorEmail}</div>}
											</div>
											<div className="col-6">
												<label>Phone Number<b className="text-danger">*</b></label>
												<input type="text" ref={PhoneRef} className={`input-form w-100 ${PhoneError ? 'input-error' : ''}`} value={Phone} onChange={(e) => {
													  setPhone(e.target.value);
													}} />
												{ErrorPhone && <div className="error-message">{ErrorPhone}</div>}
											</div>
											<div className="col-6">
												<label>Account ID</label>
												<input type="text" ref={AccountIdRef} className={`input-form w-100 ${AccountIdError ? 'input-error' : ''}`} value={AccountId} onChange={(e) => {
													  setAccountId(e.target.value);
													}} />
													
													<input type="text" className={`input-form w-100 d-none`} value={TicketId} onChange={(e) => {
													  setTicketIdInput(e.target.value);
													}} />
												{ErrorAccountId && <div className="error-message">{ErrorAccountId}</div>}
											</div>
											<div className="col-12">
												<label>Message<b className="text-danger">*</b></label>
												<textarea rows="3" ref={MessageRef} className={`input-form w-100 ${MessageError ? 'input-error' : ''}`} value={Message} onChange={(e) => {
													  setMessage(e.target.value);
													}} ></textarea>
												{ErrorMessage && <div className="error-message">{ErrorMessage}</div>}
											</div>
										</div>
									</form>
                    <div className="row">
                      <div className="col-6">
                        <button className="primary-outline-button w-100" onClick={handleBack}>Back</button>
                      </div>
                      <div className="col-6">
                        <button className="primary-button w-100" disabled={SubmitDisabled} onClick={handleSubmit}>{SubmitButtonText}</button>
                      </div>
                    </div>
                  </div>
                )}

                {currentStep === 3 && (
                  <div className="steps-area">
                    <div className="text-center success-area">
                      <img src="iconss/other/approved.png" className="success-icon" />
                      <h2 className="secondary-heading">Support Ticket Created with ID #{TicketId}</h2>
                      <h2 className="secondary-description">We have received your query, and one of our executives will get in touch with you using the contact information you provided.</h2>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
