import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/components/sidebar.css';
import Cookies from 'js-cookie';

const SideBar = ({ children }) => {
	
  const [isNotificationBarOpen, setNotificationBarIsOpen] = useState(false);
  
  
  
  const toggleNotificationBarNav = () => {
    setNotificationBarIsOpen(!isNotificationBarOpen);
  };

  const [openSection, setOpenSection] = useState(null);
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close menu if clicking outside
  useEffect(() => {
	  const CookietId = Cookies.get('current_Id');
	  const CookieAccountId = Cookies.get('current_accountId');
	  const CookieAccountName = Cookies.get('current_accountName');
	  const CookieAccountEmail = Cookies.get('current_accountEmail');
	  if (CookieAccountId) {
		  
	  } else {
		  window.location.href = `/login`;
	  }  
	  
	  
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);
   
  
	  const CookietId = Cookies.get('current_Id');
	  const CookieAccountId = Cookies.get('current_accountId');
	  const CookieAccountName = Cookies.get('current_accountName');
	  const CookieAccountEmail = Cookies.get('current_accountEmail'); 
   
  return (
    <div className="sidebar-body">
      <div className="main-container">
        <div className="small-side-bar">
          <div className="top-area" onClick={toggleSidebar}>
            <img src="https://www.aadow.com/favicon.png" className="logo" alt="Logo" />
            <a href="#" className="nav-link"><i className="bi bi-house"></i></a>
            <a href="#" className="nav-link"><i className="bi bi-megaphone"></i></a>
            <a href="#" className="nav-link"><i className="bi bi-bell"></i></a>
            <a href="#" className="nav-link"><i className="bi bi-palette"></i></a>
            <a href="#" className="nav-link"><i className="bi bi-people"></i></a>
            <a href="#" className="nav-link"><i className="bi bi-envelope-at"></i></a>
            <a href="#" className="nav-link"><i className="bi bi-code-square"></i></a>
          </div>

          <div className="bottom-area">
            <div className="mid-area mid-area-sidebar" onClick={toggleSidebar}>
			  <a href="#" className="nav-link"><i className="bi bi-gear"></i></a>
              <a href="#" className="nav-link"><i className="bi bi-life-preserver"></i></a>
            </div>
            <div className="profile text-center">
              <div className="profile-menu-container" ref={menuRef}>
                <img
                  src="https://www.aadow.com/icons/user-avatar.png"
                  className="profile-image"
                  alt="Profile"
                  onClick={toggleMenu}
                />

                {isMenuOpen && (
                  <div className="profile-menu">
                    <div className="top-area">
                      <div className="row align-items-center">
                        <div className="col-2">
                          <img
                            src="https://www.aadow.com/icons/user-avatar.png"
                            className="person-image"
                          />
                        </div>
                        <div className="col-10">
                          <h3 className="person-name">{CookieAccountName}</h3>
                          <h6 className="person-email">{CookieAccountEmail}</h6>
                        </div>
                      </div>
                    </div>

                    <div className="mid-area text-start">
                      <div className="mid-area-menu">
                        <Link to="/settings#my-plan" className="profile-menu-link">
                          <i className="bi bi-bar-chart"></i> View Account Usage
                        </Link>
                        <Link onClick={toggleNotificationBarNav} className="profile-menu-link">
                          <i className="bi bi-bell"></i> Notifications
                        </Link>
                        <Link to="/settings" className="profile-menu-link">
                          <i className="bi bi-gear"></i> Account Settings
                        </Link>
                      </div>

                      <div className="bottom-area-menu">
                        <Link to="/logout" className="profile-menu-link">
                          <i className="bi bi-box-arrow-left"></i> Logout
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={`side-bar ${isSidebarVisible ? '' : 'd-none'}`}>
          <div className="logo-area">
            <h2 className="nav-title">Dashboard</h2>
          </div>
          <Link to="/dashboard" className="nav-link"><i className="bi bi-house"></i> Dashboard</Link>
          <a href="#" className="nav-link" onClick={() => toggleSection('campaigns')}><i className="bi bi-megaphone"></i> Campaigns</a>
          {openSection === 'campaigns' && (
            <div className="accordion-menu">
              <Link to="/campaigns/email" className="nav-link">Email Campaigns</Link>
              <Link to="/campaigns/sms" className="nav-link d-none">Sms Campaigns</Link>
            </div>
          )}
          <a href="#" onClick={toggleNotificationBarNav} className="nav-link"><i className="bi bi-bell"></i> Notifications</a>
		  <a href="#" className="nav-link" onClick={() => toggleSection('templates')}>
            <i className="bi bi-palette"></i> Templates
          </a>
          {openSection === 'templates' && (
            <div className="accordion-menu">
              <Link to="/email-templates" className="nav-link">Email Templates</Link>
              <Link to="/sms-templates" className="nav-link d-none">Sms Templates</Link>
            </div>
          )}
          <Link to="/audience-overview" className="nav-link"><i className="bi bi-people"></i> Audience</Link>
          <Link to="/sender-authentication" className="nav-link"><i className="bi bi-envelope-at"></i> Sender Authentication</Link>
		  <a href="#" className="nav-link" onClick={() => toggleSection('api')}>
            <i className="bi bi-code-square"></i> Manage API
          </a>
          {openSection === 'api' && (
            <div className="accordion-menu">
              <Link to="/api/email" className="nav-link">Email API</Link>
              <Link to="/api/sms" className="nav-link d-none">SMS API</Link>
            </div>
          )}
          <Link to="/settings" className="nav-link"><i className="bi bi-gear"></i> Settings</Link>
          <a href="faqs" target="_blank" className="nav-link"><i className="bi bi-info-circle"></i> Help & Support</a>
        </div>


		
		
		 <div className={`notification-overlay ${isNotificationBarOpen ? 'show' : ''}`} onClick={toggleNotificationBarNav}></div>
		  <div className={`notification-sidenav ${isNotificationBarOpen ? 'open' : ''}`}>
			
			<div className="notification-header-area">
				<div className="row align-items-center">
				
					<div className="col-10">
						<h3 className="notification-header-area-heading">Notifications</h3>
					</div>
					
					<div className="col-2">
						<a href="#" className="close-notification-bar" onClick={toggleNotificationBarNav}><i className="bi bi-x"></i></a>
					</div>
				</div>
			</div>
			
			<div className="notification-list-area">
			
				 <div className="no-notification-area">
					<div className="text-center">
						<img className="no-notification-icon" src="iconss/other/empty-box.png" />	
						<h4 className="no-notification-heading">Nothing to See Here</h4>
						<h4 className="no-notification-subheading">You don’t have any new notifications right now. Stay tuned for future updates and alerts.</h4>
					</div>	
				 </div>
				 <div className="list-area-start d-none">
					<div className="notification-list">
						<div className="row align-items-center">
							<div className="col-2">
								<img src="https://cdn.openart.ai/published/Eb8kKVRSVNmrpwsB1rRO/SFTYEVEo_tqaf_raw.jpg" className="notification-list-icon" />
							</div>
							<div className="col-10">
								<h6 className="notification-list-heading">Your Campaign <b>Guest-List-2.0</b> has been sent</h6>
								<h6 className="notification-list-subheading">Aug 24, 12:34 PM</h6>
							</div>
						</div>
					</div>
					
					<div className="notification-list">
						<div className="row align-items-center">
							<div className="col-2">
								<img src="https://cdn.openart.ai/published/Eb8kKVRSVNmrpwsB1rRO/SFTYEVEo_tqaf_raw.jpg" className="notification-list-icon" />
							</div>
							<div className="col-10">
								<h6 className="notification-list-heading">Your Campaign <b>Guest-List-2.0</b> has been sent</h6>
								<h6 className="notification-list-subheading">Aug 24, 12:34 PM</h6>
							</div>
						</div>
					</div>
					
					<div className="notification-list">
						<div className="row align-items-center">
							<div className="col-2">
								<img src="https://cdn.openart.ai/published/Eb8kKVRSVNmrpwsB1rRO/SFTYEVEo_tqaf_raw.jpg" className="notification-list-icon" />
							</div>
							<div className="col-10">
								<h6 className="notification-list-heading">Your Campaign <b>Guest-List-2.0</b> has been sent</h6>
								<h6 className="notification-list-subheading">Aug 24, 12:34 PM</h6>
							</div>
						</div>
					</div>
					
					<div className="notification-list">
						<div className="row align-items-center">
							<div className="col-2">
								<img src="https://cdn.openart.ai/published/Eb8kKVRSVNmrpwsB1rRO/SFTYEVEo_tqaf_raw.jpg" className="notification-list-icon" />
							</div>
							<div className="col-10">
								<h6 className="notification-list-heading">Your Campaign <b>Guest-List-2.0</b> has been sent</h6>
								<h6 className="notification-list-subheading">Aug 24, 12:34 PM</h6>
							</div>
						</div>
					</div>
					
					<div className="notification-list">
						<div className="row align-items-center">
							<div className="col-2">
								<img src="https://cdn.openart.ai/published/Eb8kKVRSVNmrpwsB1rRO/SFTYEVEo_tqaf_raw.jpg" className="notification-list-icon" />
							</div>
							<div className="col-10">
								<h6 className="notification-list-heading">Your Campaign <b>Guest-List-2.0</b> has been sent</h6>
								<h6 className="notification-list-subheading">Aug 24, 12:34 PM</h6>
							</div>
						</div>
					</div>
					
					<div className="notification-list">
						<div className="row align-items-center">
							<div className="col-2">
								<img src="https://cdn.openart.ai/published/Eb8kKVRSVNmrpwsB1rRO/SFTYEVEo_tqaf_raw.jpg" className="notification-list-icon" />
							</div>
							<div className="col-10">
								<h6 className="notification-list-heading">Your Campaign <b>Guest-List-2.0</b> has been sent</h6>
								<h6 className="notification-list-subheading">Aug 24, 12:34 PM</h6>
							</div>
						</div>
					</div>
					
					<div className="notification-list">
						<div className="row align-items-center">
							<div className="col-2">
								<img src="https://cdn.openart.ai/published/Eb8kKVRSVNmrpwsB1rRO/SFTYEVEo_tqaf_raw.jpg" className="notification-list-icon" />
							</div>
							<div className="col-10">
								<h6 className="notification-list-heading">Your Campaign <b>Guest-List-2.0</b> has been sent</h6>
								<h6 className="notification-list-subheading">Aug 24, 12:34 PM</h6>
							</div>
						</div>
					</div>
					
					<div className="notification-list">
						<div className="row align-items-center">
							<div className="col-2">
								<img src="https://cdn.openart.ai/published/Eb8kKVRSVNmrpwsB1rRO/SFTYEVEo_tqaf_raw.jpg" className="notification-list-icon" />
							</div>
							<div className="col-10">
								<h6 className="notification-list-heading">Your Campaign <b>Guest-List-2.0</b> has been sent</h6>
								<h6 className="notification-list-subheading">Aug 24, 12:34 PM</h6>
							</div>
						</div>
					</div>
					
					<div className="notification-list">
						<div className="row align-items-center">
							<div className="col-2">
								<img src="https://cdn.openart.ai/published/Eb8kKVRSVNmrpwsB1rRO/SFTYEVEo_tqaf_raw.jpg" className="notification-list-icon" />
							</div>
							<div className="col-10">
								<h6 className="notification-list-heading">Your Campaign <b>Guest-List-2.0</b> has been sent</h6>
								<h6 className="notification-list-subheading">Aug 24, 12:34 PM</h6>
							</div>
						</div>
					</div>
				</div>
				
			</div>
			
			
		  </div>
		  
		  
        <div className="main-body">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
