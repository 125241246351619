import React from 'react';
import { Select, MenuItem, Box, Typography } from '@mui/material';

const TimeZoneSelect = ({ timezoneselectedOption, timezonehandleChange }) => {
  const timezoneoptions = [
	{ value: 'America/New_York', label: 'New York (UTC-05:00)' },
	{ value: 'Europe/London', label: 'London (UTC+00:00)' },
	{ value: 'Asia/Tokyo', label: 'Tokyo (UTC+09:00)' },
	{ value: 'Australia/Sydney', label: 'Sydney (UTC+10:00)' },
	{ value: 'Europe/Berlin', label: 'Berlin (UTC+01:00)' },
	{ value: 'Asia/Kolkata', label: 'Kolkata (UTC+05:30)' },
	{ value: 'Europe/Moscow', label: 'Moscow (UTC+03:00)' },
	{ value: 'America/Los_Angeles', label: 'Los Angeles (UTC-08:00)' },
	{ value: 'Asia/Dubai', label: 'Dubai (UTC+04:00)' },
	{ value: 'Africa/Cairo', label: 'Cairo (UTC+02:00)' }
  ]; 

  return (
    <Select
      labelId="timezone-select-label"
      id="timezone-select"
      className="country-select w-100"
      value={timezoneselectedOption} // Use value from props
      onChange={(event) => timezonehandleChange(event.target.value)} // Call function from props
    >
      {timezoneoptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Box display="flex" alignItems="center">
            <Typography>{option.label}</Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};

export default TimeZoneSelect;
