import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import '../../css/private/email-campaigns.css';
import SideBar from './../components/SideBar';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

  const EmailCampaigns = () => {
  
   // Initialize with the current hash
  
  const [showDropdown, setShowDropdown] = useState(null); // Track which dropdown is open
  const dropdownRefs = useRef([]); // Array to hold refs for multiple dropdowns

  const toggleDropdown = (index) => {
    setShowDropdown(showDropdown === index ? null : index); // Toggle dropdown based on index
  };

  const closeDropdown = () => {
    setShowDropdown(null);
  };

  // Close dropdown when clicking outside of it
  useEffect(() => {
    document.title = 'Email Campaigns | Aadow';

    const handleClickOutside = (event) => {
      if (dropdownRefs.current.some(ref => ref && !ref.contains(event.target))) {
        setShowDropdown(null);
      }
    };

    
    // Set the active tab based on the current hash
    const handleHashChange = () => {
      setActiveTab(window.location.hash || '#All'); // Default to '#All' if no hash exists
    };

    // On mount, set the active tab
    handleHashChange();

    // Listen for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  
  const [triggeredBy, setTriggeredBy] = useState('All');
  const [duration, setDuration] = useState('All');
  const [campaignType, setCampaignType] = useState('All');
  const [sortOrder, setSortOrder] = useState('All');
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [verifyDomainLink, setVerifyDomainLink] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
	const current_username = 'pachorieshan';
	const username = 'testuser';
	const password = 'testpassword';
	const encodedCredentials = btoa(`${username}:${password}`);
	
	const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
	
	const handleClickOpen = (id) => {
        setSelectedId(id);
        setOpen(true);
		closeDropdown();
    };

    const handleCloseDeleteModal = () => {
        setOpen(false);
        setSelectedId(null);
    };
  
  const HandleShowModal = () => {
	  
    setShow(true);
	setErrorDomain("");
	setDomain("");
    setDomainError(false);
    setInputApiArea("true");
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };  
  
  const [Domain, setDomain] = useState('');
  const [DomainError, setDomainError] = useState(false);
  const [ErrorDomain, setErrorDomain] = useState('');
  const [DomainAdded, setDomainAdded] = useState('');
  const [DomainSentTo, setDomainSentTo] = useState('');
  const DomainRef = useRef(null);
  
  
  
  const [InputApiArea, setInputApiArea] = useState('true');
  
  
  const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [noData, setNoData] = useState(false);
    const loadedIds = useRef(new Set());

    
	const [activeTab, setActiveTab] = useState(window.location.hash || '#All');
    // ... other state variables

    const fetchData = useCallback(async () => {
		if (loading || !hasMore) return;
	
		setLoading(true);
		const AUTH = 'Basic ' + btoa('testuser:testpassword');
		let url;
	
		try {
			// Determine which URL to use based on filters
			if (
				triggeredBy === 'All' &&
				duration === 'All' &&
				campaignType === 'All' &&
				sortOrder === 'All' &&
				activeTab === '#All'
			) {
				// Use the default URL when all filters are "All"
				url = `http://portal.aadow.com:8080/api/campaigns/username/pachorieshan?page=${page}`;
			} else {
				// Use the filtered URL otherwise
				url = `http://portal.aadow.com:8080/api/campaigns/filtered?username=pachorieshan&page=${page}`;
	
				// Append filters to the URL
				if (activeTab !== '#All') {
					const status = activeTab.replace('#', '').toLowerCase();
					url += `&status=${status}`;
				}
				if (triggeredBy !== 'All') {
					url += `&fromEmail=${triggeredBy}`;
				}
				if (duration !== 'All') {
					url += `&duration=${duration}`;
				}
				if (campaignType !== 'All') {
					url += `&campaignLabel=${campaignType}`;
				}
				if (sortOrder !== 'All') {
					url += `&sortOrder=${sortOrder}`;
				}
			}
	
	
			const response = await fetch(url, {
				headers: {
					'Authorization': AUTH,
				},
			});
	
			const result = await response.json();
	
			if (result.content.length > 0) {
				const newData = result.content.filter(item => !loadedIds.current.has(item.campaignId));
	
				if (newData.length > 0) {
					setData(prevData => [...prevData, ...newData]);
					newData.forEach(item => loadedIds.current.add(item.campaignId)); // Mark IDs as loaded
					setPage(prevPage => prevPage + 1);
				}
			} else {
				setHasMore(false); // No more data available
			}
	
			// Check if no data was returned initially
			if (page === 0 && result.content.length === 0) {
				setNoData(true);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setLoading(false);
		}
	}, [loading, hasMore, page, activeTab, triggeredBy, duration, campaignType, sortOrder]);
	

    // useEffect for fetching data
    useEffect(() => {
        fetchData();
    }, [fetchData, activeTab]); // Now it works because fetchData is defined

	const handleTriggeredByChange = (event) => {
		setTriggeredBy(event.target.value);
		setPage(0); // Reset page number
		setData([]); // Clear existing data
		setHasMore(true); // Reset hasMore to true to allow fetching new data
		loadedIds.current.clear(); // Clear loaded IDs for new fetch
		setNoData(false);
		fetchData(); // Fetch new data based on the selected filter
	};

	const handleDurationChange = (event) => {
		setDuration(event.target.value);
		setPage(0); // Reset page number
        setData([]); // Clear existing data
        setHasMore(true); // Reset hasMore to true to allow fetching new data
        loadedIds.current.clear(); // Clear loaded IDs for new fetch
        setNoData(false);
	};

	const handleCampaignTypeChange = (event) => {
		setCampaignType(event.target.value);
		setPage(0); // Reset page number
        setData([]); // Clear existing data
        setHasMore(true); // Reset hasMore to true to allow fetching new data
        loadedIds.current.clear(); // Clear loaded IDs for new fetch
        setNoData(false);
	};

	const handleSortOrderChange = (event) => {
		setSortOrder(event.target.value);
		setPage(0); // Reset page number
        setData([]); // Clear existing data
        setHasMore(true); // Reset hasMore to true to allow fetching new data
        loadedIds.current.clear(); // Clear loaded IDs for new fetch
        setNoData(false);
	};
    // Function to handle tab click
    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setPage(0); // Reset page number
        setData([]); // Clear existing data
        setHasMore(true); // Reset hasMore to true to allow fetching new data
        loadedIds.current.clear(); // Clear loaded IDs for new fetch
        setNoData(false);
    };

    

    const observer = useRef();

    const lastElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                fetchData();
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, fetchData]);
	
	
	
	const handleDelete = async () => {
		try {
			const response = await fetch(`http://portal.aadow.com:8080/api/campaigns/delete/${current_username}/campaign/${selectedId}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Basic ${encodedCredentials}`,
				},
			});

			if (response.ok) {
            setData(prevData => {
                const updatedData = prevData.filter(item => item.campaignId !== selectedId);
                setNoData(updatedData.length === 0); // Check if the data is empty
                return updatedData;
            });
            showSnackbar('Email Campaign Removed!', 'success');
        } else {
            alert('Failed to delete the Email Campaign');
        }
		} catch (error) {
			alert('Error:', error);
		} finally {
			handleCloseDeleteModal();
		}
	};

	const [emails, setEmails] = useState([]);
  useEffect(() => {
		const fetchEmails = async () => {
			try {
				const response = await fetch(`http://localhost:8080/api/senders_email/emails?username=pachorieshan&status=active`);
				const data = await response.json();
				setEmails(data);
			} catch (error) {
				console.error('Error fetching emails:', error);
			}
		};

		fetchEmails();
	}, [username]);

  return (
    <SideBar>
      <div className="page-content ">
        <div className="email-campaigns-body">
		<div className="breadcrumb-area"> 
			 <nav aria-label="breadcrumb">
			  <ol className="breadcrumb">
				<li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
				<li className="breadcrumb-item"><Link>Campaigns</Link></li>
				<li className="breadcrumb-item active" aria-current="page">Email</li>
			  </ol>
			</nav>
		</div>	
          <div className="header-area">
            <div className="top-area">
              <div className="row align-items-center">
                <div className="col-8">
                  <h1 className="main-heading">Email Campaigns</h1>
                  <h6 className="main-subheading">Manage Your Email Campaigns: Scheduled, Sent, Draft, and Archived at Your Fingertips</h6>
                </div>
                <div className="col-4">
                  <button type="button" onClick={handleShow} className="primary-button float-end">Create Campaign</button>
                </div>
              </div>
            </div>
          </div>

		 
		  
          
          <div className="filter-area">
            <div className="row">
              <div className="col-3">
                <label>Triggered By</label>
                <div className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-envelope-at"></i>
				  <Select
						className="input-form"
						disableUnderline
						fullWidth
						value={triggeredBy}
						onChange={handleTriggeredByChange}
						input={<InputBase />}
					>
						<MenuItem value="All">All</MenuItem>
						{emails.map((email, index) => (
							<MenuItem key={index} value={email.email}>
								{email.email}
							</MenuItem>
						))}
					</Select>

                </div>
              </div>
              
              <div className="col-3">
                <label>Duration</label>
                <div className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-clock"></i>
                  <Select
						className="input-form"
						disableUnderline
						fullWidth
						value={duration}
						onChange={handleDurationChange}
						input={<InputBase />}
					>
						<MenuItem value="All">All Time</MenuItem>
						<MenuItem value="today">Today</MenuItem>
						<MenuItem value="yesterday">Yesterday</MenuItem>
						<MenuItem value="last7days">Last 7 Days</MenuItem>
						<MenuItem value="last30days">Last 30 Days</MenuItem>
						<MenuItem value="last6months">Last 6 Months</MenuItem>
						<MenuItem value="last12months">Last 12 Months</MenuItem>
					</Select>
                </div>
              </div>
              <div className="col-3">
                <label>Campaign Type</label>
                <div className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-tag"></i>
                  <Select
						className="input-form"
						disableUnderline
						fullWidth
						value={campaignType}
						onChange={handleCampaignTypeChange}
						input={<InputBase />}
					>
						<MenuItem value="All">All</MenuItem>
						<MenuItem value="Newsletter">Newsletter</MenuItem>
						<MenuItem value="Promotional">Promotional</MenuItem>
						<MenuItem value="Transactional">Transactional</MenuItem>
						<MenuItem value="Automated">Automated</MenuItem>
						<MenuItem value="Event">Event</MenuItem>
						<MenuItem value="Survey">Survey</MenuItem>
						<MenuItem value="Announcement">Announcement</MenuItem>
						<MenuItem value="Others">Others</MenuItem>
					</Select>
                </div>
              </div>
              
              <div className="col-3">
                <label>Sort</label>
                <div className="select-input-form">
                  {/* Bootstrap Icon */}
                  <i className="bi bi-filter"></i>
                  <Select
						className="input-form"
						disableUnderline
						fullWidth
						value={sortOrder}
						onChange={handleSortOrderChange}
						input={<InputBase />}
					>
						<MenuItem value="All">All</MenuItem>
						<MenuItem value="latest">Sent Date: Latest</MenuItem>
						<MenuItem value="oldest">Sent Date: Oldest</MenuItem>
					</Select>
                </div>
              </div>
          </div>
            </div>
          
           <div className="tab-area">
            <a href="#All" className="campaign-status-tab" onClick={() => handleTabClick('#All')}>
              <span className={activeTab === '#All' ? 'active' : ''}>
                All Campaigns <span className="number d-none">24</span>
              </span>
            </a>
            <a href="#Sent" className="campaign-status-tab" onClick={() => handleTabClick('#Sent')}>
              <span className={activeTab === '#Sent' ? 'active' : ''}>
                Sent <span className="number d-none">10</span>
              </span>
            </a>
            <a href="#Scheduled" className="campaign-status-tab" onClick={() => handleTabClick('#Scheduled')}>
              <span className={activeTab === '#Scheduled' ? 'active' : ''}>
                Scheduled <span className="number d-none">15</span>
              </span>
            </a>
            <a href="#Draft" className="campaign-status-tab" onClick={() => handleTabClick('#Draft')}>
              <span className={activeTab === '#Draft' ? 'active' : ''}>
                Draft <span className="number d-none">12</span>
              </span>
            </a>
            <a href="#Archived" className="campaign-status-tab" onClick={() => handleTabClick('#Archived')}>
              <span className={activeTab === '#Archived' ? 'active' : ''}>
                Archived <span className="number d-none">2</span>
              </span>
            </a>
          </div>
          
        </div>  
        
      </div>
      
      <div className="email-campaigns-main-area">
        <div className="row">
          <div className="col-8">
            <h2 className="secondary-heading d-none">24 Campaigns</h2>
          </div>
          <div className="col-4">
            
          </div>
        </div>  
        
		{noData ? (
									<center>
										<div className="empty-error-area">
											<img className="empty-error-image w-25 mb-4" src="../../iconss/other/no-results.png"  />
											<h2 className="empty-error-heading">No Email Templates Found</h2>
											<h6 className="empty-error-subheading" >We couldn't find any email templates associated with your account.</h6>
											<Link to="/email-templates/template-library" className="primary-button text-decoration-none mt-2 d-inline-block mx-2">Create new Template</Link>
										</div>
									</center>
								) : (
									<>
										<div className="row">
											{data.map((item, index) => {
											const formattedDate = new Intl.DateTimeFormat('en-US', {
												year: 'numeric',
												month: 'long',
												day: 'numeric',
												hour: 'numeric', // for hours
												minute: 'numeric', // for minutes
												hour12: true
											}).format(new Date(item.scheduledDateTime));
											
											

											return (
												<div key={item.campaignId} ref={data.length === index + 1 ? lastElementRef : null} className="col-12">
													
													<div className="list-area">
													  <div className="campaign-top-area">
														<div className="row align-items-center">
														  <div className="col-1">
														  <Link to={`/campaigns/email/${item.campaignId}`}>
															<a href="" className="campaign-tag-icon communication"><i className="bi bi-tag"></i></a>
														  </Link>
														  </div>
														  <div className="col-8">
														  <Link className="text-decoration-none" to={`/campaigns/email/${item.campaignId}`}>
															<h2 className="campaign-title">{item.campaignName}</h2>
															<h6 className="campaign-description">#{item.campaignId} · <b className="text-capitalize">{item.status}</b> on {formattedDate}</h6>
														  </Link>
														  </div>
														  <div className="col-2 text-end">
														  <Link className="text-decoration-none" to={`/campaigns/email/${item.campaignId}`}>
															<span className={`campaign-status ${item.status}-status text-capitalize`}><i className="bi bi-dot"></i> {item.status}</span>
														  </Link>
														  </div>
														  <div className="col-1 text-start">
															<div className="dropdown-container" ref={(el) => dropdownRefs.current[index] = el}>
															  <button className="dropdown-toggle" type="button" onClick={() => toggleDropdown(index)}>
																<i className="bi bi-three-dots"></i>
															  </button>
															  {showDropdown === index && (
																  <ul className="dropdown-menu show">
																	<li><a href="#" >Edit</a></li>
																	<li><a href="#" onClick={closeDropdown}>Duplicate</a></li>
																	<li><a href="#" onClick={closeDropdown}>View Report</a></li>
																	<div className="line-break"></div>
																	<li><a href="#" onClick={() => handleClickOpen(item.campaignId)} className="text-danger">Delete</a></li>
																  </ul>
																)}
															</div>
														  </div>
														</div>
													  </div>
													  
													  <div className="campaign-bottom-area">
													  <Link className="text-decoration-none" to={`/campaigns/email/${item.campaignId}`}>
														<div className="row">
														  
														  <div className="col-3">
															  <div className="campaign-metric-column border-0">
																<h2 className="metric-heading text-dark">
																  {item.delivered} <span>({item.delivered > 0 ? ((item.delivered / item.delivered) * 100).toFixed(1) : 0}%)</span>
																</h2>
																<h6 className="metric-subheading">Delivered</h6>
															  </div>
															</div>

															<div className="col-3">
															  <div className="campaign-metric-column">
																<h2 className="metric-heading text-primary">
																  {item.opened} <span>({item.delivered > 0 ? ((item.opened / item.delivered) * 100).toFixed(1) : 0}%)</span>
																</h2>
																<h6 className="metric-subheading">Opened</h6>
															  </div>
															</div>

															<div className="col-3">
															  <div className="campaign-metric-column">
																<h2 className="metric-heading text-success">
																  {item.clicked} <span>({item.delivered > 0 ? ((item.clicked / item.delivered) * 100).toFixed(1) : 0}%)</span>
																</h2>
																<h6 className="metric-subheading">Clicked</h6>
															  </div>
															</div>

															<div className="col-3">
															  <div className="campaign-metric-column">
																<h2 className="metric-heading text-danger">
																  {item.unsubscribed} <span>({item.delivered > 0 ? ((item.unsubscribed / item.delivered) * 100).toFixed(1) : 0}%)</span>
																</h2>
																<h6 className="metric-subheading">Unsubscribed</h6>
															  </div>
															</div>

														  
														  
														  
														</div>
														</Link>
													  </div>
													  
													</div>
												</div>
												
												);
											})}
											
										</div>
										{loading && <p>Loading more data...</p>}
									</>
								)}
		
        
        
        
      </div>
       {show && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100">
						<div className="row w-100">
						  <div className="col-11">
							<h5 className="modal-title">Create a new Campaign</h5>
						  </div>
						  <div className="col-1">
							  <a style={{textDecoration: 'none'}} className="close" onClick={handleClose}>
								<span style={{fontSize: '2vw', color: 'rgba(100, 100, 100)', cursor: 'pointer'}}>&times;</span>
							  </a>
						  </div>	  
						</div>  
					</div>
					<div className="modal-body py-4 px-4">
					<Link className="text-decoration-none" to="/create/email-campaign">
						<div style={{border: '0.1vw solid rgba(225, 225, 225)', cursor: 'pointer', margin: '0vw 0 1vw 0vw', borderRadius: '0.6vw', padding: '0.5vw 1vw'}} className="create-campaign-type-area">
							<div className="row align-items-center">
								<div className="col-2">	
									<img style={{width: '5vw', height: '5vw',borderRadius: '0.6vw', background: 'rgba(250, 250, 250)', padding: '1.25vw', float: 'center', display: 'block'}} src="https://app.aadow.com/imgs/email-marketing.png" className="create-campaign-type-icon" />
								</div>
								<div className="col-10">	
									<div style={{paddingLeft: '1vw', paddingTop: '1vw'}}>
									<h4 style={{fontSize: '1.2vw', fontWeight: '600', color: 'rgba(10, 10, 10)'}} className="create-campaign-type-heading">Create Regular Campaign</h4>
									<h6 style={{fontSize: '0.9vw', lineHeight:'1.5vw', fontWeight: '400', color: 'rgba(120, 120, 120)'}} className="create-campaign-type-description">Engage subscribers with news, promotions, and events to keep them interested and informed</h6>
									</div>
								</div>

							</div>
						</div>
					</Link>	
						<div style={{border: '0.1vw solid rgba(220, 220, 220)',cursor: 'pointer', margin: '2vw 0 0vw 0vw', borderRadius: '0.6vw', padding: '0.5vw 1vw'}} className="create-campaign-type-area">
							<div className="row align-items-center">
								<div className="col-2">	
									<img style={{width: '5vw', height: '5vw',borderRadius: '0.6vw', background: 'rgba(250, 250, 250)', padding: '1.25vw', float: 'center', display: 'block'}} src="https://app.aadow.com/imgs/ab-testing.png" className="create-campaign-type-icon" />
								</div>
								<div className="col-10">	
									<div style={{paddingLeft: '1vw', paddingTop: '1vw'}}>
									<h4 style={{fontSize: '1.2vw', fontWeight: '600'}} className="create-campaign-type-heading">Create AB Test Campaign</h4>
									<h6 style={{fontSize: '0.9vw', lineHeight:'1.5vw', fontWeight: '400', color: 'rgba(120, 120, 120)'}} className="create-campaign-type-description">Engage subscribers with news, promotions, and events to keep them interested and informed</h6>
									</div>
								</div>

							</div>
						</div>
					</div>
					
				  </div>
				</div>
			  </div>
			</>
		  )}
		  
		  <Dialog open={open} onClose={handleCloseDeleteModal}>
					<DialogTitle>Confirm Deletion</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete this campaign? This action cannot be undone.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseDeleteModal} className="text-secondary">
							Cancel
						</Button>
						<Button onClick={handleDelete} className="text-danger">
							Delete
						</Button>
					</DialogActions>
			</Dialog>
			
			<Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
				<MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
			</Snackbar>
		  
    </SideBar>
  );
};

export default EmailCampaigns;
