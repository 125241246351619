import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import '../../css/private/audience-overview.css';
import SideBar from './../components/SideBar';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';

const AudienceOverview = () => {
  const [showDropdown, setShowDropdown] = useState(null);
  const dropdownRefs = useRef([]);

  const toggleDropdown = (index) => {
    setShowDropdown(showDropdown === index ? null : index);
  };

  const closeDropdown = () => {
    setShowDropdown(null);
  };
  
  const [SenderEmailselectedValue, setSenderEmailselectedValue] = useState("");
  const [SegmentselectedValue, setSegmentselectedValue] = useState("");
  const [ListselectedValue, setListselectedValue] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [ExportModalshow, setExportModalshow] = useState(false);
  const handleExportModalClose = () => setExportModalshow(false);
  const handleExportModalShow = () => setExportModalshow(true);
  
  const handleSegmentChange = (event) => {
	  setSegmentselectedValue(event.target.value); // Update state with the selected value
	};

	const handleListChange = (event) => {
	  setListselectedValue(event.target.value); // Update state with the selected value
	};
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  const handleExportModalShowHide = () => {
	  setInputApiArea('true');
	  setExportModalshow(true);
  };
  
  const [InputApiArea, setInputApiArea] = useState('true');
  const [Name, setName] = useState('');
  const [NameError, setNameError] = useState(false);
  const [ErrorName, setErrorName] = useState('');
  const [EmailSentTo, setEmailSentTo] = useState('');
  const NameRef = useRef(null);
  
  const [Email, setEmail] = useState('');
  const [EmailError, setEmailError] = useState(false);
  const [ErrorEmail, setErrorEmail] = useState('');
  
  const [ErrorListName, setErrorListName] = useState('');
  
  const EmailRef = useRef(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  const handleExportList = () =>{
	  
		setErrorListName("");
	if (ListselectedValue.trim() === '') {
		setErrorListName("Please select a contact list");
		return;
	}
	setInputApiArea("false");
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  
  const [isOpen, setIsOpen] = useState(false);

  const toggleSideNav = () => {
    setIsOpen(!isOpen);
  };
  
  const [SearchContact, setSearchContact] = useState('');
  
  
  const handleSearchContactChange = (value) => {
  };	
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

	

  // Close dropdown when clicking outside of it
  useEffect(() => {
	  
    document.title = 'Audience Overview | Aadow';
    const handleClickOutside = (event) => {
      if (dropdownRefs.current.some(ref => ref && !ref.contains(event.target))) {
        setShowDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(null);
  const [listId, setListId] = useState('');
  const [status, setStatus] = useState('');
  const [segment, setSegment] = useState('');
  const [folderId, setFolderId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const pageSize = 8; // Number of items per page

  const fetchContacts = async (page) => {
    try {
      let url;

      if (searchTerm) {
        url = `http://localhost:8080/api/emailcontacts/search?username=pachorieshan&search=${searchTerm}&page=${page}`;
      } else {
        url = `http://localhost:8080/api/emailcontacts/username?username=pachorieshan&page=${page}`;
        
        // Build the filter URL if any filter is applied
        if (listId || status || segment || folderId) {
          url = `http://localhost:8080/api/emailcontacts/filters?username=pachorieshan&page=${page}`;
          const params = new URLSearchParams();

          if (listId) params.append('listid', listId);
          if (status) params.append('status', status);
          if (segment) params.append('segment', segment);
          if (folderId) params.append('folderid', folderId);

          url += '&' + params.toString();
        }
      }

      const response = await axios.get(url, {
        auth: {
          username: 'testuser',
          password: 'testpassword',
        },
      });

      setContacts(response.data.content);
      setTotalPages(response.data.totalPages);
      setError(null); // Clear any previous error
    } catch (err) {
      setError('Failed to fetch contacts. Please try again later.');
      setContacts([]);
      setTotalPages(0);
    }
  };

  useEffect(() => {
    fetchContacts(currentPage);
  }, [currentPage, listId, status, segment, folderId, searchTerm]);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      // If Enter is pressed, we trigger the fetch
      setCurrentPage(0); // Reset to first page when searching
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (!value) {
      setCurrentPage(0); // Reset to first page when search is cleared
    }
  };
  
  
  return (
    <SideBar>
      <div className="page-content ">
        <div className="audience-overview-body ">
        <div className="breadcrumb-area"> 
			 <nav aria-label="breadcrumb">
			  <ol className="breadcrumb">
				<li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
				<li className="breadcrumb-item active" aria-current="page">Audience Overview</li>
			  </ol>
			</nav>
		</div>	
		 <div className="header-area">
            <div className="top-area">
              <div className="row align-items-center">
                <div className="col-7">
                  <h1 className="main-heading">Audience Overview</h1>
                  <h6 className="main-subheading">Manage Your Contacts and Subscribers Efficiently</h6>
                </div>
                <div className="col-5">
                  <button type="button" onClick={handleShow} className="primary-button float-end mx-2 me-0">Import Contacts</button>
                  <button type="button" className="primary-outline-button float-end mx-2 d-none">Add a Contact</button>
                </div>
              </div>
            </div>
          </div>
		  
		  <div className="audience-metric-area d-none">
			<div className="row">
				<div className="col">
					<div className="audience-metric-col">
						<div className="row align-items-center">
							<div className="col-2">
								<i className="bi bi-people icon"></i>
							</div>
							<div className="col-10">
								<h3 className="secondary-heading">All Contacts</h3>
							</div>
						</div>
						<div className="row align-items-center mt-3">
							<div className="col-7">
								<h2 className="main-heading">30,492</h2>
							</div>
							<div className="col-5 text-end">
								<span className="metric-today-change-up"><i className="bi bi-graph-up-arrow me-1"></i> 7.4%</span>
							</div>
						</div>
					</div>
				</div>
				
				<div className="col">
					<div className="audience-metric-col">
						<div className="row align-items-center">
							<div className="col-2">
								<i className="bi bi-person-check icon"></i>
							</div>
							<div className="col-10">
								<h3 className="secondary-heading">Active</h3>
							</div>
						</div>
						<div className="row align-items-center mt-3">
							<div className="col-7">
								<h2 className="main-heading">28,313</h2>
							</div>
							<div className="col-5 text-end">
								<span className="metric-today-change-up"><i className="bi bi-graph-up-arrow me-1"></i> 23.59%</span>
							</div>
						</div>
					</div>
				</div>
				
				<div className="col">
					<div className="audience-metric-col">
						<div className="row align-items-center">
							<div className="col-2">
								<i className="bi bi-person-x icon"></i>
							</div>
							<div className="col-10">
								<h3 className="secondary-heading">Non-Active</h3>
							</div>
						</div>
						<div className="row align-items-center mt-3">
							<div className="col-7">
								<h2 className="main-heading">2,094</h2>
							</div>
							<div className="col-5 text-end">
								<span className="metric-today-change-down"><i className="bi bi-graph-down-arrow me-1"></i> -1.56%</span>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		  </div>
		  

		  {/* Overlay */}
		  <div className={`overlay ${isOpen ? 'open' : ''}`} onClick={toggleSideNav}></div>

		  {/* Side Navigation */}
		  <div id="mySidenav" className={`sidenav ${isOpen ? 'open' : ''}`}>
		  </div>
		  
		  <div className="audience-filter-area">
			  <div className="row align-items-center">
				<div className="col-5">
					  <input
						  type="text"
						  value={searchTerm}
						  onChange={handleSearchChange}
						  onKeyPress={handleSearchKeyPress}
						  autoComplete="off"
						  className="contact-search"
						  placeholder="Search Contacts"
						/>
				</div>
				<div className="col-7 text-end">
					<button type="button" className="secondary-button me-3 d-none"><i className="bi bi-funnel-fill me-2"></i> Filter</button>
					<button type="button" onClick={handleExportModalShowHide} className="primary-button d-none	"><i className="bi bi-download me-2"></i> Export Contacts</button>
				</div>
			  </div>
		  </div>
		  
		  
		  <div className="table-contact-area">
	{contacts.length === 0 && !error ? (
									<center>
										<div className="empty-error-area">
											<img className="empty-error-image w-25 mb-4" src="../../iconss/other/no-results.png"  />
											<h2 className="empty-error-heading">No contacts found.</h2>
											<h6 className="empty-error-subheading d-none" >We couldn't find any domain authentication records associated with your account.</h6>
										</div>
									</center>
      ) : (
	  <div>
	<table>
      <thead>
        <tr>
          <th className="th-one">Subscriber</th>
          <th className="th-two">Status</th>
          <th className="th-three">Listname</th>
          <th className="th-five">Segment</th>
          <th className="th-six"></th>
        </tr>
      </thead>
      <tbody>
		  {contacts.map(contact => (
          <tr key={contact.id}>
            <td className="td-one">
              <div className="row align-items-center">
                <div className="col-2">
                  <img src="https://www.aadow.com/icons/user-avatar.png" alt="Person" className="person-image" />
                </div>
                <div className="col-10">
                  <h6 className="person-name">{contact.firstname} {contact.lastname}</h6>
                  <h6 className="person-contact-info">{contact.email}</h6>
                </div>
              </div>
            </td>
            <td className="td-two"><span className={`person-status ${contact.status}-status text-capitalize`}>{contact.status}</span></td>
            <td className="td-three listname">{contact.listname}</td>
            <td className="td-five">
              <div className={`segment-container ${contact.segment}`}>
                <div className="first-segment"></div>
                <div className="second-segment"></div>
                <div className="third-segment"></div>
              </div>
            </td>
            <td className="td-six">
              <div className="dropdown-container" >
                <button className="dropdown-toggle" onClick={() => toggleDropdown(contact.id)} type="button" >
                  <i className="bi bi-three-dots-vertical"></i>
                </button>
				{showDropdown === contact.id && (
                  <ul className="dropdown-menu show">
                    <li><a href="#" className="d-none">Edit</a></li>
                    <li><a href="#" className="d-none" >Duplicate</a></li>
                    <li><a href="#" className="d-none" >View Report</a></li>
                    <div className="line-break d-none"></div>
                    <li><a href="#"  className="text-danger">Delete</a></li>
                  </ul>
				)} 
              </div>
            </td>
          </tr>
		  ))}
      </tbody>
    </table>
			
			<div className="pagination-area ">
				<div className="row">
					<div className="col-2">
						<button onClick={handlePrevPage} disabled={currentPage === 0} type="button"><i className="bi bi-arrow-left me-2" ></i> Previous</button>
						
					</div>
					<div className="col-8 text-center pagination-numbers">
					{Array.from({ length: totalPages }, (_, index) => (
							<button
							  key={index}
							  onClick={() => handlePageClick(index)}
							  disabled={index === currentPage}
							  type="button"
							  className={`{index === currentPage ? 'active' : ''}`}
							>
							  {index + 1}
							</button>
					))}	
					</div>
					<div className="col-2 text-end"> 
						<button type="button" onClick={handleNextPage} disabled={currentPage === totalPages - 1}> Next <i className="bi bi-arrow-right ms-2"></i></button>
					</div>
				</div>
			</div>
			</div>
			)}
			
		  </div>
		  
          
        </div>
      </div>
	  
	   {show && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100">
						<div className="row w-100">
						  <div className="col-11">
							<h5 className="modal-title">Import Contacts</h5>
						  </div>
						  <div className="col-1">
							  <a style={{textDecoration: 'none'}} className="close" onClick={handleClose}>
								<span style={{fontSize: '2vw', color: 'rgba(100, 100, 100)', cursor: 'pointer'}}>&times;</span>
							  </a>
						  </div>	  
						</div>  
					</div>
					<div className="modal-body py-4 px-4">
						<Link className="text-decoration-none d-none" to="/import-contacts/copy-paste">
						<div style={{border: '0.1vw solid rgba(225, 225, 225)', cursor: 'pointer', margin: '0vw 0 1vw 0vw', borderRadius: '0.6vw', padding: '1vw 1vw'}} className="create-campaign-type-area">
							<div className="row align-items-center">
								<div className="col-2">	
									<img style={{width: '5vw', height: '5vw',borderRadius: '0.6vw', background: 'rgba(250, 250, 250)', padding: '1.25vw', float: 'center', display: 'block'}} src="https://app.aadow.com/imgs/folder.png" className="create-campaign-type-icon" />
								</div>
								<div className="col-10">	
									<div style={{paddingLeft: '1vw', paddingTop: '1vw'}}>
									<h4 style={{fontSize: '1.2vw', fontWeight: '600'}} className="create-campaign-type-heading text-dark">Bulk List Upload</h4>
									<h6 style={{fontSize: '0.9vw', lineHeight:'1.5vw', fontWeight: '400', color: 'rgba(120, 120, 120)'}} className="create-campaign-type-description">Upload folder with multiple .csv files</h6>
									</div>
								</div>

							</div>
						</div>
						</Link>
						<Link className="text-decoration-none" to="/import-contacts/csv-upload">
						<div style={{border: '0.1vw solid rgba(220, 220, 220)',cursor: 'pointer', margin: '0vw 0 0vw 0vw', borderRadius: '0.6vw', padding: '1vw 1vw'}} className="create-campaign-type-area">
							<div className="row align-items-center">
								<div className="col-2">	
									<img style={{width: '5vw', height: '5vw',borderRadius: '0.6vw', background: 'rgba(250, 250, 250)', padding: '1.25vw', float: 'center', display: 'block'}} src="https://app.aadow.com/imgs/upload-emails.png" className="create-campaign-type-icon" />
								</div>
								<div className="col-10">	
									<div style={{paddingLeft: '1vw', paddingTop: '1vw'}}>
									<h4 style={{fontSize: '1.2vw', fontWeight: '600'}} className="create-campaign-type-heading text-dark">Upload a file</h4>
									<h6 style={{fontSize: '0.9vw', lineHeight:'1.5vw', fontWeight: '400', color: 'rgba(120, 120, 120)'}} className="create-campaign-type-description">Select a .csv from your computer</h6>
									</div>
								</div>

							</div>
						</div>
						</Link>
						<Link className="text-decoration-none" to="/import-contacts/copy-paste">
						<div style={{border: '0.1vw solid rgba(220, 220, 220)',cursor: 'pointer', margin: '1vw 0 0vw 0vw', borderRadius: '0.6vw', padding: '1vw 1vw'}} className="create-campaign-type-area">
							<div className="row align-items-center">
								<div className="col-2">	
									<img style={{width: '5vw', height: '5vw',borderRadius: '0.6vw', background: 'rgba(250, 250, 250)', padding: '1.25vw', float: 'center', display: 'block'}} src="https://app.aadow.com/imgs/copy-paste.png" className="create-campaign-type-icon" />
								</div>
								<div className="col-10">	
									<div style={{paddingLeft: '1vw', paddingTop: '1vw'}}>
									<h4 style={{fontSize: '1.2vw', fontWeight: '600'}} className="create-campaign-type-heading text-dark">Copy & Paste</h4>
									<h6 style={{fontSize: '0.9vw', lineHeight:'1.5vw', fontWeight: '400', color: 'rgba(120, 120, 120)'}} className="create-campaign-type-description">Copy and Paste contacts from your computer</h6>
									</div>
								</div>

							</div>
						</div>
						</Link>
					</div>
					
				  </div>
				</div>
			  </div>
			</>
		  )}
		  
		  {ExportModalshow && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100">
						<div className="row w-100">
						  <div className="col-11">
							<h5 className="modal-title">Export Contact</h5>
						  </div>
						  <div className="col-1">
							  <a style={{textDecoration: 'none'}} className="close" onClick={handleExportModalClose}>
								<span style={{fontSize: '2vw', color: 'rgba(100, 100, 100)', cursor: 'pointer'}}>&times;</span>
							  </a>
						  </div>	  
						</div>  
					</div>
					<div className="modal-body pb-4 pt-0 px-4">
					{InputApiArea === 'true' && (
					<div className="row">
						<div className="col-12">
							<label>Sender Name<b className="text-danger">*</b></label>
											<Select
												className="input-form"
												disableUnderline
												fullWidth
												value={ListselectedValue} // Set the value from state
												onChange={handleListChange} // Handle the onChange event
												input={<InputBase />}
											  >
												<MenuItem value=""></MenuItem>
												<MenuItem value="eshanpachori@aadow.com">
												  All Contacts
												</MenuItem>
												<MenuItem value="ashwinpachori@aadow.com">
													List 1
												</MenuItem>
												<MenuItem value="contact@aadow.com">
													List 2
												</MenuItem>
											  </Select>
							{ErrorListName && <div className="error-message">{ErrorListName}</div>}
						</div>
						
						<label className="d-block mt-3">Segment<b className="text-danger">*</b></label>
												<Select
												className="input-form"
												disableUnderline
												fullWidth
												value={SegmentselectedValue} // Set the value from state
												onChange={handleSegmentChange} // Handle the onChange event
												input={<InputBase />}
											  >
												<MenuItem value=""></MenuItem>
												<MenuItem value="eshanpachori@aadow.com">
												  <i className="bi bi-circle-fill text-success me-2"></i> Send Only to Engaged
												</MenuItem>
												<MenuItem value="ashwinpachori@aadow.com">
												  <i className="bi bi-circle-fill text-warning me-2"></i> Send Only to Prospective
												</MenuItem>
												<MenuItem value="contact@aadow.com">
												  <i className="bi bi-circle-fill text-danger me-2"></i> Send Only to Un-Engaged
												</MenuItem>
											  </Select>
					</div>	
					)}
					{InputApiArea === 'false' && (	
						<div className="col-12">
							<div id="step_three" className="right-content">
							  <img src="iconss/other/approved.png" className="success-icon" alt="Success" />
							  <h2 className="secondary-heading-modal text-center">Contacts Exporting!</h2>
							  <h2 className="secondary-description-modal text-center">Your contacts are being downloaded</h2>
							  <center><button type="button" className="primary-button float-center" onClick={handleExportModalClose} >Okay</button></center>
							</div>
						</div>
					)}
						
					</div>
					
					{InputApiArea === 'true' && (
					<div className="modal-footer ">
						<button type="button" className="secondary-button" onClick={handleExportModalClose}>Cancel</button>
						<button type="button" className="primary-button" onClick={handleExportList}>Add Sender</button>
					</div>
					)}
					
				  </div>
				</div>
			  </div>
			</>
		  )}
		  
	  
    </SideBar>
  );
};

export default AudienceOverview;
