import React from 'react';

import '../../css/public/CustomSelect.css';
import { Select, MenuItem, Box, Typography } from '@mui/material';

const CountrySelect = ({ countryselectedOption, countryhandleChange }) => {
  const countryoptions = [
    { value: 'India', label: 'India', imgSrc: 'iconss/flags/india.png' },
    { value: 'Afghanistan', label: 'Afghanistan', imgSrc: 'iconss/flags/afghanistan.png' },
    { value: 'Albania', label: 'Albania', imgSrc: 'iconss/flags/albania.png' },
    { value: 'Algeria', label: 'Algeria', imgSrc: 'iconss/flags/algeria.png' },
    { value: 'Andorra', label: 'Andorra', imgSrc: 'iconss/flags/andorra.png' },
    { value: 'Angola', label: 'Angola', imgSrc: 'iconss/flags/angola.png' },
    { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda', imgSrc: 'iconss/flags/antigua-and-barbuda.png' },
    { value: 'Argentina', label: 'Argentina', imgSrc: 'iconss/flags/argentina.png' },
    { value: 'Armenia', label: 'Armenia', imgSrc: 'iconss/flags/armenia.png' },
    { value: 'Australia', label: 'Australia', imgSrc: 'iconss/flags/australia.png' },
    { value: 'United States', label: 'United States', imgSrc: 'iconss/flags/united-states.png' },
    { value: 'United Kingdom', label: 'United States', imgSrc: 'iconss/flags/united-kingdom.png' },
    { value: 'Europe', label: 'Europe', imgSrc: 'iconss/flags/europe.png' },
  ];

  return (
	
	<Select
					  labelId="country-select-label"
					  id="country-select"
					  className="country-select  w-100" 
					  value={countryselectedOption} // Use value from props
					  onChange={(event) => countryhandleChange(event.target.value)}
									  >
						{countryoptions.map((option) => (
						<MenuItem key={option.value} value={option.value}>
						  <Box className="custom-typo" display="flex" alignItems="center">
							<img className="custom-select-icon" src={option.imgSrc} alt={option.label}  />
							<Typography className="custom-select-typography">{option.label}</Typography>
						  </Box>
						</MenuItem>
						))}
				  </Select>
	
	
  );
};

export default CountrySelect;
