import React, { useState, useEffect, useRef } from 'react';
import '../../css/private/create-email-campaign.css';
import SideBar from './../components/SideBar';
import { Select, MenuItem, InputBase } from '@mui/material';

const EmailApi = () => {
   
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    const [isSidenavOpen, setIsSidenavOpen] = useState(false);

    // Function to handle schedule click
    const handleSchedule = () => {
        setIsSidenavOpen(true); // Open the sidenav
    };

    // Function to handle overlay click
    const handleOverlayClick = () => {
        setIsSidenavOpen(false); // Close the sidenav
    };
	
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedListNames, setSelectedListNames] = useState("");
  const [contactCounts, setContactCounts] = useState("");
  const [totalContacts, setTotalContacts] = useState(0); // New state for the total sum
  const formatNumberWithCommas = (number) => {
	  return new Intl.NumberFormat().format(number);
	};

  // List data (mimics what you want in the MenuItems)
  const menuItems = [
    { label: "NewSubscribers", value: "eshanpachori@aadow.com", count: 582 },
    { label: "ActiveUsers", value: "ashwinpachori@aadow.com", count: 312 },
    { label: "AbandonedCart", value: "contact@aadow.com", count: 9427 },
  ];

  // Function to handle when a new item is selected
  const handleSelectChange = (event) => {
	  setErrorSelectList("");
    const selectedValue = event.target.value;
    const selectedItem = menuItems.find(item => item.value === selectedValue);

    // Check if the selected item already exists
    if (selectedItem && !selectedItems.some(item => item.value === selectedItem.value)) {
      const updatedItems = [...selectedItems, selectedItem];
      setSelectedItems(updatedItems);

      // Update the input values (list names and contact counts)
      const listNames = updatedItems.map(item => item.label).join(", ");
      const counts = updatedItems.map(item => item.count).join(", ");
      const total = updatedItems.reduce((acc, item) => acc + item.count, 0); // Calculate the total

      setSelectedListNames(listNames);
      setContactCounts(counts);
      setTotalContacts(total); // Update the totalContacts state
    }
  };

  // Function to remove a selected item
  const removeSelectedItem = (itemToRemove) => {
	  setErrorSelectList("");
    const updatedItems = selectedItems.filter(item => item !== itemToRemove);
    setSelectedItems(updatedItems);

    // Update the input values (list names and contact counts)
    const listNames = updatedItems.map(item => item.label).join(", ");
    const counts = updatedItems.map(item => item.count).join(", ");
    const total = updatedItems.reduce((acc, item) => acc + item.count, 0); // Recalculate the total

    setSelectedListNames(listNames);
    setContactCounts(counts);
    setTotalContacts(total); // Update the totalContacts state
  };
  
  const [SenderEmailselectedValue, setSenderEmailselectedValue] = useState("");
  const [SegmentselectedValue, setSegmentselectedValue] = useState("");
  
  
  // Function to handle the change event
  const handleSenderEmailSelectChange = (event) => {
    setSenderEmailselectedValue(event.target.value); // Update the state with the selected value
    console.log(event.target.value); // You can also log the selected value to verify
	setGetSenderEmail(SenderEmailselectedValue);
	
  };
  
  // Function to handle the change event
  const handleSegmentChange = (event) => {
    alert("googn");
  };
  
  
  const [totalCredits, settotalCredits] = useState('50000');
  const [SenderName, setSenderName] = useState('');
  const [GetSenderEmail, setGetSenderEmail] = useState('Sender Name');
  const [GetSenderName, setGetSenderName] = useState('Sender Name');
  const [GetSubjectLine, setGetSubjectLine] = useState('Subject Line');
  const [GetPreviewText, setGetPreviewText] = useState('Preview Text');
  const [TemplateName, setTemplateName] = useState('Untitled Template');
  const [ReplyTo, setReplyTo] = useState('');
  const [Subject, setSubject] = useState('');
  const [Preview, setPreview] = useState('');
  const [Template, setTemplate] = useState('demo');
  const [SenderNameError, setSenderNameError] = useState(false);
  const [ReplyToError, setReplyToError] = useState(false);
  const [SubjectError, setSubjectError] = useState(false);
  const [PreviewError, setPreviewError] = useState(false);
  const [TemplateError, setTemplateError] = useState(false);
  const SenderNameRef = useRef(null);
  const ReplyToRef = useRef(null);
  const SubjectRef = useRef(null);
  const PreviewRef = useRef(null);
  const TemplateRef = useRef(null);
  const TemplateNameRef = useRef(null);
  const [ErrorSenderName, setErrorSenderName] = useState('');
  const [ErrorSelectList, setErrorSelectList] = useState('');
  const [ErrorSenderEmail, setErrorSenderEmail] = useState('');
  const [ErrorSubject, setErrorSubject] = useState('');
  const [ErrorPreview, setErrorPreview] = useState('');
  const [ErrorTemplate, setErrorTemplate] = useState('');
  
  const [currentStep, setCurrentStep] = useState(0); // Step state
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  
  const [showContent, setShowContent] = useState(false);
  const [showContentTwo, setShowContentTwo] = useState(false);

  // Function to handle click and toggle the content visibility
  const toggleContent = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowContent((prevState) => !prevState); // Toggle the state
  };
  const toggleContentTwo = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowContentTwo((prevState) => !prevState); // Toggle the state
  };


	  useEffect(() => {
		document.title = 'New Email Campaign | Aadow';
	  }, []);
  
	  setInterval(() => {
		if (SenderName !== "") {
		setGetSenderName(SenderName);
		}
		if (Subject !== "") {
	    setGetSubjectLine(Subject);
		}
		if (Preview !== "") {
		setGetPreviewText(Preview);
		}
		if (SenderEmailselectedValue !== "") {
		setGetSenderEmail(SenderEmailselectedValue);
		}
	  }, 30000);
  
  const handleSenderNameChange = () => {
	  
  }
     
  const handleSubjectChange = () => {
  }
  
  const handlePreviewChange = () => {
	  setGetPreviewText(Preview);
  }
  
  const handleNext = () => {

    if (currentStep === 0) {
			
			setErrorSenderEmail("");
			setErrorSenderName("");
			setSenderNameError(false);
		if (SenderEmailselectedValue.trim() === '') {
			setErrorSenderEmail("Please select sender email");
			return;
		}else if (SenderName.trim() === '') {
			setErrorSenderName("Please enter a sender name");
			setSenderNameError(true);
			SenderNameRef.current.focus();
			return;
		}
    }
	
	if (currentStep === 1) {
		
			setErrorSelectList("");
		if (selectedListNames.trim() === '') {
			setErrorSelectList("Please select aleast 1 contact list");
			return;
		}
    }
	
	if (currentStep === 2) {
			setErrorSubject("");
			setSubjectError(false);
			setPreviewError("");
			setErrorTemplate("");
			setTemplateError(false);
		if (Subject.trim() === '') {
			setErrorSubject("Please enter a subject line");
			setSubjectError(true);
			SubjectRef.current.focus();
			return;
		}else if (Template.trim() === '') {
			setErrorTemplate("Please select a template");
			setTemplateError(true);
			TemplateRef.current.focus();
			return;
		}
    }
	
	
	
    if (currentStep < 3) setCurrentStep(currentStep + 1);
  };
  
  
   const calculatePercentageUsed = (contacts, credits) => {
    if (credits === 0) return 0; // Prevent division by zero
    return ((contacts / credits) * 100).toFixed(2); // Return percentage formatted to 2 decimal places
  };

  const percentageUsed = calculatePercentageUsed(totalContacts, totalCredits);
  

  const handlePrev = () => {
    if (currentStep > 0) setCurrentStep(currentStep - 1); // Go to the previous step
  };
  
  
  const goToStep = (stepNumber) => {
    setCurrentStep(stepNumber); // Directly jump to the step number
  };

  return (
    <SideBar>
      <div className="page-content">
        <div className="create-campaign-body">
			
			<input type="text" ref={TemplateNameRef} className="campaign-name-input w-75" value={TemplateName} onChange={(e) => {
												  setTemplateName(e.target.value); // Call a custom handler with the value
												}} placeHolder="" />
			
	  
			<div className="top-bar-navigation">
		  
			  <div id="step_one" className={`steps ${currentStep === 0 ? 'steps_active' : ''}`}>
			  <h5>
			  <i className={`bi ${currentStep === 0 ? 'bi-1-circle-fill' : 'bi-1-circle'}`}></i>
			  Sender
			  
			  <i className="bi bi-dash-lg"></i>
			  </h5>
			  </div>
			  
			  <div  id="step_two" className={`steps ${currentStep === 1 ? 'steps_active' : ''}`}>
			  <h5>
			  <i className={`bi ${currentStep === 1 ? 'bi-2-circle-fill' : 'bi-2-circle'}`}></i>
			  Recipients
			  
			  <i className="bi bi-dash-lg"></i>
			  </h5>
			  </div>
			  
			  
			  <div  id="step_three" className={`steps ${currentStep === 2 ? 'steps_active' : ''}`}>
			  <h5>
			  <i className={`bi ${currentStep === 2 ? 'bi-3-circle-fill' : 'bi-3-circle'}`}></i>
			  Email Content
			  
			  <i className="bi bi-dash-lg"></i>
			  </h5>
			  </div>
			  
			  
			  <div  id="step_four" className={`steps ${currentStep === 3 ? 'steps_active' : ''}`}>
			  <h5>
			  <i className={`bi ${currentStep === 3 ? 'bi-4-circle-fill' : 'bi-4-circle'}`}></i>
			  Review & Schedule
			  </h5>
			  </div>
			  </div>
			  
			  
			  <div className="campaign-area">
				<div className="row">
					<div className="col-8">
						<div className="left-side">
						
							{currentStep === 0 && (
							<div className="step-one">
								<div className="secondary-heading-area">
										<h4 className="secondary-heading">Sender Infomration</h4>
											  <h6 className="secondary-subheading">Who is sending this email campaign?</h6>
								</div>
								
								<div className="form-area">
									<div className="row align-items-center">
										<div className="col-6">
											  <h4 className="secondary-heading">Email address <b className="text-danger">*</b></h4>
											  <h6 className="secondary-subheading">Choose the email address that appears in the recipient's inbox when they receive your campaign</h6>
										</div>
										<div className="col-6">
												 <Select
												  className="input-form"
												  sx={{ fontSize: '4vw' }}
												  disableUnderline
												  fullWidth
												  value={SenderEmailselectedValue} // Set the value from state
												  onChange={handleSenderEmailSelectChange} // Handle the onChange event
												  input={<InputBase />}
												>
												  <MenuItem value=""></MenuItem>
												  <MenuItem value="eshanpachori@aadow.com">eshupachori777@gmail.com</MenuItem>
												  <MenuItem value="ashwinpachori@aadow.com">pachorieshan@gmail.com</MenuItem>
												  <MenuItem value="contact@aadow.com">ashwinpachori@aadow.com</MenuItem>
												</Select>
												{ErrorSenderEmail && <div className="error-message">{ErrorSenderEmail}</div>}
										</div>
									</div>
								</div>
								
								
								<div className="form-area">
									<div className="row align-items-center">
										<div className="col-6">
											  <h4 className="secondary-heading">Name <b className="text-danger">*</b></h4>
											  <h6 className="secondary-subheading">Enter a name (e.g. Your company name) to help recipients recognize you in the inbox.</h6>
										</div>
										<div className="col-6">
											 <input type="text" ref={SenderNameRef} className={`input-form w-100 ${SenderNameError ? 'input-error' : ''}`} value={SenderName} onChange={(e) => {
												  setSenderName(e.target.value);
												  handleSenderNameChange(e.target.value);// Call a custom handler with the value
												}}  />
											{ErrorSenderName && <div className="error-message">{ErrorSenderName}</div>}
										</div>
									</div>
									<a href="#" className="link-button" onClick={toggleContent}>Advanced Options</a>
								</div>
								
								{showContent && (
								<div className="toggled-content">
									<div className="form-area">
										<div className="row align-items-center">
											<div className="col-6">
												  <h4 className="secondary-heading">Reply to</h4>
												  <h6 className="secondary-subheading">Any specific email address you would like to get reply on.</h6>
											</div>
											<div className="col-6">
													<input type="text" ref={ReplyToRef} className={`input-form w-100 ${ReplyToError ? 'input-error' : ''}`} value={ReplyTo} onChange={(e) => {
												  setReplyTo(e.target.value); // Call a custom handler with the value
												}} placeHolder="[DEFAULT_REPLY_TO]" />
											</div>
										</div>
									</div>
									
									<div className="form-area">
										<div className="row align-items-center">
											<div className="col-6">
												  <h4 className="secondary-heading">Attachments</h4>
												  <h6 className="secondary-subheading">Add attachments to boost your campaign performance</h6>
											</div>
											<div className="col-6">
													<button type="button" className="attach-files-button"><i className="bi bi-paperclip"></i> Attach Files</button>
											</div>
										</div>
									</div>
								</div>
								)}
								

								
								
								<div className="form-area">
									<div className="row align-items-center">
										<div className="col-4">
										</div>
										<div className="col-8 text-end">
											
											<button type="button" onClick={handleNext} className="primary-button ms-2">Next</button>
										</div>
									</div>
								</div>
							</div>
							
							)}
							
							
							{currentStep === 1 && (
							<div className="step-two">
								<div className="secondary-heading-area">
										<h4 className="secondary-heading">Recipients</h4>
											  <h6 className="secondary-subheading">Select recipients</h6>
								</div>
								
								<div className="form-area">
									<div className="row align-items-center">
										<div className="col-6">
											  <h4 className="secondary-heading">Send to <b className="text-danger">*</b></h4>
											  <h6 className="secondary-subheading">Select one or more email lists to send your campaign</h6>
										</div>
										 <div className="col-6">
										  {/* Select box */}
										  <Select
											className="input-form"
											sx={{ fontSize: '4vw' }}
											disableUnderline
											fullWidth
											value=""
											onChange={handleSelectChange}
											input={<InputBase />}
										  >
											<MenuItem value=""></MenuItem>
											{menuItems.map((item) => (
											  <MenuItem key={item.value} value={item.value}>
												{item.label} ({item.count})
											  </MenuItem>
											))}
										  </Select>
										  
										  {ErrorSelectList && <div className="error-message">{ErrorSelectList}</div>}
										  {/* Display selected items */}
										  <div className="selected-lists">
											{selectedItems.map((item) => (
											  <span key={item.value}>
												{item.label} ({item.count}){" "}
												<i className="bi bi-x" onClick={() => removeSelectedItem(item)}></i>
											  </span>
											))}
										  </div>

										  {/* Inputs for selected list names and contact counts */}
										  <input
											type="text"
											placeholder="List names"
											className="input-form d-none"
											value={selectedListNames}
											readOnly
										  />
										  <input
											type="text"
											placeholder="Contact counts"
											className="input-form d-none"
											value={contactCounts}
											readOnly
										  />
										  
										  
										  {/* Advanced options link */}
										  <a href="#" className="link-button" onClick={toggleContentTwo}>
											Advanced Options
										  </a>
										</div>
									</div>
								</div>
								
								{showContentTwo && (
								<div className="toggled-content">
									<div className="form-area">
										<div className="row align-items-center">
											<div className="col-6">
												  <h4 className="secondary-heading">Audience Segmentaion</h4>
												  <h6 className="secondary-subheading">Any specific email address you would like to get reply on.</h6>
											</div>
											<div className="col-6">
												<Select
													className="input-form"
													sx={{ fontSize: '4vw' }}
													disableUnderline
													fullWidth
													defaultValue=""
													value={SegmentselectedValue} // Set the value from state
												    onChange={handleSegmentChange} // Handle the onChange event
												    input={<InputBase />}
												  >
													<MenuItem value=""></MenuItem>
													<MenuItem value="eshanpachori@aadow.com"><i className="bi bi-circle-fill text-success me-2"></i> Send Only to Engaged</MenuItem>
													<MenuItem value="ashwinpachori@aadow.com"><i className="bi bi-circle-fill text-warning me-2"></i> Send Only to Prospective</MenuItem>
													<MenuItem value="contact@aadow.com"><i className="bi bi-circle-fill text-danger me-2"></i> Send Only to Un-Engaged</MenuItem>
												  </Select>
												  
												  
											</div>
										</div>
									</div>
									
									
								</div>
								)}
								
								<div className="form-area">
									<div className="row align-items-center">
										<div className="col-lg-6"><h4 className="recipient-meter-left-heading"><span id="total_data_rel">{formatNumberWithCommas(totalContacts)} </span> recipients</h4></div>
										<div className="col-lg-6"><h4 className="recipient-meter-right-subheading"><span id="plan-credits">{formatNumberWithCommas(totalCredits)}</span> remaining emails</h4></div>
										
										<div className="col-lg-12">
										<div className="recipient-email-tracker">
										
										<div style={{ 
										  width: `${percentageUsed}%`, // Correctly formatted width
										  backgroundColor: 'rgba(2, 96, 105, 1)' // Include alpha value for opacity
										}}  id="total_email_progress_bar" className="recipient-email-tracker-fill"></div>
										
										</div>
										<p className="recipient-email-label">Select multiple lists and send them to as many recipients as you wish, within your plan limits</p>
										</div>
									</div>
								</div>
								
								

								
								
								<div className="form-area">
									<div className="row align-items-center">
										<div className="col-4">
										</div>
										<div className="col-8 text-end">
											
											<button type="button" onClick={handlePrev} className="secondary-button me-2">Prev</button>
											<button type="button" onClick={handleNext} className="primary-button ms-2">Next</button>
										</div>
									</div>
								</div>
							</div>
							)}
							
							{currentStep === 2 && (
							<div className="step-three">
								<div className="secondary-heading-area">
										<h4 className="secondary-heading">Content</h4>
											  <h6 className="secondary-subheading">Add a subject & template for this campaign.</h6>
								</div>
								
								<div className="form-area">
									<div className="row align-items-center">
										<div className="col-6">
											  <h4 className="secondary-heading">Subject <b className="text-danger">*</b></h4>
											  <h6 className="secondary-subheading">Brief summary, aids recipient understanding and action.</h6>
										</div>
										<div className="col-6">
												<input type="text" ref={SubjectRef} className={`input-form w-100 ${SubjectError ? 'input-error' : ''}`} value={Subject} onChange={(e) => {
												  setSubject(e.target.value); // Call a custom handler with the value
												  handleSubjectChange(e.target.value);
												}} placeHolder="" />
												
										  {ErrorSubject && <div className="error-message">{ErrorSubject}</div>}
										</div>
									</div>
								</div>
								
								
								<div className="form-area">
									<div className="row align-items-center">
										<div className="col-6">
											  <h4 className="secondary-heading">Preview <b className="text-danger">*</b></h4>
											  <h6 className="secondary-subheading">Snippet appearing with email subject line in inbox preview, giving a glimpse of email content.</h6>
										</div>
										<div className="col-6">
												<input type="text" ref={PreviewRef} className={`input-form w-100 ${PreviewError ? 'input-error' : ''}`} value={Preview} onChange={(e) => {
												  setPreview(e.target.value);
												  handlePreviewChange(e.target.value);// Call a custom handler with the value
												}} placeHolder="" />
												
										  {ErrorPreview && <div className="error-message">{ErrorPreview}</div>}
										</div>
									</div>
								</div>
								
								
								<input type="text" ref={TemplateRef} className={`input-form d-none w-100 ${TemplateError ? 'input-error' : ''}`} value={Template} onChange={(e) => {
												  setTemplate(e.target.value); // Call a custom handler with the value
												}} placeHolder="" />
								<div className="form-area">
									<div className="row align-items-center">
										<div className="col-12">
											  <h4 className="secondary-heading">Email Template <b className="text-danger">*</b></h4>
											  <div id="email_preview_area" className="email-preview-area text-center">
												<img src="https://app.aadow.com/images/placeholder-image.png" id="email_preview" className="email-preview" />
												<h6 className="text-center">Click here to select an email template</h6>
											  </div>
											  
										  {ErrorTemplate && <div className="error-message">{ErrorTemplate}</div>}
										</div>
									</div>
								</div>
								
								
								
								

								
								
								<div className="form-area">
									<div className="row align-items-center">
										<div className="col-4">
										</div>
										<div className="col-8 text-end">
											
											<button type="button" onClick={handlePrev} className="secondary-button me-2">Prev</button>
											<button type="button" onClick={handleNext} className="primary-button ms-2">Next</button>
										</div>
									</div>
								</div>
							</div>
							
							)}
							
							{currentStep === 3 && (
							
							<div className="step-four ">
								<div className="secondary-heading-area">
										<h4 className="secondary-heading">Review & Schedule</h4>
									    <h6 className="secondary-subheading">Review campaign details and schedule it using your preferred method and time</h6>
								</div>
								
								
								
								
								
								<div className="form-area p-0">
								
									<div className="email-review-blocks">
										<div className="container-fluid">
									<div className="row">
										<div className="col-1 text-center icon-area">
											<i id="step_one_filled_icon" className="bi bi-check-circle-fill"></i>
											<i id="step_one_empty_icon" className="bi bi-exclamation-circle-fill d-none"></i>
										</div>
										
										<div className="col-9 center-area">
										<h3>Sender</h3>
										<h6><b id="final_sent_from_name">{GetSenderName}</b><span> &#183;  </span> <span id="final_sent_from_email">{GetSenderEmail}</span></h6>
										</div>
										
										<div className="col-2 end-area text-center">
											<button id="edit_page_one" onClick={() => goToStep(0)} type="button">Edit</button>
										</div>
										
									</div>
									</div>
									</div>
									
									
									<div className="email-review-blocks">
										<div className="container-fluid">
										<div className="row ">
											<div className="col-1 text-center icon-area">
												
												<i id="step_two_filled_icon" className="bi bi-check-circle-fill"></i>
												<i id="step_two_empty_icon" className="bi bi-exclamation-circle-fill d-none"></i>
											</div>
											
											<div className="col-9 center-area">
											<h3>Recipients</h3>
											<h6><b id="final_total_recipients">{formatNumberWithCommas(totalContacts)} recipients</b><span> &#183;  </span> {formatNumberWithCommas(totalCredits)} remaining emails</h6>
											</div>
											
											<div className="col-2 end-area text-center">
											<button id="edit_page_two" onClick={() => goToStep(1)} type="button">Edit</button>
											</div>
											
										</div>
										</div>
									</div>
									
									
									<div className="email-review-blocks">
										<div className="container-fluid">
										<div className="row ">
											<div className="col-1 text-center icon-area">
												<i id="step_three_filled_icon" className="bi bi-check-circle-fill"></i>
												<i id="step_three_empty_icon" className="bi bi-exclamation-circle-fill d-none"></i>
											</div>
											
											<div className="col-9 center-area">
											<h3>Email Content</h3>
											<h6><b>Subject: </b><span id="final_subject">{GetSubjectLine}</span></h6>
											<h6><b>Preview: </b><span id="final_preview_text">{GetPreviewText}</span></h6>
											</div>
											
											<div className="col-2 end-area text-center">
											
											<button id="edit_page_three" onClick={() => goToStep(2)} type="button">Edit</button>
											</div>
											
										</div>
										<div className="email-preview-area">
										  <center><img id="final_email_template" src="https://app.aadow.com/images/placeholder-image.png" className="email-preview" /></center>
										</div>
									</div>
									
									
									
									</div>
									
									<h6 id="step_four_error" className="error mt-3">Please fill all required information</h6>
									
									
								</div>
								
								
								
								

								
								
								<div className="form-area">
									<div className="row align-items-center">
										<div className="col-4">
										</div>
										<div className="col-8 text-end">
											
											<button type="button" onClick={handlePrev} className="secondary-button me-2">Prev</button>
											<button type="button" onClick={handleSchedule} className="primary-button ms-2">Schedule</button>
										</div>
									</div>
								</div>
							</div>
							
							)}
							
							
							
							
						</div>
					</div>
					
					<div className="col-4">
						<div className="">
							<div className="preview-area">
							<div className="header">Preview</div>
							<div className="content">
							<div className="preview-device-area">
								<div className='mobile-phone'>
								<center><div className='brove'><span className='speaker'></span></div></center>
								  <div className='screen'>
									<div className="container-fluid mobile-top-nav">
									  <div className="row">
										<div className="col-4">
										<p className="ms-2">13:23</p>
										</div>
										<div className="col-4">
										</div>
										<div className="col-4 text-end">
										<i className="bi bi-wifi ms-1"></i>
										<i className="bi bi-reception-4 ms-1"></i>
										<i className="bi bi-battery-half ms-1"></i>
										</div>
									  </div>
									</div>
									<div className="container-fluid mobile-top-nav-menu">
									  <div className="row">
										<div className="col-4">
										<i className="bi bi-arrow-left ms-1"></i>
										</div>
										<div className="col-4 text-center">
										<h6>Inbox</h6>
										</div>
										<div className="col-4 text-end">
										<i className="bi bi-three-dots-vertical"></i>
										</div>
									  </div>
									</div>
									<div className="container-fluid email-preview-list-area">
									  
									  <div className="row">
										<div className="col-2 pt-2">
										<span className="icon_avatar bg_blue_avatar"><i className="bi bi-person"></i></span>
										</div>
										<div className="col-8">
										<h6 id="preview_sender_name" className="preview_sender_name">{GetSenderName}</h6>
										<h6 id="preview_sender_subject" className="preview_sender_subject">{GetSubjectLine}</h6>
										<p id="preview_sender_preview" className="preview_sender_preview">{GetPreviewText}</p>
										</div>
										<div className="col-2 pt-2">
										<h6 className="preview-time">10:35</h6>
										</div>
									  </div>
									  
									  <div Style="opacity:0.5;"  className="row">
										<div className="col-2 pt-2">
										<span className="icon_avatar bg_red_avatar"><i className="bi bi-person"></i></span>
										</div>
										<div className="col-8">
										<h6 className="preview_sender_name">Aadow</h6>
										<h6 className="preview_sender_subject">Your Campaign Sent</h6>
										<p className="preview_sender_preview">This email to inform you that your campaign has been sent</p>
										</div>
										<div className="col-2 pt-2">
										<h6 className="preview-time">05:42</h6>
										</div>
									   </div>
									  
									</div>
									  
								  </div>
								</div>
							</div>
							<p className="email-preview-disclaimer">Actual email preview may vary depending on the email client.</p>
							</div>
							</div>
							</div>
					</div>
					
					
				</div>
			  </div>
			  
			  
			  
		</div>  
		</div>  
		{isSidenavOpen && <div className="overlay" onClick={handleOverlayClick}></div>}
		<div id="mySidenav" className={`sidenav_contact shadow-sm ${isSidenavOpen ? 'openedsidenav' : ''}`}>
	  <div id="add_contact_area" className="sidenav_content">
	  <div className="sidenav_top_area">
	  <div className="row align-items-center">
	  <div className="col-8">Schedule</div>
	  <div className="col-4"><a href="javascript:void(0)" className="closebtn" onClick={handleOverlayClick}>&times;</a></div>
	  </div>
	  </div>
	  <div className="sidenav_main_content">
	  <div id="allow_campaign_scheduled" className="">
	  <form>
	  <div className="row">
	  
	  <div className="col-12">
		<label>Please provide the exact date and time you wish to send the campaign:</label>
      </div>
	  
	  <div className="col-12 mt-3">
			
			
			<div id="send_besttime_campaign" disabled className=" schedule-option   align-items-center row">
			<div className="col-3 pe-0 me-0"><img src="https://app.aadow.com/imgs/send-best-mail-2.png"  /></div>
			<div className="col-9 ms-0 ps-0">
			<h3>Send at the Best Time </h3>
			<h6>Optimize send times for opens</h6>
			</div>
			</div>
			
			<div id="send_now_campaign" disabled className=" schedule-option   align-items-center row">
			<div className="col-3 pe-0 me-0"><img src="https://app.aadow.com/imgs/direct.png"/></div>
			<div className="col-9 ms-0 ps-0">
			<h3>Send now</h3>
			<h6>Send your campaign right now</h6>
			</div>
			</div>
			
			<div id="schedule_later_campaign" disabled className=" schedule-option  align-items-center row">
			<div className="col-3 pe-0 me-0"><img src="https://app.aadow.com/imgs/schedule.png"/></div>
			<div className="col-9 ms-0 ps-0">
			<h3>Schedule for later</h3>
			<h6>Set Date & Time for Campaign</h6>
			</div>
			</div>
	  </div>
	  </div>
	  
	  <div id="sendnow_area" className="row d-none">
		<div className="col-12">
		  <h6 className="error" id="edit_error"></h6>
		  <button id="final_send_button" name="final_send_button" type="button" className="submit-button-full text-end">Send Now</button>
		  <button id="final_sending_button" name="final_sending_button" type="button" disabled className="saving-button-full text-end">Sending...</button>
		</div>
	  </div>
	  
	  <div id="sendbest_area" className="row d-none">
		<div className="col-12">
		  <h6 className="error" id="edit_error"></h6>
		  <button id="final_sendbest_button" name="final_sendbest_button" type="button" className="submit-button-full text-end">Schedule</button>
		  <button id="final_sendingbest_button" name="final_sendingbest_button" type="button" disabled className="saving-button-full text-end">Sending...</button>
		</div>
	  </div>
	  
	  
	  
	  <div id="schedule_area" className="row d-none">
	  
		<div className="col-12">
		  <label>Date <span className="text-danger">*</span></label>
		  <input type="date" min="" className="form_conrol mt-2" autocomplete="off" name="schedule_date" id="schedule_date" />
		</div>
		
		<div className="col-md-4">
		<label>Time <span className="text-danger">*</span></label>
		
		<select id="schedule_hour" required name="schedule_hour" className="select nice-select wide input_form">
			<option value=""></option>
			<option value="00">12 AM</option>
			<option value="01">01 AM</option>
			<option value="02">02 AM</option>
			<option value="03">03 AM</option>
			<option value="04">04 AM</option>
			<option value="05">05 AM</option>
			<option value="06">06 AM</option>
			<option value="07">07 AM</option>
			<option value="08">08 AM</option>
			<option value="09">09 AM</option>
			<option value="10">10 AM</option>
			<option value="11">11 AM</option>
			<option value="12">12 PM</option>
			<option value="13">01 PM</option>
			<option value="14">02 PM</option>
			<option value="15">03 PM</option>
			<option value="16">04 PM</option>
			<option value="17">05 PM</option>
			<option value="18">06 PM</option>
			<option value="19">07 PM</option>
			<option value="20">08 PM</option>
			<option value="21">09 PM</option>
			<option value="22">10 PM</option>
			<option value="23">11 PM</option>
		</select>
		
		</div>
		
		<div className="col-md-4">
		<label> <span className="text-white">*</span></label>
		
		<select id="schedule_minute" required name="schedule_minute" className="select nice-select wide input_form">
			<option value=""></option>
			<option>00</option>
			<option>05</option>
			<option>10</option>
			<option>15</option>
			<option>20</option>
			<option>25</option>
			<option>30</option>
			<option>35</option>
			<option>40</option>
			<option>45</option>
			<option>50</option>
			<option>55</option>
		</select>
		
		</div>
		
		
		<div  className="col-md-12">
		    
		    </div>
		
		<div className="col-12">
		  <h6 className="error" id="shedule_error"></h6>
		  <button id="final_shedule_button" name="final_shedule_button" type="button" className="submit-button-full text-end">Schedule</button>
		  <button id="final_sheduling_button" name="final_sheduling_button" type="button" disabled className="saving-button-full text-end">Saving...</button>
		</div>
	  
	  </div>
	  
	  <div id="schedule_area" className="row d-none">
	  
		<div className="col-12">
		  <label>Date <span className="text-danger">*</span></label>
		  <input type="date" min="" className="form_conrol mt-2" autocomplete="off" name="schedule_date" id="schedule_date" />
		</div>
		
		<div className="col-md-4">
		<label>Time <span className="text-danger">*</span></label>
		
		<select id="schedule_hour" required name="schedule_hour" className="select nice-select wide input_form">
			<option value=""></option>
			<option value="00">00</option>
			<option value="01">01</option>
			<option value="02">02</option>
			<option value="03">03</option>
			<option value="04">04</option>
			<option value="05">05</option>
			<option value="06">06</option>
			<option value="07">07</option>
			<option value="08">08</option>
			<option value="09">09</option>
			<option value="10">10</option>
			<option value="11">11</option>
			<option value="12">12</option>
			<option value="13">13</option>
			<option value="14">14</option>
			<option value="15">15</option>
			<option value="16">16</option>
			<option value="17">17</option>
			<option value="18">18</option>
			<option value="19">19</option>
			<option value="20">20</option>
			<option value="21">21</option>
			<option value="22">22</option>
			<option value="23">23</option>
		</select>
		
		</div>
		
		<div className="col-md-4">
		<label> <span className="text-white">*</span></label>
		
		<select id="schedule_minute" required name="schedule_minute" className="select nice-select wide input_form">
			<option value=""></option>
			<option>00</option>
			<option>05</option>
			<option>10</option>
			<option>15</option>
			<option>20</option>
			<option>25</option>
			<option>30</option>
			<option>35</option>
			<option>40</option>
			<option>45</option>
			<option>50</option>
			<option>55</option>
		</select>
		
		</div>
		
		
		<div  className="col-md-12">
		  
		    </div>
		
		<div className="col-12">
		  <h6 className="error" id="shedule_error"></h6>
		  <button id="final_shedule_button" name="final_shedule_button" type="button" className="submit-button-full text-end">Schedule</button>
		  <button id="final_sheduling_button" name="final_sheduling_button" type="button" disabled className="saving-button-full text-end">Saving...</button>
		</div>
	  
	  </div>
	  
	  
	  
	  
	  </form>
	  </div>
	  <div id="notallow_campaign_scheduled" className="d-none">
			<div id="email_credits_error" className="email_credits_error">
			  You don't have enough credits. <span onclick="location.href='my-plan'">Upgrade your account</span> or <span onclick="location.href='my-plan'">buy credits</span> to send email campaigns.
			</div>
	  </div>
	  
	  </div>
	  </div>
	  
	  
	  {show && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					
					<div className="modal-body pb-4 pt-0 px-4">
					
						<div className="col-12">
							<div id="step_three" className="right-content">
							  <img src="../iconss/other/approved.png" className="success-icon" alt="Success" />
							  <h2 className="secondary-heading-modal text-center">Mail Scheduled!</h2>
							  <h2 className="secondary-description-modal text-center">Your mail has been scheduled successfully, Redirecting...</h2>
							  <center><button type="button" className="primary-button float-center" onClick={handleClose} >Okay</button></center>
							</div>
						</div>
						
					</div>
					
					
				  </div>
				</div>
			  </div>
			</>
		  )}
	</div>
       
    </SideBar>
  );
};

export default EmailApi;
