import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import '../../css/private/email-api.css';
import SideBar from './../components/SideBar';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { format } from 'date-fns';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const EmailApi = () => {
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  
  
  
  const [ApiInput, setApiInput] = useState('');
  
  const [apis, setApis] = useState([]);
  
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  
  
  const [ActiveUserName, setActiveUserName] = useState("Eshan");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  const HandleShowModal = () => {
	  
    setShow(true);
	setErrorName("");
	setName("");
    setNameError(false);
    setInputApiArea("true");
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };  
  
const [InputApiArea, setInputApiArea] = useState('true');

const [activeTab, setActiveTab] = useState('Curl'); // Manage active tab
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
  
  const codeRef = useRef(null);

  const handleCodeClick = () => {
    const range = document.createRange();
    range.selectNodeContents(codeRef.current);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  };
  
  const apiKeyRef = useRef(null);

  const copyApiKey = () => {
    const apiKey = apiKeyRef.current.value;
    navigator.clipboard.writeText(apiKey)
      .then(() => {
		  showSnackbar('API key copied to clipboard!', 'success');
      })
      .catch(err => {
        console.error('Failed to copy API key: ', err);
      });
  };
  
  
  const [Name, setName] = useState('');
  const [NameError, setNameError] = useState(false);
  const [ErrorName, setErrorName] = useState('');
  const NameRef = useRef(null);
  
  const handleGenerate = () =>{
	  
		setErrorName("");
		setNameError(false);
	if (Name.trim() === '') {
		setErrorName("Please give a name to your API");
		setNameError(true);
		NameRef.current.focus();
		return;
	}
	handleAddApi();
  };
  
  // Close dropdown when clicking outside of it
  useEffect(() => {
    document.title = 'Email API | Aadow';
	
	const current_username = 'pachorieshan';
	const username = 'testuser';
	const password = 'testpassword';
	const encodedCredentials = btoa(`${username}:${password}`);
	
	const fetchEmailAPIs = async () => {
			try {
			  const response = await fetch(`http://portal.aadow.com:8080/api/emailapi/list?username=${current_username}&page=0`, {
				method: 'GET',
				headers: {
				  'Authorization': `Basic ${encodedCredentials}`,
				  'Content-Type': 'application/json',
				},
			  });

			  if (!response.ok) {
				throw new Error('Network response was not ok');
			  }

			  const data = await response.json();
			  setApis(data.content); // Set the campaigns array
			} catch (error) {
			  setError("Fetch error: " + error.message);
			} finally {
			  setLoading(false); // Set loading to false once data is fetched
			}
	    };
	fetchEmailAPIs();	
  }, []);
  
  
	const current_username = 'pachorieshan';
	const username = 'testuser';
	const password = 'testpassword';
	const encodedCredentials = btoa(`${username}:${password}`);
	
	const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
	
	const handleClickOpen = (id) => {
        setSelectedId(id);
        setOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setOpen(false);
        setSelectedId(null);
    };
	
	const handleDelete = async () => {
        try {
            const response = await fetch(`http://portal.aadow.com:8080/api/emailapi/delete?id=${selectedId}&username=${current_username}`, {
                method: 'DELETE',
                headers: {
				  'Authorization': `Basic ${encodedCredentials}`,
                },
            });

            if (response.ok) {
				showSnackbar('API key Removed!', 'success');
                setApis(apis.filter(api => api.id !== selectedId));
            } else {
                alert('Failed to delete the API');
            }
        } catch (error) {
            alert('Error:', error);
        } finally {
            handleCloseDeleteModal();
        }
    };
	
	
	const handleAddApi = async () => {
        try {
            const response = await fetch(`http://portal.aadow.com:8080/api/emailapi/generate?username=${current_username}&name=${Name}`, {
                method: 'POST',
                headers: {
				  'Authorization': `Basic ${encodedCredentials}`,
                },
            });

            if (response.ok) {
                const newApi = await response.json(); // Assuming the API returns the new API object
                setApis([...apis, newApi]); // Add the new API to the list
				
                setInputApiArea("false");
				setApiInput(newApi.api);
				
            } else {
                console.error('Failed to add the API');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
	
  
    const codeSnippets = {
    Curl: `
# ------------------
# Create a campaign
# ------------------
curl -H 'api-key: YOUR_API_V3_KEY'
-X POST -d '{
# Define the campaign settings
"name":"Campaign sent via the API",
"subject":"My subject",
"sender": {"name":"From name", "email":"myfromemail@mycompany.com" },
"type":"classic",
# Content that will be sent
"htmlContent":"Congratulations! You successfully sent this example campaign via the Brevo API.",
# Select the recipients
"recipients": { "listIds": [2,7] },
# Schedule the sending in one hour
"scheduledAt": "2018-01-01 00:00:01",
}'
'https://api.brevo.com/v3/emailCampaigns'
    `,
    Ruby: `# ------------------
# Create a campaign
# ------------------
# Include the Brevo library
require 'sib-api-v3-sdk'
# Instantiate the client
SibApiV3Sdk.configure do |config|
config.api_key['api-key'] = 'YOUR_API_V3_KEY'
end
api_instance = SibApiV3Sdk::EmailCampaignsApi.new
# Define the campaign settings
email_campaigns = {
"name"=> "Campaign sent via the API",
"subject"=> "My subject",
"sender"=> { "name"=> "From name", "email"=> "myfromemail@mycompany.com"}, 
"type"=> "classic",
# Content that will be sent
"htmlContent":"Congratulations! You successfully sent this example campaign via the Brevo API.",
# Select the recipients
"recipients": { "listIds": [2,7] },
# Schedule the sending in one hour
# Make the call to the client
begin
result = api_instance.create_email_campaign(email_campaigns)
p result
rescue SibApiV3Sdk::ApiError => e
puts "Exception when calling EmailCampaignsApi->create_email_campaign: #{e}"
end`,
    Python: `# ------------------
# Create a campaign
# ------------------
# Include the Brevo library
require_once(__DIR__ . "/APIv3-php-library/autoload.php");
# Instantiate the client
Sendinblue\Client\Configuration::getDefaultConfiguration()->setApiKey("api-key","YOUR_API_V3_KEY");
$api_instance = new Sendinblue\Client\Api\EmailCampaignsApi();
$emailCampaigns = new \Sendinblue\Client\Model\CreateEmailCampaign();
# Define the campaign settings
$email_campaigns['name'] = "Campaign sent via the API";
$email_campaigns['subject'] = "My subject";
$email_campaigns['sender'] = array("name": "From name", "email":"myfromemail@mycompany.com");
$email_campaigns['type'] = "classic";
# Content that will be sent
"htmlContent"=> "Congratulations! You successfully sent this example campaign via the Brevo API.",
# Select the recipients
"recipients"=> array("listIds"=> [2, 7])
# Schedule the sending in one hour
"scheduledAt"=> "2018-01-01 00:00:01"
);
# Make the call to the client
try {
$result = $api_instance->createEmailCampaign($emailCampaigns);
print_r($result);
} catch (Exception $e) {
echo 'Exception when calling EmailCampaignsApi->createEmailCampaign: ', $e->getMessage(), PHP_EOL;
}`,
    PHP: `# ------------------
# Create a campaign\
# ------------------
# Include the Brevo library\
from __future__ import print_function
import time
import sib_api_v3_sdk
from sib_api_v3_sdk.rest import ApiException
from pprint import pprint
# Instantiate the client\
sib_api_v3_sdk.configuration.api_key['api-key'] = 'YOUR_API_V3_KEY'
api_instance = sib_api_v3_sdk.EmailCampaignsApi()
# Define the campaign settings\
email_campaigns = sib_api_v3_sdk.CreateEmailCampaign(
name= "Campaign sent via the API",
subject= "My subject",
sender= { "name": From name, "email": "myfromemail@mycompany.com"},
type= "classic",
# Content that will be sent\
html_content= "Congratulations! You successfully sent this example campaign via the Brevo API.",
# Select the recipients\
recipients= {"listIds": [2, 7]},
# Schedule the sending in one hour\
scheduled_at= "2018-01-01 00:00:01"
)
# Make the call to the client\
try:
api_response = api_instance.create_email_campaign(email_campaigns)
pprint(api_response)
except ApiException as e:
print("Exception when calling EmailCampaignsApi->create_email_campaign: %s\n" % e)`,
    NodeJS: `# ------------------
# Create a campaign\
# ------------------
# Include the Brevo library\
var SibApiV3Sdk = require('sib-api-v3-sdk');
var defaultClient = SibApiV3Sdk.ApiClient.instance;
# Instantiate the client\
var apiKey = defaultClient.authentications['api-key'];
apiKey.apiKey = 'YOUR_API_V3_KEY';
var apiInstance = new SibApiV3Sdk.EmailCampaignsApi();
var emailCampaigns = new SibApiV3Sdk.CreateEmailCampaign();
# Define the campaign settings\
emailCampaigns.name = "Campaign sent via the API";
emailCampaigns.subject = "My subject";
emailCampaigns.sender = {"name": "From name", "email": "myfromemail@mycompany.com"};
emailCampaigns.type = "classic";
# Content that will be sent\
htmlContent: 'Congratulations! You successfully sent this example campaign via the Brevo API.',
# Select the recipients\
recipients: {listIds: [2, 7]},
# Schedule the sending in one hour\
scheduledAt: '2018-01-01 00:00:01'
}
# Make the call to the client\
apiInstance.createEmailCampaign(emailCampaigns).then(function(data) {
console.log(API called successfully. Returned data: ' + data);
}, function(error) {
console.error(error);
});`
  };

  return (
    <SideBar>
      <div className="page-content ">
        <div className="email-api-body ">
        <div className="breadcrumb-area"> 
			 <nav aria-label="breadcrumb">
			  <ol className="breadcrumb">
				<li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
				<li className="breadcrumb-item"><a>API</a></li>
				<li className="breadcrumb-item active" aria-current="page">Email</li>
			  </ol>
			</nav>
		</div>	
		 <div className="header-area">
            <div className="top-area">
              <div className="row align-items-center">
                <div className="col-8">
                  <h1 className="main-heading">Email API</h1>
                  <h6 className="main-subheading">Effortlessly manage and monitor your email APIs for seamless communication and integration.</h6>
                </div>
                <div className="col-4">
                  <button type="button" onClick={HandleShowModal} className="primary-button float-end mx-2">Generate a New API Key</button>
                </div>
              </div>
            </div>
          </div>
		  {show && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100">
						<div className="row w-100">
						  <div className="col-11">
							<h5 className="modal-title">Generate a new API Key</h5>
						  </div>
						  <div className="col-1">
							  <a style={{textDecoration: 'none'}} className="close" onClick={handleClose}>
								<span style={{fontSize: '2vw', color: 'rgba(100, 100, 100)', cursor: 'pointer'}}>&times;</span>
							  </a>
						  </div>	  
						</div>  
					</div>
					<div className="modal-body py-4 px-4">
					{InputApiArea === 'true' && (
						<div className="col-12">
							<label>Name your API key<b className="text-danger">*</b></label>
							<input type="text" ref={NameRef} className={`input-forms w-100 ${NameError ? 'input-error' : ''}`} value={Name} onChange={(e) => {
								  setName(e.target.value);
								}} />
							{ErrorName && <div className="error-message">{ErrorName}</div>}
						</div>
					)}
					{InputApiArea === 'false' && (	
						<div className="col-12">
							<p className="api-disclaimer">API key has been generated successfully. Please copy this key and save it somewhere safe. For security reasons, we cannot show it to you again.</p>
							
							<div className="row align-items-center">
							  <div className="col-12">
								<input
								  type="text"
								  className="input-forms"
								  ref={apiKeyRef}
								  readOnly
								  value={ApiInput} 
								  onChange={(e) => setApiInput(e.target.value)}
								/>
							  </div>
							  <div className="col-4 mt-4">
								<button type="button" className="primary-button" onClick={copyApiKey}>
								  <i className="bi bi-copy me-3"></i>Copy API
								</button>
							  </div>
							</div>
						</div>
					)}
						
					</div>
					
					{InputApiArea === 'true' && (
					<div className="modal-footer ">
						<button type="button" className="secondary-button" onClick={handleClose} data-bs-dismiss="modal">Cancel</button>
						<button type="button" className="primary-button" onClick={handleGenerate}>Generate</button>
					</div>
					)}
					
				  </div>
				</div>
			  </div>
			</>
		  )}
		  <div className="table-container">
			<h4 className="your-api-heading">Your API Keys</h4>
			{apis.length === 0 ? (
							<center>
								<div className="empty-error-area">
									<img className="empty-error-image w-25" src="../../iconss/other/no-results.png"  />
									<h2 className="empty-error-heading">No APIs Found</h2>
									<h6 className="empty-error-subheading" >It looks like you don't have any generated APIs at the moment. Create one now to get started!</h6>
								</div>
							</center>
			) : (
			  <table className="styled-table">
				<thead>
				  <tr>
					<th>Version</th>
					<th>API Key</th>
					<th>Name</th>
					<th>Created on</th>
					<th></th>
				  </tr>
				</thead>
				<tbody>
				{apis.map(api => (
				  <tr>
					<td><h5>v3</h5></td>
					<td><h5 className="transaction-date">{'****************' + api.api.slice(-6)}</h5></td>
					<td><h5 className="transaction-amount">{api.name}</h5></td>
					<td><h5 className="transaction-invoice-id">{format(new Date(api.date), 'MMM dd, yyyy')}</h5></td>
					<td><a href="#" className="download-invoice" onClick={() => handleClickOpen(api.id)}><i className="bi bi-trash"></i></a></td>
				  </tr>
				))}  
				</tbody>
			  </table>
			  )}	
			</div>	
		
		<Dialog open={open} onClose={handleCloseDeleteModal}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this API? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteModal} className="text-secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} className="text-danger">
                        Delete
                    </Button>
                </DialogActions>
        </Dialog>
		
		<div className="documentation-area d-none">
			<div className="row">
				<div className="col-5">
					<h3 className="documentation-heading">About the API</h3>
					<h6 className="documentation-description">The Brevo API makes it easy for programmers to integrate many of Brevo's features into other applications. Interested in learning more?</h6>
					<a  className="documentation-link" href="">Read our API documentation</a>
				</div>
				
				<div className="col-7">
                <div className="code-box">
                  <div className="code-tabs-area">
                    {/* Tab navigation */}
                    {['Curl', 'Ruby', 'Python', 'PHP', 'NodeJS'].map(tab => (
                      <a 
                        key={tab} 
                         
                        className={`code-tab ${activeTab === tab ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab)}
                      >
                        {tab}
                      </a>
                    ))}
                  </div>
                  
                  <div className="tab-content-area">
                    {/* Display code based on the active tab */}
                    <pre>
                      <code ref={codeRef} onClick={handleCodeClick}>
                        {codeSnippets[activeTab].trim()}
                      </code>
                    </pre>
                  </div>
                </div>
              </div>
				
			</div>
		</div>
		  
        </div>
      </div>
		  <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
			<MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
		  </Snackbar>
    </SideBar>
  );
};

export default EmailApi;
