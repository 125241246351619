import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CountrySelect from './../components/CountrySelect';
import '../../css/private/checkout.css';
import SideBar from './../components/SideBar';
import Cookies from 'js-cookie';
// Import other settings components as needed

const Checkout = () => {
   const [amount, setAmount] = useState(0);
   const [razorpayLoaded, setRazorpayLoaded] = useState(false);
   
   	const [loading, setLoading] = useState(true);
 	const [error, setError] = useState(null);
	const CookieSelectedPlanId = Cookies.get('selected_plan_id');
	const [planName, setplanName] = useState("-----");
 	const [price, setprice] = useState("-----");
 	const [credits, setcredits] = useState("-----");

    useEffect(() => {
		const current_username = 'pachorieshan';
		const username = 'testuser';
		const password = 'testpassword';
		const planId = 'PRO003';
		const encodedCredentials = btoa(`${username}:${password}`);

		const fetchPlanInfo = async () => {
			try {
			  const response = await fetch(`http://localhost:8080/api/pricing-plans/${planId}`, {
				method: 'GET',
				headers: {
				  'Authorization': `Basic ${encodedCredentials}`,
				  'Content-Type': 'application/json',
				},
			  });

			  if (!response.ok) {
				throw new Error('Network response was not ok');
			  }

			  const data = await response.json();
			  setAmount(data.price);
			  setLoading(false);  // Set loading to false once data is fetched
			} catch (error) {
			  setError("Fetch error:", error);
			  setLoading(false);
			}
		};

		fetchPlanInfo();

        const loadRazorpayScript = () => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => setRazorpayLoaded(true);
            document.body.appendChild(script);
        };

        loadRazorpayScript();
    }, []);

    const handlePayment = () => {
        if (!razorpayLoaded) {
            alert('Razorpay SDK not loaded yet.');
            return;
        }

        const options = {
            key: 'rzp_test_a9Y2LPAou4n5tp', // Replace with your Razorpay key ID
            amount: amount * 100, // Amount is in paise
            currency: 'INR',
            name: 'Your Company Name',
            description: 'Test Transaction',
            handler: function (response) {
                // Handle success here
                alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
                console.log(response);
            },
            prefill: {
                name: 'Customer Name',
                email: 'customer@example.com',
                contact: '9999999999',
            },
            theme: {
                color: '#000000',
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    }; 
	
   	
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [BillingInfoAreashow, setBillingInfoAreashow] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('yearly'); // Default selection

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  const HandleShowModal = () => {
    setShow(true);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  
  const [countryselectedOption, countrysetSelectedOption] = useState('India'); // Move state to parent
  
  const [Name, setName] = useState('');
  const [NameError, setNameError] = useState(false);
  const [ErrorName, setErrorName] = useState('');
  const NameRef = useRef(null);
  
  const [EmailSentTo, setEmailSentTo] = useState('');
  const [Email, setEmail] = useState('');
  const [EmailError, setEmailError] = useState(false);
  const [ErrorEmail, setErrorEmail] = useState('');
  const EmailRef = useRef(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  const [Phone, setPhone] = useState('');
  const [PhoneError, setPhoneError] = useState(false);
  const [ErrorPhone, setErrorPhone] = useState('');
  const PhoneRef = useRef(null);
  
  const [Company, setCompany] = useState('');
  const [CompanyError, setCompanyError] = useState(false);
  const [ErrorCompany, setErrorCompany] = useState('');
  const CompanyRef = useRef(null);
  
  const [TaxDetail, setTaxDetail] = useState('');
  const [TaxDetailError, setTaxDetailError] = useState(false);
  const [ErrorTaxDetail, setErrorTaxDetail] = useState('');
  const TaxDetailRef = useRef(null);
  
  const [Address, setAddress] = useState('');
  const [AddressError, setAddressError] = useState(false);
  const [ErrorAddress, setErrorAddress] = useState('');
  const AddressRef = useRef(null);
  
  const [City, setCity] = useState('');
  const [CityError, setCityError] = useState(false);
  const [ErrorCity, setErrorCity] = useState('');
  const CityRef = useRef(null);
  
  
  const [State, setState] = useState('');
  const [StateError, setStateError] = useState(false);
  const [ErrorState, setErrorState] = useState('');
  const StateRef = useRef(null);
  
  
  const [PostalCode, setPostalCode] = useState('');
  const [PostalCodeError, setPostalCodeError] = useState(false);
  const [ErrorPostalCode, setErrorPostalCode] = useState('');
  const PostalCodeRef = useRef(null);
  
  
  const [BiilingName, setBiilingName] = useState('');
  const [BillingPhone, setBillingPhone] = useState('');
  const [BillingEmail, setBillingEmail] = useState('');
  const [BillingCompany, setBillingCompany] = useState('');
  const [BillingTax, setBillingTax] = useState('');
  const [BillingAddress, setBillingAddress] = useState('');
  const [BillingCity, setBillingCity] = useState('');
  const [BillingState, setBillingState] = useState('');
  const [BillingCountry, setBillingCountry] = useState('');
  const [BillingPostalCode, setBillingPostalCode] = useState('');
  
  const [ErrorCountry, setErrorCountry] = useState('');
  
  const handleSaveBillingInfo = () =>{
	  
		setErrorName("");
		setNameError(false);
		setErrorEmail("");
		setEmailError(false);
		setErrorPhone("");
		setPhoneError(false);
		setErrorCompany("");
		setCompanyError(false);
		setErrorAddress("");
		setAddressError(false);
		setErrorCity("");
		setCityError(false);
		setErrorState("");
		setStateError(false);
		setErrorPostalCode("");
		setPostalCodeError(false);
		setErrorCountry("");
	if (Name.trim() === '') {
		setErrorName("Please enter a name");
		setNameError(true);
		NameRef.current.focus();
		return;
	}else if (Email.trim() === '') {
		setErrorEmail("Please enter a email address");
		setEmailError(true);
		EmailRef.current.focus();
		return;
	}else if (!emailRegex.test(Email)) {
		setErrorEmail("Please enter a valid email address");
		setEmailError(true);
		EmailRef.current.focus();
		return;
	}else if (Phone.trim() === '') {
		setErrorPhone("Please enter a phone number");
		setPhoneError(true);
		PhoneRef.current.focus();
		return;
	}else if (Company.trim() === '') {
		setErrorCompany("Please enter company name");
		setCompanyError(true);
		CompanyRef.current.focus();
		return;
	}else if (Address.trim() === '') {
		setErrorAddress("Please enter company's address");
		setAddressError(true);
		AddressRef.current.focus();
		return;
	}else if (City.trim() === '') {
		setErrorCity("Please enter city name");
		setCityError(true);
		CityRef.current.focus();
		return;
	}else if (State.trim() === '') {
		setErrorState("Please enter state name");
		setStateError(true);
		StateRef.current.focus();
		return;
	}else if (PostalCode.trim() === '') {
		setErrorPostalCode("Please enter postal code");
		setPostalCodeError(true);
		PostalCodeRef.current.focus();
		return;
	}else if (countryselectedOption.trim() === '') {
		setErrorCountry("Please select a country");
		return;
	}
	
    setBillingInfoAreashow(true);
    setShow(false);
	setBiilingName(Name);
	setBillingEmail(Email);
	setBillingPhone(Phone);
	setBillingCompany(Company);
	setBillingTax(TaxDetail);
	setBillingAddress(Address);
	setBillingCity(City);
	setBillingState(State);
	setBillingPostalCode(PostalCode);
	setBillingCountry(countryselectedOption);
	showSnackbar('Billing Info Saved', 'success');
  };
  
 const [InputApiArea, setInputApiArea] = useState('true');
  
  useEffect(() => {
    document.title = 'Checkout | Aadow';

  }, []);

  return (
    <SideBar>
      <div className="campaign-overview-content">
        <div className="checkout-body">
          <div className="breadcrumb-area">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item"><Link to="/settings#my-plan">My Plan</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Checkout</li>
              </ol>
            </nav>
          </div>
		  
		<div className="checkout-area">
		  <div className="row">
			<div className="col-8">
				<div className="left-area">
					<div className="left-header-area">
						<h2 className="main-plan-heading">Ultimate Pro - Plan <Link className="change-plan" to="/pricing">Change</Link></h2>
						<h6 className="main-plan-subheading">Your plan will start from today - 24 Sep 2024</h6>
					</div>
					
					<div className="duration-plan-area">
					  {/* Monthly Plan */}
					  <div 
						className={`duration-plan-col ${selectedPlan === 'monthly' ? 'active-duration-plan' : ''}`}
						onClick={() => handlePlanSelect('monthly')}
					  >
						<h2 className="duration-plan-heading">
						  <i className={`bi ${selectedPlan === 'monthly' ? 'bi-check-circle-fill' : 'bi-circle'}`}></i> Monthly
						</h2>
						<div className="duration-plan-bottom-area">
						  <div className="row">
							<div className="col-9">
							  <h4 className="duration-plan-bottom-heading">$499.99/mo</h4>
							  <h4 className="duration-plan-bottom-subheading">$499.99 Billed Monthly</h4>
							</div>
						  </div>
						</div>
					  </div>

					  {/* Half-Yearly Plan */}
					  <div 
						className={`d-none duration-plan-col ${selectedPlan === 'half-yearly' ? 'active-duration-plan' : ''}`}
						onClick={() => handlePlanSelect('half-yearly')}
					  >
						<h2 className="duration-plan-heading">
						  <i className={`bi ${selectedPlan === 'half-yearly' ? 'bi-check-circle-fill' : 'bi-circle'}`}></i> Half-Yearly
						</h2>
						<div className="duration-plan-bottom-area">
						  <div className="row">
							<div className="col-7">
							  <h4 className="duration-plan-bottom-heading">$419.99/mo</h4>
							  <h4 className="duration-plan-bottom-subheading">$4499 Billed Yearly</h4>
							</div>
							<div className="col-5 text-end">
							  <span className="saving-label">5% off</span>
							</div>
						  </div>
						</div>
					  </div>

					  {/* Yearly Plan */}
					  <div 
						className={`duration-plan-col ${selectedPlan === 'yearly' ? 'active-duration-plan' : ''}`}
						onClick={() => handlePlanSelect('yearly')}
					  >
						<h2 className="duration-plan-heading">
						  <i className={`bi ${selectedPlan === 'yearly' ? 'bi-check-circle-fill' : 'bi-circle'}`}></i> Yearly
						</h2>
						<div className="duration-plan-bottom-area">
						  <div className="row">
							<div className="col-7">
							  <h4 className="duration-plan-bottom-heading">$419.99/mo</h4>
							  <h4 className="duration-plan-bottom-subheading">$4499 Billed Yearly</h4>
							</div>
							<div className="col-5 text-end">
							  <span className="saving-label">10% off</span>
							</div>
						  </div>
						</div>
					  </div>
					</div>
					
					
					<div className="features-plan-area">
						<h5 className="features-plan-heading">Features</h5>
						<div className="features-box">
							<div className="row">
								<div className="col-6"><h6 className="feature-list"><i className="bi bi-check-lg"></i> Unlimited Contacts</h6></div>
								<div className="col-6"><h6 className="feature-list"><i className="bi bi-check-lg"></i> Personalized Learning Path</h6></div>
								<div className="col-6"><h6 className="feature-list"><i className="bi bi-check-lg"></i> Priority Support</h6></div>
								<div className="col-6"><h6 className="feature-list"><i className="bi bi-check-lg"></i> Advanced Progress Tracking</h6></div>
								<div className="col-6"><h6 className="feature-list"><i className="bi bi-check-lg"></i> Certificate of Compassion</h6></div>
							</div>
						</div>
					</div>	
					{BillingInfoAreashow && (
					<div className="features-plan-area">
						<div className="row align-items-center">
							<div className="col-6">
								<h5 className="features-plan-heading d-block">Billing Information</h5>
							</div>
							<div className="col-6 text-end">
								<a href="#" onClick={HandleShowModal} className="edit-link">Edit</a>
							</div>
						</div>	
						<div className="features-box">
							<div className="row">
								<div className="col-12">
									<h6 className="billing-info-list-active">{BiilingName}</h6>
									<h6 className="billing-info-list">{BillingPhone} | {BillingEmail}</h6>
									<h6 className="billing-info-list">{BillingCompany} | {BillingTax}</h6>
									<h6 className="billing-info-list">{BillingAddress}, {BillingCity}, {BillingState}, {BillingCountry} - {BillingPostalCode}</h6>
								</div>
							</div>
						</div>
					</div>	
					)}
					
					
					
				</div>	
			</div>
			
			<div className="col-4">
				<div className="right-area">
					<div className="right-header-area">
						<h2 className="main-plan-heading">Summary</h2>
						<h6 className="main-plan-subheading">Plan Starts on July 1, 2024</h6>
					</div>
					<div className="billing-total-area">
						<div className="row"><div className="col-6"><h6 className="billing-summary-left">Monthly</h6></div><div className="col-6"><h6 className="billing-summary-right">$49.99</h6></div>	
						</div>
						<div className="row"><div className="col-6"><h6 className="billing-summary-left">Estimated Tax</h6></div><div className="col-6"><h6 className="billing-summary-right">$4.57</h6></div>	
						</div>
					</div>
					<div className="final-total-area">
						<div className="row"><div className="col-6"><h6 className="billing-summary-left">Final Total</h6></div><div className="col-6"><h6 className="billing-summary-right">$54.54</h6></div>	
						</div>
					</div>
					
					<button type="button" onClick={handlePayment} className="primary-button w-100">Make Payment</button>
					
				</div>
			</div>
		  </div>
		</div>
		  
		  
        </div>
		
		{show && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog modal-lg" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100">
						<div className="row w-100">
						  <div className="col-11">
							<h5 className="modal-title">Billing Information</h5>
						  </div>
						  <div className="col-1 text-end">
							  <a style={{textDecoration: 'none'}} className="close" onClick={handleClose}>
								<span style={{fontSize: '2vw', color: 'rgba(100, 100, 100)', cursor: 'pointer'}}>&times;</span>
							  </a>
						  </div>	  
						</div>  
					</div>
					<div className="modal-body pb-4 pt-0 px-4">
					<div className="row">
						<div className="col-4">
							<label>Name<b className="text-danger">*</b></label>
							<input type="text" ref={NameRef} className={`input-forms w-100 ${NameError ? 'input-error' : ''}`} value={Name} onChange={(e) => {
								  setName(e.target.value);
								}} />
							{ErrorName && <div className="error-message">{ErrorName}</div>}
						</div>
						<div className="col-4">
							<label>Email<b className="text-danger">*</b></label>
							<input type="text" ref={EmailRef} className={`input-forms w-100 ${EmailError ? 'input-error' : ''}`} value={Email} onChange={(e) => {
								  setEmail(e.target.value);
								}} />
							{ErrorEmail && <div className="error-message">{ErrorEmail}</div>}
						</div>
						<div className="col-4">
							<label>Phone<b className="text-danger">*</b></label>
							<input type="text" ref={PhoneRef} className={`input-forms w-100 ${PhoneError ? 'input-error' : ''}`} value={Phone} onChange={(e) => {
								  setPhone(e.target.value);
								}} />
							{ErrorPhone && <div className="error-message">{ErrorPhone}</div>}
						</div>
						<div className="col-6">
							<label>Company Name<b className="text-danger">*</b></label>
							<input type="text" ref={CompanyRef} className={`input-forms w-100 ${CompanyError ? 'input-error' : ''}`} value={Company} onChange={(e) => {
								  setCompany(e.target.value);
								}} />
							{ErrorCompany && <div className="error-message">{ErrorCompany}</div>}
						</div>
						<div className="col-6">
							<label>GST Detail</label>
							<input type="text" ref={TaxDetailRef} className={`input-forms w-100 ${TaxDetailError ? 'input-error' : ''}`} value={TaxDetail} onChange={(e) => {
								  setTaxDetail(e.target.value);
								}} />
							{ErrorTaxDetail && <div className="error-message">{ErrorTaxDetail}</div>}
						</div>
						
						<div className="col-12">
							<label>Address<b className="text-danger">*</b></label>
							<input type="text" ref={AddressRef} className={`input-forms w-100 ${AddressError ? 'input-error' : ''}`} value={Address} onChange={(e) => {
								  setAddress(e.target.value);
								}} />
							{ErrorAddress && <div className="error-message">{ErrorAddress}</div>}
						</div>
						
						<div className="col-6">
							<label>City<b className="text-danger">*</b></label>
							<input type="text" ref={CityRef} className={`input-forms w-100 ${CityError ? 'input-error' : ''}`} value={City} onChange={(e) => {
								  setCity(e.target.value);
								}} />
							{ErrorCity && <div className="error-message">{ErrorCity}</div>}
						</div>
						
						<div className="col-6">
							<label>State<b className="text-danger">*</b></label>
							<input type="text" ref={StateRef} className={`input-forms w-100 ${StateError ? 'input-error' : ''}`} value={State} onChange={(e) => {
								  setState(e.target.value);
								}} />
							{ErrorState && <div className="error-message">{ErrorState}</div>}
						</div>
						
						
						<div className="col-6">
							<label>Postal Code<b className="text-danger">*</b></label>
							<input type="text" ref={PostalCodeRef} className={`input-forms w-100 ${PostalCodeError ? 'input-error' : ''}`} value={PostalCode} onChange={(e) => {
								  setPostalCode(e.target.value);
								}} />
							{ErrorPostalCode && <div className="error-message">{ErrorPostalCode}</div>}
						</div>
						
						<div className="col-6">
							<label>Country<b className="text-danger">*</b></label>
							<CountrySelect
								countryselectedOption={countryselectedOption}
								countryhandleChange={(value) => {
								  countrysetSelectedOption(value);
								}}
							  />
							{ErrorCountry && <div className="error-message">{ErrorCountry}</div>}
						</div>
				
					</div>	
						
					</div>
					
					<div className="modal-footer ">
						<button type="button" className="secondary-button" onClick={handleClose} data-bs-dismiss="modal">Cancel</button>
						<button type="button" className="primary-button" onClick={handleSaveBillingInfo}>Save Info</button>
					</div>
					
				  </div>
				</div>
			  </div>
			</>
		  )}
		
      </div>
			
		  <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
			<MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
		  </Snackbar>
	  <br/><br/><br/><br/><br/><br/>
    </SideBar>
  );
};

export default Checkout;