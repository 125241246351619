import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '../../css/private/create-email-template-drag-drop-editor.css';

const ItemTypes = {
  ELEMENT: 'element',
};

const DraggableElement = ({ element }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.ELEMENT,
    item: { element },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      className="element"
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <img className="element-icon" src={element.icon} alt={element.title} />
      <h6 className="element-title">{element.title}</h6>
      <i className="bi bi-grip-horizontal"></i>
    </div>
  );
};

const DroppedElement = ({ element, index, onMoveUp, onMoveDown, onRemove }) => {
  return (
    <div className="dropped-element">
      <img
        src={element.icon}
        alt={element.title}
        style={{ width: '50px', margin: '10px' }}
      />
      <h6>{element.title}</h6>

      {/* Move and Remove Buttons */}
      <div className="element-controls">
        <button onClick={() => onMoveUp(index)} disabled={index === 0}>
          Move Up
        </button>
        <button onClick={() => onMoveDown(index)}>
          Move Down
        </button>
        <button onClick={() => onRemove(index)}>
          Remove
        </button>
      </div>
    </div>
  );
};

const DropArea = ({ onDrop, droppedElements, onMoveUp, onMoveDown, onRemove }) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.ELEMENT,
    drop: (item) => onDrop(item.element),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      className="canva-area"
      ref={drop}
      style={{
        border: '2px dashed #ccc',
        backgroundColor: isOver ? '#f5f5f5' : 'white',
        minHeight: '400px',
        padding: '10px',
      }}
    >
      {isOver && <p>Release to drop</p>}
      {droppedElements.map((element, index) => (
        <DroppedElement
          key={index}
          element={element}
          index={index}
          onMoveUp={onMoveUp}
          onMoveDown={onMoveDown}
          onRemove={onRemove}
        />
      ))}
    </div>
  );
};

const CreateEmailTemplateDragDropEditor = () => {
  const [droppedElements, setDroppedElements] = useState([]);

  const handleDrop = (element) => {
    setDroppedElements((prev) => [...prev, element]);
  };

  const handleMoveUp = (index) => {
    if (index > 0) {
      const newElements = [...droppedElements];
      const temp = newElements[index - 1];
      newElements[index - 1] = newElements[index];
      newElements[index] = temp;
      setDroppedElements(newElements);
    }
  };

  const handleMoveDown = (index) => {
    if (index < droppedElements.length - 1) {
      const newElements = [...droppedElements];
      const temp = newElements[index + 1];
      newElements[index + 1] = newElements[index];
      newElements[index] = temp;
      setDroppedElements(newElements);
    }
  };

  const handleRemove = (index) => {
    const newElements = droppedElements.filter((_, i) => i !== index);
    setDroppedElements(newElements);
  };

  // Close dropdown when clicking outside of it
  useEffect(() => {
    document.title = 'Email Template - Drag Drop Editor | Aadow';
  }, []);

  const elements = [
    { title: 'Title', icon: 'https://app.aadow.com/svg/title.svg' },
    { title: 'Text', icon: 'https://app.aadow.com/svg/text.svg' },
    { title: 'Button', icon: 'https://app.aadow.com/svg/button.svg' },
    { title: 'Logo', icon: 'https://app.aadow.com/svg/logo.svg' },
    { title: 'Image', icon: 'https://app.aadow.com/svg/image.svg' },
    { title: 'Social', icon: 'https://app.aadow.com/svg/social.svg' },
    { title: 'Header', icon: 'https://app.aadow.com/svg/header.svg' },
    { title: 'Footer', icon: 'https://app.aadow.com/svg/footer.svg' },
    { title: 'Divider', icon: 'https://app.aadow.com/svg/divider.svg' },
    { title: 'Spacer', icon: 'https://app.aadow.com/svg/spacer.svg' },
  ];

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="page-content">
        <div className="create-drag-drop-template-body">
          <header>
            <div className="row">
              <div className="col-4">
                <button className="back-button secondary-button ms-2 me-2">
                  <i className="bi bi-arrow-left me-2"></i> Back to Templates
                </button>
              </div>
              <div className="col-4">
                <input
                  type="text"
                  className="template-title"
                  value="New Product Annoucement of 26th Jan"
                ></input>
              </div>
              <div className="col-4 text-end">
                <button className="publish-button primary-button ms-2">
                  <i className="bi bi-megaphone me-2"></i> Publish
                </button>
              </div>
            </div>
          </header>

          <div className="semi-header">
            <div className="row">
              <div className="col-6">
                <a href="" className="active-tab">Elements</a>
                <a href="" className="tab">Content</a>
                <a href="" className="tab">Body Style</a>
              </div>
              <div className="col-6 text-end">
                <button className="secondary-outline-button me-2 undo-redo">
                  <i className="bi bi-arrow-counterclockwise"></i>
                </button>
                <button className="secondary-outline-button ms-2 me-2 undo-redo">
                  <i className="bi bi-arrow-clockwise"></i>
                </button>
                <button className="secondary-outline-button ms-2 me-2">
                  <i className="bi bi-eye me-2"></i> Preview
                </button>
                <button className="secondary-button ms-2 me-2">
                  <i className="bi bi-send me-2"></i> Send Test
                </button>
              </div>
            </div>
          </div>

          <div className="editor-area">
            <div className="editor-container">
              <div className="left-section">
                <div className="element-area">
                  <div className="row">
                    {elements.map((element, index) => (
                      <div className="col-4" key={index}>
                        <DraggableElement element={element} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="right-section">
				<DropArea
					onDrop={handleDrop}
					droppedElements={droppedElements}
					onMoveUp={handleMoveUp}
					onMoveDown={handleMoveDown}
					onRemove={handleRemove}
				/>
				</div>

            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default CreateEmailTemplateDragDropEditor;
