import React, { useState, useEffect, useRef } from 'react';
import '../../css/private/create-email-template-text-editor.css';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Select, MenuItem, InputBase, FormControl, InputLabel, Box, Typography } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

const CreateEmailTemplateTextEditor = () => {
  const [InputApiArea, setInputApiArea] = useState('true');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  const HandleShowModal = () => {
	  
    setShow(true);
	setErrorEmail("");
	setEmail("");
    setEmailError(false);
    setInputApiArea("true");
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };  
  
  const [Name, setName] = useState('');
  const [NameError, setNameError] = useState(false);
  const [ErrorName, setErrorName] = useState('');
  const [EmailSentTo, setEmailSentTo] = useState('');
  const NameRef = useRef(null);
  
  const [Email, setEmail] = useState('');
  const [EmailError, setEmailError] = useState(false);
  const [ErrorEmail, setErrorEmail] = useState('');
  const EmailRef = useRef(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  const handleAddSender = () =>{
	  
		setErrorEmail("");
		setEmailError(false);
	if (Email.trim() === '') {
		setErrorEmail("Please enter a sender email address");
		setEmailError(true);
		EmailRef.current.focus();
		return;
	}else if (!emailRegex.test(Email)) {
		setErrorEmail("Please enter a valid email address");
		setEmailError(true);
		EmailRef.current.focus();
		return;
	}
	setEmailSentTo(Email);
	setInputApiArea("false");
  };
  
  const [editorContent, setEditorContent] = useState('');

  useEffect(() => {
    document.title = 'Email Template - Text Editor | Aadow';
  }, []);

  const handleEditorChange = (value) => {
    setEditorContent(value);
  };
  const handleGoBack = () => {
    window.history.back();
  };
  return (
    <div>
      <div className="page-content">
        <div className="create-text-editor-template-body">
          <header className="header-area">
            <div className="row align-items-center">
              <div className="col-4">
                <button onClick={handleGoBack} className="secondary-outline-button">Back</button>
              </div>
              <div className="col-4 text-center">
                <input type="text" className="template-title" value="New Template - Code Editor" readOnly />
              </div>
              <div className="col-4 text-end">
                <button type="button" onClick={HandleShowModal} className="secondary-outline-button me-2">Send Test Email</button>
                <button type="button" className="primary-button ms-3">Save Template</button>
              </div>
            </div>
          </header>

          <div className="editor-area">
            <div className="row bg-white">
              <div className="col-6 p-0 m-0">
                <ReactQuill
                  value={editorContent}
                  onChange={handleEditorChange}
                  modules={CreateEmailTemplateTextEditor.modules}
				  className="custom-quill"
                />
              </div>
              <div className="col-6 p-0 m-0">
                <div className="live-preview" dangerouslySetInnerHTML={{ __html: editorContent }} />
              </div>
            </div>
          </div>
        </div>  
      </div>
	  {show && (
			<>
			  <div  style={{position: 'fixed',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: 1040}}  className="modal-overlay" onClick={handleClose}></div>
			  <div className="modal show d-block" tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
				  <div className="modal-content">
					<div className="modal-header w-100">
						<div className="row w-100">
						  <div className="col-11">
							<h5 className="modal-title">Send Test Email</h5>
						  </div>
						  <div className="col-1">
							  <a style={{textDecoration: 'none'}} className="close" onClick={handleClose}>
								<span style={{fontSize: '2vw', color: 'rgba(100, 100, 100)', cursor: 'pointer'}}>&times;</span>
							  </a>
						  </div>	  
						</div>  
					</div>
					<div className="modal-body pb-4 pt-0 px-4">
					{InputApiArea === 'true' && (
					<div className="row">
						
						
						<div className="col-12">
							<label>Sender Email<b className="text-danger">*</b></label>
							<input type="text" ref={EmailRef} className={`input-forms w-100 ${EmailError ? 'input-error' : ''}`} value={Email} onChange={(e) => {
								  setEmail(e.target.value);
								}} />
							{ErrorEmail && <div className="error-message">{ErrorEmail}</div>}
						</div>
					</div>	
					)}
					{InputApiArea === 'false' && (	
						<div className="col-12">
							<div id="step_three" className="right-content">
							  <img src="../../iconss/other/approved.png" className="success-icon" alt="Success" />
							  <h2 className="secondary-heading-modal text-center">Test Mail Sent!</h2>
							  <h2 className="secondary-description-modal text-center">Test mail sent to <b>{EmailSentTo}</b></h2>
							  <center><button type="button" className="primary-button float-center" onClick={handleClose} >Okay</button></center>
							</div>
						</div>
					)}
						
					</div>
					
					{InputApiArea === 'true' && (
					<div className="modal-footer ">
						<button type="button" className="secondary-button" onClick={handleClose} data-bs-dismiss="modal">Cancel</button>
						<button type="button" className="primary-button" onClick={handleAddSender}>Send Test Mail</button>
					</div>
					)}
					
				  </div>
				</div>
			  </div>
			</>
		  )}
    </div>
  );
};

// Define custom modules or formats if needed
CreateEmailTemplateTextEditor.modules = {
  toolbar: [                        // Font style dropdown
    [{ 'header': '1' }, { 'header': '2' }],  // Header sizes
    ['bold', 'italic', 'underline', 'strike'], // Text formatting options
    [{ 'color': [] }, { 'background': [] }], // Text color and background color
    [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Ordered and bullet lists
    [{ 'align': [] }],                       // Text alignment options
    ['link', 'blockquote', 'code-block'], // Link, image, blockquote, code-block options
    ['clean']   
  ]
};

export default CreateEmailTemplateTextEditor;
