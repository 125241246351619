import React, { useState, useEffect, useRef } from 'react';
import '../../css/private/import-contacts.css';
import { Link } from 'react-router-dom';
import SideBar from './../components/SideBar';
import axios from 'axios';
import Papa from 'papaparse'; // Import PapaParse

import { Select, MenuItem, FormControl, InputBase, InputLabel, Checkbox, FormControlLabel } from "@mui/material";

const ImportContactsCopyPaste = () => {
  
  const [responseMessage, setresponseMessage] = useState(0); // Step state
  const [ResponseSuccessMessage, setResponseSuccessMessage] = useState(""); // Step state
  
  const [file, setFile] = useState(null);  // State to store uploaded file
  const [uploadProgress, setUploadProgress] = useState(0); // State to store upload progress

  const fileInputRef = useRef(null);
  const [selectedListOption, setselectedListOption] = useState("All");

  // Handler to update the selected option
  const handleListSelectChange = (event) => {
    setselectedListOption(event.target.value);
  };	
  
  const [csvData, setCsvData] = useState([]); // State to store CSV data

  const [totalContactsInList, setTotalContactsInList] = useState(0);
  const [contactsWillBeProcessed, setContactsWillBeProcessed] = useState(0);
  const [contactsWillBeSkipped, setContactsWillBeSkipped] = useState(0);
  
  const [NewListName, setNewListName] = useState('');
  const [NewListNameError, setNewListNameError] = useState(false);
  const NewListNameRef = useRef(null);
	
  const [currentStep, setCurrentStep] = useState(0); // Step state
  const [checked, setChecked] = useState(false);
  const [selectedValue, setSelectedValue] = useState(1); // Pre-selected value
  const [textareaValue, setTextareaValue] = useState(""); // State for textarea value
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const [errorList, seterrorList] = useState(""); // State for error message
  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile && uploadedFile.type === 'text/csv') {
      setFile(uploadedFile);
      setErrorMessage("");
      // Simulate upload progress
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        setUploadProgress(progress);
        if (progress === 100) {
          clearInterval(interval);
        }
      }, 100);
	  
	   Papa.parse(uploadedFile, {
      header: true, // Use first row as header
      complete: (results) => {
        const rows = results.data;
        setCsvData(rows); // Store parsed data
        
        // Calculate totals
        const totalContacts = rows.length; // Total rows including header
        const emailSet = new Set(); // To track unique emails
        let validCount = 0;
        let invalidCount = 0;

        rows.forEach(row => {
          const email = row.Email; // Assuming the email column is named "Email"
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for valid email

          if (emailRegex.test(email)) {
            if (!emailSet.has(email)) {
              emailSet.add(email);
              validCount++;
            }
          } else {
            invalidCount++;
          }
        });

        setTotalContactsInList(totalContacts - 1); // Exclude header
        setContactsWillBeProcessed(validCount);
        setContactsWillBeSkipped(invalidCount);
      },
      error: (error) => {
        setErrorMessage("Error reading CSV file.");
      },
    });
	  
    } else {
      setFile(null);
      setErrorMessage("Only CSV files are allowed.");
    }
  };
  const options = [
    { value: 1, label: "First Name", image: "../iconss/other/cells.png" },
    { value: 2, label: "Last Name", image: "../iconss/other/cells.png" },
    { value: 3, label: "Email", image: "../iconss/other/cells.png" },
  ];

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setChecked(event.target.checked);
  };
  
  const handleImport = (event) => {
    seterrorList("");
    if (selectedListOption.trim() === '') {
        seterrorList("Please Select a List");
        return;
    }
    
    if (selectedListOption.trim() === 'new-list') {
        if (NewListName.trim() === '') {
            seterrorList("Please enter a Listname");
            return;
        }
    }
    setresponseMessage(0);
	setCurrentStep(3);
    handleSubmit(event); // Pass the event here
};


  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
    if (event.target.value) {
      setErrorMessage(""); // Clear error message if textarea is filled
    }
  };

  // Function to validate email addresses
  const validateEmails = (emails) => {
    const emailArray = emails.split(/\s+/); // Split emails by whitespace
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for validating email addresses

    return emailArray.some(email => emailRegex.test(email)); // Check if at least one valid email exists
  };

  const handleNext = () => {
    if (currentStep === 0) {
       if (!file) {
		  setErrorMessage("Please upload a CSV file before proceeding.");
		  return;
		}
    }
	
	
	
    if (currentStep < 2) setCurrentStep(currentStep + 1); // Go to the next step
  };

  const handlePrev = () => {
    if (currentStep > 0) setCurrentStep(currentStep - 1); // Go to the previous step
  };

  useEffect(() => {
    document.title = 'Import Contacts | Aadow';
  }, []);
  
  
  
  const [username, setUsername] = useState('123456');
  const [listname, setListname] = useState('123456');
  const [listid, setListid] = useState('123456');
  const [folder, setFolder] = useState('123456');
  const [folderid, setFolderid] = useState('123456');
  const [message, setMessage] = useState('123456');
  
  const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', file);
        formData.append('username', username);
        formData.append('listname', listname);
        formData.append('listid', listid);
        formData.append('folder', folder);
        formData.append('folderid', folderid);

        try {
            const response = await axios.post('http://portal.aadow.com:8080/import-contacts/csv-upload', formData, {
                auth: {
                    username: 'testuser',
                    password: 'testpassword',
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
			setresponseMessage(1);
            setResponseSuccessMessage(response.data);
        } catch (error) {
            console.error(error);
            setMessage('An error occurred while uploading the file.');
        }
    };
  
  return (
    <SideBar>
      <div className="import-contacts">
        <div className="import-contacts-body">
          <div className="breadcrumb-area"> 
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item"><Link>Import Contacts</Link></li>
                <li className="breadcrumb-item active" aria-current="page">CSV Upload</li>
              </ol>
            </nav>
          </div>

          {/* Step 1: Copy-Paste Area */}
          {currentStep === 0 && (
            <div className="step-one">
              <div className="header-area">
                <div className="top-area">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h1 className="main-heading">Import Contacts</h1>
                      <h6 className="main-subheading">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="import-area">
                <div onClick={() => fileInputRef.current.click()} style={{ cursor: 'pointer' }} className="file-uploader">
                  <center>
                    <img src="../iconss/other/file.png" alt="File Upload" />
                    <h6 className="uploader-heading">Click to upload a file</h6>
                    <h6 className="uploader-subheading">Accepted type .csv only, Max file size 5mb</h6>
                  </center>
                  <input
                    type="file"
					className="d-none"
                    ref={fileInputRef}
                    accept=".csv"
                    onChange={handleFileChange}
                  />
				  {file && (
                  <div className="file-upload-progress">
                    
                      <div className="row align-items-center">
                        <div className="col-1">
                          <img src="../iconss/other/csv-file.png" alt="CSV File" />
                        </div>
                        <div className="col">
                          <h6 className="upload-progress-heading">{file.name}</h6>
                          <h6 className="upload-progress-subheading">
                            Uploading {uploadProgress}% complete
                          </h6>
                          <div className="progress">
                            <div className="completed-progress" style={{ width: `${uploadProgress}%` }}></div>
                          </div>
                        </div>
                      </div>
                  </div>
                    )}
                  
                </div>
                  {errorMessage && <p className="text-danger my-4">{errorMessage}</p>}
				<h6 className="uploader-label">
                  <a href="">Learn More</a> about importing contacts
                </h6>
				<div className="footer-area text-end">
                    <button type="button" className="primary-button ms-2" onClick={handleNext}>Next</button>
                  </div>
              </div>
            </div>
          )}

          {/* Step 2: Map Properties */}
		  {/*
          {currentStep === 1 && (
            <div className="step-two">
              <div className="mid-area">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h1 className="main-heading">Map Properties</h1>
                    <h6 className="main-subheading">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h6>
                  </div>
                </div>

                <div className="mapping-area">
                  <div className="row">
                    <div className="col-4">
                      <label>Columns in your file</label>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-4">
                      <label>Properties in Aadow</label>
                    </div>
                  </div>

                  {Array.from({ length: 4 }, (_, index) => (
                    <div className="row" key={index}>
                      <div className="col-4">
                        <FormControl fullWidth variant="outlined">
                          <Select
                            labelId="custom-select-label"
                            value={selectedValue}
                            onChange={handleChange}
                            className="custom-select"
                          >
                            {options.map((option) => (
                              <MenuItem key={option.value} value={option.value} className="custom-menu-item">
                                <img src={option.image} alt={option.label} className="menu-item-icon" />
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-1">
                        <h6 className="right-arrow"><i className="bi bi-arrow-right text-center"></i></h6>
                      </div>
                      <div className="col-4">
                        <FormControl fullWidth variant="outlined">
                          <Select
                            labelId="custom-select-label"
                            value={selectedValue}
                            onChange={handleChange}
                            className="custom-select"
                          >
                            {options.map((option) => (
                              <MenuItem key={option.value} value={option.value} className="custom-menu-item">
                                <img src={option.image} alt={option.label} className="menu-item-icon" />
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  ))}

                  <div className="footer-area text-end">
                    <button type="button" className="primary-outline-button me-2" onClick={handlePrev}>Prev</button>
                    <button type="button" className="primary-button ms-2" onClick={handleNext}>Next</button>
                  </div>
                </div>
              </div>
            </div>
          )}
			*/}
          {/* Step 3: Final Import */}
          {currentStep === 1 && (
            <div className="step-three">
              <div className="mid-area">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h1 className="main-heading">Import Contacts</h1>
                    <h6 className="main-subheading">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h6>
                  </div>
                </div>

                <div className="final-import">
                  <div className="total-contacts-area">
                    <div className="row">
                       <div className="col-4">
                        <h2 className="import-contacts-heading">{totalContactsInList}</h2>
                        <h6 className="import-contacts-subheading">Total Contacts in your list</h6>
                      </div>
                      <div className="col-4">
                        <h2 className="import-contacts-heading">{contactsWillBeProcessed}</h2>
                        <h6 className="import-contacts-subheading">Contacts will be Processed</h6>
                      </div>
                      <div className="col-4">
                        <h2 className="import-contacts-heading">{contactsWillBeSkipped}</h2>
                        <h6 className="import-contacts-subheading">Contacts will be skipped</h6>
                      </div>
                    </div>
                  </div>
				  
				  <div className="row">
                    <div className="col-12 mt-3">
                      <h6 className="listname-heading">Name your import<b className="text-danger">*</b></h6>
                      <h6 className="listname-subheading">This is the name that would show up when filtering contacts by search</h6>
                      
					  
					  <Select
						className="input-form w-25"
						sx={{ fontSize: '4vw' }}
						disableUnderline
						fullWidth
						value={selectedListOption} // Use state to control the selected value
						onChange={handleListSelectChange} // Handle change events
						input={<InputBase />}
					  >
						<MenuItem value="" selected>Select a List....</MenuItem>
						<MenuItem value="eshanpachori@aadow.com">My Clients</MenuItem>
						<MenuItem value="ashwinpachori@aadow.com">New Users</MenuItem>
						<MenuItem value="contact@aadow.com">All Time Users</MenuItem>
						<MenuItem value="new-list">Create new list</MenuItem>
					  </Select>

					  {/* Conditionally render the input based on selectedOption */}
					  {selectedListOption === "new-list" && (
						
						<input type="text" ref={NewListNameRef} className={`input-form w-25 ms-4 py-2 ${NewListNameError ? 'input-error' : ''}`} value={NewListName} onChange={(e) => {
								  setNewListName(e.target.value);
								   // Call a custom handler with the value
								}} placeHolder="New List Name*" />
					  )}
					  
                  {errorList && <p className="error-message">{errorList}</p>}
                      <div className="d-block">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={(e) => setChecked(e.target.checked)}
                              color="primary"
                            />
                          }
                          label="Do not import duplicate contacts"
                          className="custom-label" // Apply the custom CSS class
                        />
                      </div>
                    </div>
                  </div>
                  <div className="footer-area text-end">
                    <button type="button" className="primary-outline-button me-2" onClick={handlePrev}>Prev</button>
                    <button type="button" className="primary-button ms-2" onClick={handleImport}>Import</button>
                  </div>
				  
                </div>
              </div>
            </div>
          )}
		  
		  
		  {currentStep === 3 && (
            <div className="step-three">
              <div className="mid-area">
						{responseMessage === 0 && (
								<div className="col-12">
									<div id="step_three" className="right-content success-content">
									  <img src="../iconss/other/sand-clock.png" className="success-icon" alt="Success" />
									  <h2 className="secondary-heading-modal text-center">We're adding your contacts</h2>
									  <h2 className="secondary-description-modal text-center">Depending on the number of contacts you've uploaded, this may take several minutes. You can move on to your next tasks in the mean time.</h2>
									  <center><Link to="/audience-overview" className="primary-button text-decoration-none float-center d-none" >Okay</Link></center>
									</div>
								</div>
								
						)}
						
						{responseMessage === 1 && (
								<div className="col-12">
									<div id="step_three" className="right-content success-content">
									  <img src="../iconss/other/approved.png" className="success-icon" alt="Success" />
									  <h2 className="secondary-heading-modal text-center">{ResponseSuccessMessage}</h2>
									  <center><Link to="/audience-overview" className="primary-button text-decoration-none float-center mt-3 d-inline-block" >Okay, Got it!</Link></center>
									</div>
								</div>
								
						)}
              </div>
            </div>
          )}
		  
		  
        </div>
      </div>
    </SideBar>
  );
};

export default ImportContactsCopyPaste;
