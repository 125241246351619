import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import '../../css/authentication/account-recovery.css';
import Cookies from 'js-cookie';

const AccountRecovery = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(3);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [otp, setOtp] = useState(new Array(5).fill(''));
  const [seconds, setSeconds] = useState(30);
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonText, setButtonText] = useState("Resend Code in 30");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [currentStep, setCurrentStep] = useState('step_one'); // State to manage current step

  useEffect(() => {
    document.title = 'Account Recovery | Aadow';

    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds(prevSeconds => {
          const newSeconds = prevSeconds - 1;
          setButtonText(`Resend Code in ${newSeconds}`);
          return newSeconds;
        });
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setIsDisabled(false);
      setButtonText("Resend Code");
    }
  }, [seconds]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleOtpChange = (element, index) => {
    const value = element.value;
    if (isNaN(value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? value : d))]);

    // Focus on the next field
    if (element.nextSibling && value) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  const [email, setEmail] = useState('');
  const [EmailError, setEmailError] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [EmailErrorMessage, setEmailErrorMessage] = useState('');
  const [OtpSentEmail, setOtpSentEmail] = useState('');
  const [sendotpbuttonDisabled, setsendotpbuttonDisabled] = useState(false);
  const [SendOtpButtonText, setSendOtpButtonText] = useState('Continue');
  
  const SendForgotOtp = async () => {
		setEmailError(false);
		setEmailErrorMessage('');
		if (email.trim() === '') {
		  setEmailError(true);
		  setEmailErrorMessage('Please enter an email address');
		  return;
		}else if (!emailRegex.test(email)) {
		  setEmailErrorMessage('Please enter a valid email address.');
		  setEmailError(true);
		  return;
		}
		
		setsendotpbuttonDisabled(true);
		setSendOtpButtonText('Sending OTP...');
		/// setCurrentStep('step_two');
		/// setOtpSentEmail(email.replace(/(\w)([\w.-]*)(\w)(@.+)/, (_, first, middle, last, domain) => first + '*'.repeat(middle.length) + last + domain));
		/// showSnackbar('OTP sent successfully!', 'success');
		/// setSeconds(30);
		/// setIsDisabled(true);
		handleCheckEmailAvailability();
  };
  
  const handleCheckEmailAvailability = async () => {
		const loginData = new URLSearchParams({
			email: email, // Your email input
		});

		// Collecting Basic Auth credentials from inputs
		const basicAuthUsername = "testuser"; // This should be set from user input
		const basicAuthPassword = "testpassword"; // This should be set from user input

		// Base64 encode the Basic Auth credentials
		const encodedCredentials = btoa(`${basicAuthUsername}:${basicAuthPassword}`);

		try {
			const response = await fetch('http://portal.aadow.com:8080/api/validate/email', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded', // Change content type
					'Authorization': `Basic ${encodedCredentials}` // Add Basic Auth header
				},
				body: loginData.toString(), // Send login data as x-www-form-urlencoded
			});

			const status = response.status; // Get the response status

			if (response.ok) {
				const data = await response.text(); // Expect plain text response
				if(data === "Email available"){
				  setEmailError(true);
				  setEmailErrorMessage('The email address you entered does not exist with us!');
				  setsendotpbuttonDisabled(false);
				  setSendOtpButtonText('Continue');
				}else{
				  SendForgotOtpServer();
				}
			} else {
				showSnackbar('An error occurred. Please try again.', 'error');
				  setsendotpbuttonDisabled(false);
				  setSendOtpButtonText('Continue');
			}
		} catch (error) {
			console.error('Error during login:', error); // Log the entire error object
			
			showSnackbar('An error occurred. Please try again.', 'error');
			setsendotpbuttonDisabled(false);
			setSendOtpButtonText('Continue');
		}
	};
  
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const [otpError, setOtpError] = useState(false);
  
  const [otpverifybuttonDisabled, setotpverifyButtonDisabled] = useState(false);
  const [otpverifybuttonText, setotpverifyButtonText] = useState('Verify');
  
  const SendForgotOtpServer = async () => {
		const requestData = {
			username: email, // The username input
			type: "recovery",         // The type input (e.g., 'email' or 'sms')
		};

		// Collecting Basic Auth credentials from inputs
		const basicAuthUsername = "testuser"; // This should be set from user input
		const basicAuthPassword = "testpassword"; // This should be set from user input

		// Base64 encode the Basic Auth credentials
		const encodedCredentials = btoa(`${basicAuthUsername}:${basicAuthPassword}`);

		try {
			const response = await fetch('http://portal.aadow.com:8080/send-otp', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json', // Sending raw JSON data
					'Authorization': `Basic ${encodedCredentials}` // Add Basic Auth header
				},
				body: JSON.stringify(requestData), // Send the username and type in JSON format
			});

			const status = response.status; // Get the response status

			if (response.ok) {
				const message = await response.text(); // Get plain text response
				setCurrentStep('step_two');
				setOtpSentEmail(email.replace(/(\w)([\w.-]*)(\w)(@.+)/, (_, first, middle, last, domain) => first + '*'.repeat(middle.length) + last + domain));
				showSnackbar('OTP sent successfully!', 'success');
				setSeconds(30);
				setIsDisabled(true);
			} else {
			
			showSnackbar('An error occurred. Please try again.', 'error');
			setsendotpbuttonDisabled(false);
			setSendOtpButtonText('Continue');
			}
		} catch (error) {
			console.error('Error during OTP send:', error); // Log the entire error object
			
			showSnackbar('An error occurred. Please try again.', 'error');
			setsendotpbuttonDisabled(false);
			setSendOtpButtonText('Continue');
		}
	};
  
  
  
  const handleVerifyOtp = () => {
    const cleanedOtp = String(otp).replace(/,/g, '');
	const otpLength = cleanedOtp.length;
	
	setOtpError(false);
	setOtpErrorMessage("");
	if (cleanedOtp.trim() === '') {
		setOtpError(true);
		setOtpErrorMessage("Please enter OTP");
		return;
	}else if (otpLength < 5) {
		setOtpError(true);
		setOtpErrorMessage("The OTP must be at least 5 characters long");
		return;
	}
	/// showSnackbar('Account Verified!', 'success');
	setotpverifyButtonDisabled(true);
    setotpverifyButtonText('Verifying...');
	handleValidateOTP();
  };
  
  const handleValidateOTP = async () => {
	  
    const cleanedOtp = String(otp).replace(/,/g, '');
	const otpLength = cleanedOtp.length;
    const requestData = {
        username: email, // The username input
        type: "recovery",         // The type input (e.g., 'email' or 'sms')
        otp: cleanedOtp,           // The OTP input
    };

    // Collecting Basic Auth credentials from inputs
    const basicAuthUsername = "testuser"; // This should be set from user input
    const basicAuthPassword = "testpassword"; // This should be set from user input

    // Base64 encode the Basic Auth credentials
    const encodedCredentials = btoa(`${basicAuthUsername}:${basicAuthPassword}`);

    try {
        const response = await fetch('http://portal.aadow.com:8080/auth-otp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Sending raw JSON data
                'Authorization': `Basic ${encodedCredentials}` // Add Basic Auth header
            },
            body: JSON.stringify(requestData), // Send the username, type, and OTP in JSON format
        });

        const status = response.status; // Get the response status

        if (response.ok) {
            const data = await response.json(); // Parse JSON response
            const message = data.message; // Extract 'message' from the JSON response
            console.log('OTP validation message:', message); // Log the message
			showSnackbar('OTP Verified', 'success');
			handleCreateSession();
        } else {
            // Handle non-OK response
            const errorData = await response.json();
            const errorMessage = errorData.message; // Extract 'message' from the error response
            console.error('Error message:', errorMessage); // Log the error message
            setOtpError(true);
			setOtpErrorMessage("Invalid OTP");
			setotpverifyButtonDisabled(false);
			setotpverifyButtonText("Verify");
        }
    } catch (error) {
        console.error('Error during OTP validation:', error); // Log the entire error object
		
		setotpverifyButtonDisabled(false);
		otpverifybuttonText("Verify");
		showSnackbar('An error occurred. Please try again.', 'error');
    }
};
  
  const handleCreateSession = async () => {
    const sessionData = new URLSearchParams();
    sessionData.append('email', email); // Use 'email' instead of 'Email'

    // Collecting Basic Auth credentials from inputs
    const basicAuthUsername = "testuser"; // This should be set from user input
    const basicAuthPassword = "testpassword"; // This should be set from user input

    // Base64 encode the Basic Auth credentials
    const encodedCredentials = btoa(`${basicAuthUsername}:${basicAuthPassword}`);

    try {
        const response = await fetch('http://portal.aadow.com:8080/api/session/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', // Specify x-www-form-urlencoded
                'Authorization': `Basic ${encodedCredentials}` // Add Basic Auth header
            },
            body: sessionData.toString(), // Convert form data to string
        });

        const status = response.status; // Get the response status

        if (response.ok) {
            const jsonResponse = await response.json(); // Parse the response as JSON
            console.log('Session creation response:', jsonResponse); // Log the JSON response
            //alert(`Response: ${JSON.stringify(jsonResponse)}`); // Show the full JSON response in an alert
            setCurrentStep("step_three");
			Cookies.set('current_Id', jsonResponse.id, { expires: 30 });
			Cookies.set('current_accountId', jsonResponse.accountId, { expires: 30 });
			Cookies.set('current_accountName', jsonResponse.name, { expires: 30 });
			Cookies.set('current_accountEmail', jsonResponse.email, { expires: 30 });
            // Show the message in your UI or handle it as needed
            showSnackbar("OTP Verified", 'success'); // Show success message
        } else {
            // Handle non-OK response
            const jsonErrorResponse = await response.json(); // Parse the error response as JSON
            console.error('Error message:', jsonErrorResponse); // Log the error message
            ///alert(`Error: ${JSON.stringify(jsonErrorResponse)}`); // Show the full error response in an alert
            showSnackbar('An error occurred. Please try again.', 'error');
            setotpverifyButtonDisabled(false);
            setotpverifyButtonText('Verify');
        }
    } catch (error) {
        console.error('Error during session creation:', error); // Log the entire error object
        ///alert(`Error: ${error.message}`); // Show error message in an alert
        showSnackbar('An error occurred. Please try again.', 'error');
        setotpverifyButtonDisabled(false);
        setotpverifyButtonText('Verify');
    }
};

  
  const [PasswordOneErrorMessage, setPasswordOneErrorMessage] = useState('');
  const [PasswordOneError, setPasswordOneError] = useState(false);
  
  const [PasswordTwoErrorMessage, setPasswordTwoErrorMessage] = useState('');
  const [PasswordTwoError, setPasswordTwoError] = useState(false);
  
  const [resetpasswordbuttonDisabled, setresetpasswordbuttonDisabled] = useState(false);
  const [resetpasswordbuttonText, setresetpasswordbuttonText] = useState('Reset Password');
  
  
  const [passwordOne, setPasswordOne] = useState('');
  const [PasswordTwo, setPasswordTwo] = useState('');
  
  const ResetPassword = () => {
	
	setPasswordOneError(false);
	setPasswordOneErrorMessage("");
	
	setPasswordTwoError(false);
	setPasswordTwoErrorMessage("");
	
	if (passwordOne.trim() === '') {
		setPasswordOneError(true);
		setPasswordOneErrorMessage("Please enter new password");
		return;
	}else if (PasswordTwo.trim() === '') {
		setPasswordTwoError(true);
		setPasswordTwoErrorMessage("Please re-type new password");
		return;
	}else if (passwordOne !== PasswordTwo) {
		setPasswordTwoError(true);
		setPasswordTwoErrorMessage("Passwords do not match");
		return;
	}
	
			setresetpasswordbuttonDisabled(true);
			setresetpasswordbuttonText('Saving...');
	///setCurrentStep('step_four');
	handleUpdatePassword();
  };
  
  const handleUpdatePassword = async () => {
    const updateData = {
        email: email,          // Email input
        newPassword: passwordOne // New password input
    };

    // Collecting Basic Auth credentials from inputs
    const basicAuthUsername = "testuser"; // This should be set from user input
    const basicAuthPassword = "testpassword"; // This should be set from user input

    // Base64 encode the Basic Auth credentials
    const encodedCredentials = btoa(`${basicAuthUsername}:${basicAuthPassword}`);

    try {
        const response = await fetch('http://portal.aadow.com:8080/auth/update-password', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', // Send the data as raw JSON
                'Authorization': `Basic ${encodedCredentials}` // Add Basic Auth header
            },
            body: JSON.stringify(updateData), // Send the email and new password
        });

        const status = response.status; // Get the response status

        const contentType = response.headers.get('Content-Type'); // Get the response content type

        if (response.ok) {
            if (contentType && contentType.includes('application/json')) {
                const message = await response.json(); // Parse JSON response
            } else {
                const message = await response.text(); // Handle non-JSON response
            }
			
            showSnackbar('password Updated', 'success');
			setCurrentStep("step_four");
			const countdown = setInterval(() => {
					  setTimer(prevTimer => {
						if (prevTimer <= 1) {
						  clearInterval(countdown);
						  navigate('/dashboard'); // Change '/target-page' to your desired route
						}
						return prevTimer - 1;
					  });
					}, 1000);
        } else {
            if (contentType && contentType.includes('application/json')) {
                const errorData = await response.json(); // Parse JSON error response
                const errorMessage = errorData.message || 'An error occurred.'; // Extract error message
            showSnackbar('An unexpected error occurred. Please try again.', 'error');
            } else {
                const errorMessage = await response.text(); // Handle non-JSON error response
            showSnackbar('An unexpected error occurred. Please try again.', 'error');
            }
        }
    } catch (error) {
        console.error('Error during password update:', error); // Log the error object
            showSnackbar('An unexpected error occurred. Please try again.', 'error');
    }
};




  
  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  
  const handleChangeEmail = async () => {
	setCurrentStep('step_one');
	setsendotpbuttonDisabled(false);
	setSendOtpButtonText('Continue');
  }
  const handleResendCode = () => {
    // Logic to resend the OTP code
    setSeconds(30);
    setIsDisabled(true);
	SendForgotOtpServer();
	showSnackbar('OTP sent successfully!', 'success');
  };

  return (
    <div className="account-recovery-body">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6 col-xl-6">
            <div className="left-area">
              <div className="top-section">
                <img className="logo" src="logos/aadow-logo-white.svg" alt="Aadow Logo" />
              </div>
              <div className="middle-section">
                <div><h1 className="main-heading">Need Help Accessing Your Account?</h1></div>
                <div><h6 className="main-description">Easily reset your password and continue where you left off</h6></div>
                <div><a className="main-link d-none">Get Started</a></div>
              </div>
              <div className="bottom-section">
                <p className="footer-description"><i className="bi bi-c-circle me-1"></i> {currentYear} Copyright: Aadow by Horza Private Limited</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 col-xl-6">

            <div className="right-area">
            {/* Validation Step Start */}
			{currentStep === 'step_one' && (
              <div id="step_one" className="right-content">
                <h2 className="secondary-heading">Account Recovery</h2>
                <h2 className="secondary-description">Forgot? Don't worry, We'll send you intrusctions</h2>

                <br />

                <form>
                  <div className="form-area">
                    <div className="col-12">
                      <label>Email Address<b className="text-danger">*</b></label>
                      <input type="text" className={`input-form ${EmailError ? 'input-error' : ''}`} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
					{EmailErrorMessage && <div className="error-message">{EmailErrorMessage}</div>}
                    <div className="col-12">
                      <button type="button" disabled={sendotpbuttonDisabled} className="primary-button w-100" onClick={SendForgotOtp}>{SendOtpButtonText}</button>
                    </div>
                    <div className="col-12">
                      <p className="login-signup-link">Back to <Link to="/login">Sign In</Link></p>
                    </div>
                  </div>
                </form>
              </div>
			)}
            {/* Validation Step End */}

            {/* Verification Required Step Start */}
			{currentStep === 'step_two' && (
              <div id="step_two" className="right-content">
                <h2 className="secondary-heading">Verification Required</h2>
                <h2 className="secondary-description">We sent a code to <b>{OtpSentEmail}</b> <a onClick={handleChangeEmail} className="change-email">Edit</a></h2>


                <form>
                  <div className="form-area">
                    <div className="col-12 otp-container">
                      {otp.map((data, index) => (
                        <input
                          type="tel"
						  className={`otp-input ${otpError ? 'otp-error' : ''}`}
                          maxLength="1"
                          key={index}
                          value={data}
                          onChange={e => handleOtpChange(e.target, index)}
                          onFocus={e => e.target.select()}
                          onKeyDown={e => handleKeyDown(e, index)}
                        />
                      ))}
                    </div>
					{otpErrorMessage && <div className="error-message">{otpErrorMessage}</div>}
                    <div className="col-12">
                      <button type="button" disabled={otpverifybuttonDisabled} className="primary-button w-100" onClick={handleVerifyOtp}>{otpverifybuttonText}</button>
                    </div>

                    <div className="col-12">
                       <button
                        type="button"
                        id="resend_code"
                        disabled={isDisabled}
                        className="secondary-button"
						onClick={handleResendCode}
                      >
                        {buttonText}
                      </button>
                    </div>

                  </div>
                </form>
              </div>
			  )}
              {/* Verification Step End */}


              {/* Change Password Step Start */}
			  {currentStep === 'step_three' && (
              <div id="step_three" className="right-content">
                <h2 className="secondary-heading">Set Password</h2>
                <h2 className="secondary-description">Create new Password or just skip and login</h2>

                <br />

                <form>
                  <div className="form-area">

                    <div className="col-12">
                      <label>New Password<b className="text-danger">*</b></label>
                      <div className="password-container">
                        <input type={passwordVisible ? 'text' : 'password'} className={`input-form ${PasswordOneError ? 'input-error' : ''} pe-5`} onChange={(e) => setPasswordOne(e.target.value)} />
                        <i className={`bi ${passwordVisible ? 'bi-eye-slash' : 'bi-eye'} password-icon`} onClick={togglePasswordVisibility}></i>
                      </div>
                    </div>
					{PasswordOneErrorMessage && <div className="error-message">{PasswordOneErrorMessage}</div>}
                    <div className="col-12">
                      <label>Confirm Password<b className="text-danger">*</b></label>
                      <div className="password-container">
                        <input type={passwordVisible ? 'text' : 'password'} className={`input-form ${PasswordTwoError ? 'input-error' : ''} pe-5`} onChange={(e) => setPasswordTwo(e.target.value)} />
                        <i className={`bi ${passwordVisible ? 'bi-eye-slash' : 'bi-eye'} password-icon`} onClick={togglePasswordVisibility}></i>
                      </div>
                    </div>
					{PasswordTwoErrorMessage && <div className="error-message">{PasswordTwoErrorMessage}</div>}
                    <div className="col-12">
                      <button type="button" disabled={resetpasswordbuttonDisabled} className="primary-button w-100" onClick={ResetPassword}>{resetpasswordbuttonText}</button>
                    </div>
                    <div className="col-12">
                      <p className="login-signup-link">Skip to <Link to="/dashboard">Dashboard</Link></p>
                    </div>
                  </div>
                </form>
              </div>
			  )}
              {/* Change Password Step End */}

              {/* Verified Step Start */}
			  {currentStep === 'step_four' && (
              <div id="step_four" className="right-content">
                <img src="iconss/verify.png" className="success-icon" />
                <h2 className="secondary-heading text-center">New Password Saved</h2>
                <h2 className="secondary-description text-center">We're redirecting you to your Dashboard.</h2>
              </div>
			  )}
              {/* Verified Step End */}


            </div>

          </div>
        </div>
      </div>

      <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</MuiAlert>
      </Snackbar>
	  
    </div>
  );
};

export default AccountRecovery;
