import React, { useState, useEffect } from 'react';
import '../../css/public/pricing.css';
import EmailPlans from './components/pricing/EmailPlans';
import SmsPlans from './components/pricing/SmsPlans';
import WhatsappPlans from './components/pricing/WhatsappPlans';
import TopBar from './NavBar';
import Footer from './../components/Footer';

const Pricing = () => {
  const [activePlan, setActivePlan] = useState('email');

  useEffect(() => {
    document.title = 'Pricing | Aadow';
  }, []);

  return (
  <div>
  <TopBar />
    <div className="pricing-body">
	  
      <div className="header-area">
        <h1 className="main-heading">Pricing Plans</h1>
        <p className="main-subheading">
          Tailored Solutions for Every Budget. Find the Ideal Pricing Option that will Propel your Business to New Heights
        </p>
      </div>

      <div className="plan-category-area">
        <div  className="row horizontalrow">
          <div className={`col-12 col-md-12 col-lg col-xl ${activePlan === 'email' ? 'active' : ''}`}> 
            <div
              id="EmailPlans"
              className="plan-category"
              onClick={() => setActivePlan('email')}
            >
              <div className="row align-items-center">
                <div className="col-3 plan-category-icon-area">
                  <div className="plan-category-icon"><i className="bi bi-envelope-at"></i></div>
                </div>
                <div className="col-9">
                  <h2 className="plan-category-heading">Email</h2>
                  <h6 className="plan-category-subheading">
                    Engage your audience with powerful email campaigns that drive results
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className={`col-12 col-md-12 col-lg col-xl ${activePlan === 'sms' ? 'active' : ''}`}>
            <div
              id="SmsPlans"
              className="plan-category"
              onClick={() => setActivePlan('sms')}
            >
              <div className="row align-items-center">
                <div className="col-3  plan-category-icon-area">
                  <div className="plan-category-icon"><i className="bi bi-chat-quote"></i></div>
                </div>
                <div className="col-9">
                  <h2 className="plan-category-heading">SMS</h2>
                  <h6 className="plan-category-subheading">
                    Reach your customers instantly with targeted SMS marketing
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className={`col-12 col-md-12 col-lg col-xl ${activePlan === 'whatsapp' ? 'active' : ''}`}> 
            <div
              id="WhatsappPlans"
              className="plan-category"
              onClick={() => setActivePlan('whatsapp')}
            >
              <div className="row align-items-center">
                <div className="col-3 plan-category-icon-area">
                  <div className="plan-category-icon"><i className="bi bi-whatsapp"></i></div>
                </div>
                <div className="col-9">
                  <h2 className="plan-category-heading">
                    Whatsapp <span className="whatsapp-label d-none">Coming Soon</span>
                  </h2>
                  <h6 className="plan-category-subheading">
                    Connect personally with your audience through direct WhatsApp
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>

      <div className="plans-content">
        {activePlan === 'email' && <EmailPlans />}
        {activePlan === 'sms' && <WhatsappPlans />}
        {activePlan === 'whatsapp' && <WhatsappPlans />}
      </div>
    </div>
	<Footer/>
    </div>
  );
};

export default Pricing;
