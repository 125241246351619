import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/public/faqs.css';
import TopBar from './NavBar';
import Footer from './../components/Footer';
import { format } from 'date-fns';

const FAQs = () => {
  // Get the 'id' parameter from the URL
  const { search } = useParams();
  const navigate = useNavigate(); // Hook to programmatically navigate
  const [searchBar, setSearchBar] = useState(search || ''); // Initialize with URL param if present

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [noResults, setNoResults] = useState(false); // Track if no results found
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true); // Track if more blogs are available
  const observer = useRef();

  const fetchBlogs = async (pageNumber) => {
		try {
			setLoading(true);
			const url = search 
				? `http://portal.aadow.com:8080/api/Faq-feed/search?query=${encodeURIComponent(search)}&page=${pageNumber}`
				: `http://portal.aadow.com:8080/api/Faq-feed?page=${pageNumber}`;

			const response = await axios.get(url, {
				auth: {
					username: 'testuser',
					password: 'testpassword',
				},
			});

			// Check if the fetch was for search and there are no results
			if (search && response.data.length === 0) {
				setNoResults(true); // Set noResults only for search
			} else {
				setNoResults(false); // Reset no results flag if we have results or it's a normal fetch
			}

			setBlogs((prevBlogs) => [...prevBlogs, ...response.data]); // Append new blogs
			setHasMore(response.data.length > 0); // Check if there's more data
		} catch (err) {
			setError(err);
		} finally {
			setLoading(false);
		}
	};


  // Load the initial data
  useEffect(() => {
	  
    document.title = 'FAQs | Aadow';
    fetchBlogs(page);
  }, [page, search]); // Add search to dependency array

  // Observer callback function
  const lastBlogElementRef = useCallback((node) => {
    if (loading) return; // Do nothing if already loading
    if (observer.current) observer.current.disconnect(); // Disconnect the observer

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) { // Check if there's more to load
        setPage((prevPage) => prevPage + 1); // Increment the page number
      }
    });

    if (node) observer.current.observe(node); // Observe the last blog post element
  }, [loading, hasMore]);

  const handleSubmit = (e) => {
	  e.preventDefault(); // Prevent the form from doing a full page reload
	  setBlogs([]); // Clear the existing blogs
	  setPage(0); // Reset the page number to 0 for a fresh start
	  navigate(`/faqs/${searchBar}`); // Navigate to the new URL with the search input
	};
  

  return (
    <div>
      <TopBar />
      <div className="faqs-area">
        <div className="top-area">
          <div className="container-search">
            <h1>Frequently Asked Questions</h1>
            <h4>Advice and answers from the Customer Success Team</h4>
             <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="search"
                autoComplete="off"
                id="search"
                value={searchBar} // Controlled input
                onChange={(e) => setSearchBar(e.target.value)} // Update state when typing
                className="search"
                placeholder="Search for FAQs...."
                required
              />
              <button type="submit" className="d-none">Search</button> {/* You can add a search button */}
            </form>
          </div>
        </div>
        <div className="result-area">
          <h5 className="result-area-heading mb-3">{search ? <p>Search Query: <b>{search}</b></p> : <b><p>Recent FAQs</p></b>}</h5>
          
		{blogs.map((blog, index) => {
                const formattedDate = format(new Date(blog.date), 'MMM dd, yyyy');
                const isLastBlog = blogs.length === index + 1;

                return (
				  <div className="result-list">
					<Link to={`/faq/${blog.title.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '-').toLowerCase()}-${blog.id}`} className="text-decoration-none">
					  <h4 className="result-heading">{blog.title}</h4>
					  <h4 className="result-subheading">
						{blog.preview}
					  </h4>
					  <h6 className="result-date-author text-end">- {formattedDate} by <span>{blog.author}</span></h6>
					</Link>
				  </div>
		  
                );
              })}
			  
			  {loading && hasMore && (
              <div className="d-block float-center text-center pt-4 pb-4">
                <div className="spinner-grow" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            )}
            {noResults && !loading && <div className="text-center">No results found for your search.</div>}
		  
			
			<div id="contact_desktop" class="contact-support-area shadow-sm bg-white mt-4 mb-4">
				<div class="row align-items-center">
					<div class="col-1">
						<img src="https://app.aadow.com/images/support-executive-avatar.webp" alt="Contact Aadow" title="Contact Aadow" class="support-avatar" />
					</div>
					<div class="col-8 pt-2">
						<h4 class="contact-support-heading">Still Have Questions?</h4>
						<h4 class="contact-support-subheading">Can't find the answer you're looking for? Chat to our friendly Team.</h4>
					</div>
					<div class="col-3">
						<a href="contact" className="contact-support-button text-decoration-none float-end shadow-sm">Get in touch</a>
					</div>
				</div>
			</div>
			
			<div id="contact_mobile" class="contact-support-area shadow-sm text-center bg-white  mt-4 mb-4">
				<div class="row align-items-center">
					<div class="col-12">
						<img src="https://app.aadow.com/images/support-executive-avatar.webp" alt="Contact Aadow" title="Contact Aadow" class="support-avatar" />
					</div>
					<div class="col-12 pt-2">
						<h4 class="contact-support-heading">Still Have Questions?</h4>
						<h4 class="contact-support-subheading">Can't find the answer you're looking for? Chat to our friendly Team.</h4>
					</div>
					<div class="col-12">
						<a href="contact" className="contact-support-button text-decoration-none shadow-sm">Get in touch</a>
					</div>
				</div>
			</div>
		  
		  
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQs;
